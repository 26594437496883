/**計画一覧画面(管理・承認者ユーザ) */
export const PROJECT_LIST_CONSTANT = {
  // csvファイル名
  CSV_FILENAME: 'project',
  // 合算項目値
  SUM_PLAN_NUM: 'sum_plan_num',
  // 対象テーブル
  TARGET_TABLE: 'event_information',
};

// 製作指示ファイル
export const STOCK_FILE_DATA = {
  // csvファイル名
  CSV_FILENAME: '倉庫連携用入庫情報',
  // 追加情報:ワークタイプ
  WORK_TYPE: 'work_type',
  // 追加情報:ワークタイプ
  WORK_TYPE_DATA: '1',
  // 追加情報:ステータス
  STATUS: 'status',
  // 追加情報:ステータス
  STATUS_DATA: '95',
  // 取得情報:販促資材コード
  ITEM_CODE: 'item_code',
  // 取得情報:組織コード
  DEPARTMENT_CODE: 'department_code',
  // 変更キー情報:組織コード
  STORE_LOCATION_CODE: 'store_location_code',
  // 取得情報:計画数
  SUM_PLAN_NUM: 'sum_plan_num',
  // 変更キー情報:計画数
  PLAN_STOCK_CHANGE_NUM: 'plan_stock_change_num',
};
