/**8wb
 * API URL(開発用)
 */

/**
 * 共通
 */
// ログインユーザ情報取得
export const USER_AUTHINFOS =
  'https://7roil1zd7l.execute-api.ap-northeast-1.amazonaws.com/dev/userauthinfos/';
// ヘッダー項目生成
export const HEADER_LIST =
  'https://kgsdnqteih.execute-api.ap-northeast-1.amazonaws.com/dev/headerlist/';
// バリデーションチェック
export const API_URL_VALIDATION_RESULT =
  'https://7roil1zd7l.execute-api.ap-northeast-1.amazonaws.com/dev/validationresult/';
// バリデーションチェック(複数)
export const API_URL_MULTIVALIDATION_RESULT =
  'https://7roil1zd7l.execute-api.ap-northeast-1.amazonaws.com/dev/multivalidationresult/';
// 画像登録
export const API_URL_UPLOAD_IMAGE =
  'https://7roil1zd7l.execute-api.ap-northeast-1.amazonaws.com/dev/uploadimage/';
// ファイル登録
export const API_URL_UPLOAD_CSV =
  'https://7roil1zd7l.execute-api.ap-northeast-1.amazonaws.com/dev/uploadcsv/';
// ファイル情報登録
export const API_URL_REGIST_DATA =
  'https://7roil1zd7l.execute-api.ap-northeast-1.amazonaws.com/dev/registdata/';
// ブロック実行必要回数の取得
export const API_URL_RUN_COUNT =
  'https://7roil1zd7l.execute-api.ap-northeast-1.amazonaws.com/dev/runcount/';
// 辞書値取得
export const API_URL_DICVALUES =
  'https://7roil1zd7l.execute-api.ap-northeast-1.amazonaws.com/dev/dicvalues/';
// 情報出力
// TODO 現在は注文情報(ORDER)のみ対応
export const API_URL_OUTPUT =
  'https://7roil1zd7l.execute-api.ap-northeast-1.amazonaws.com/dev/output/';

/**
 * マスタ画面
 */
// お知らせマスタ管理／確認(GET)
export const API_URL_INFORMATIONS2 =
  'https://hmbsiffof6.execute-api.ap-northeast-1.amazonaws.com/dev/informations2';
// お知らせマスタ管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_INFORMATION =
  'https://hmbsiffof6.execute-api.ap-northeast-1.amazonaws.com/dev/informations/';
// お知らせマスタ管理／検索画面(GET)
export const API_URL_SEARCH_INFORMATION =
  'https://hmbsiffof6.execute-api.ap-northeast-1.amazonaws.com/dev/searchinformations/';
// お知らせマスタ管理／登録画面(GET)
export const API_URL_INPUT_INFORMATION =
  'https://hmbsiffof6.execute-api.ap-northeast-1.amazonaws.com/dev/inputinformations/';

// ユーザマスタ管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_USER =
  'https://hmbsiffof6.execute-api.ap-northeast-1.amazonaws.com/dev/users/';
// ユーザマスタ管理／検索画面(GET)
export const API_URL_SEARCH_USER =
  'https://hmbsiffof6.execute-api.ap-northeast-1.amazonaws.com/dev/searchusers/';
// ユーザマスタ管理／登録画面(GET)
export const API_URL_INPUT_USER =
  'https://hmbsiffof6.execute-api.ap-northeast-1.amazonaws.com/dev/inputusers/';

// 営業日マスタ管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_BIZDAY =
  'https://kgsdnqteih.execute-api.ap-northeast-1.amazonaws.com/dev/bizdays/';
// 営業日マスタ管理／検索画面(GET)
export const API_URL_SEARCH_BIZDAY =
  'https://kgsdnqteih.execute-api.ap-northeast-1.amazonaws.com/dev/searchbizdays/';
// 営業日マスタ管理／登録画面(GET)
export const API_URL_INPUT_BIZDAY =
  'https://kgsdnqteih.execute-api.ap-northeast-1.amazonaws.com/dev/inputbizdays/';

// 組織マスタ管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_DEPARTMENT =
  'https://hmbsiffof6.execute-api.ap-northeast-1.amazonaws.com/dev/departments/';
// 組織マスタ管理／検索画面(GET)
export const API_URL_SEARCH_DEPARTMENT =
  'https://hmbsiffof6.execute-api.ap-northeast-1.amazonaws.com/dev/searchdepartments/';
// 組織マスタ管理／登録画面(GET)
export const API_URL_INPUT_DEPARTMENT =
  'https://hmbsiffof6.execute-api.ap-northeast-1.amazonaws.com/dev/inputdepartments/';

// 在庫調整(POST:入庫登録/PUT:在庫調整)
export const API_URL_STOCK =
  'https://8h8urrp5ag.execute-api.ap-northeast-1.amazonaws.com/dev/stock/';

// 届先マスタ管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_DELIVERY =
  'https://kgsdnqteih.execute-api.ap-northeast-1.amazonaws.com/dev/deliveries/';
// 届先マスタ管理／検索画面(GET)
export const API_URL_SEARCH_DELIVERY =
  'https://kgsdnqteih.execute-api.ap-northeast-1.amazonaws.com/dev/searchdeliveries/';
// 届先マスタ管理／登録画面(GET)
export const API_URL_INPUT_DELIVERY =
  'https://kgsdnqteih.execute-api.ap-northeast-1.amazonaws.com/dev/inputdeliveries/';

// 販促資材マスタ管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_ITEM =
  'https://kgsdnqteih.execute-api.ap-northeast-1.amazonaws.com/dev/items/';
// 販促資材マスタ管理／検索画面(GET)
export const API_URL_SEARCH_ITEM =
  'https://kgsdnqteih.execute-api.ap-northeast-1.amazonaws.com/dev/searchitems/';
// 販促資材マスタ管理／登録画面(GET)
export const API_URL_INPUT_ITEM =
  'https://kgsdnqteih.execute-api.ap-northeast-1.amazonaws.com/dev/inputitems/';

/**
 * セット品画面
 */
// セット品マスタ管理_登録画面(GET)
export const API_URL_INPUT_SET =
  'https://kokhvzv5wh.execute-api.ap-northeast-1.amazonaws.com/dev/inputset/';
// セット品マスタ管理_詳細画面(メイン)(GET)
export const API_URL_DISPLAY_SET_MAIN =
  'https://kokhvzv5wh.execute-api.ap-northeast-1.amazonaws.com/dev/set/main/';
// セット品マスタ管理_詳細画面(サブ)(GET)
export const API_URL_DISPLAY_SET_ITEM =
  'https://kokhvzv5wh.execute-api.ap-northeast-1.amazonaws.com/dev/set/item/';
// セット品マスタ管理_検索画面(GET)
export const API_URL_SEARCH_SET =
  'https://kokhvzv5wh.execute-api.ap-northeast-1.amazonaws.com/dev/searchset/';
// セット品マスタ管理_資材検索部(GET)
export const API_URL_SERACH_SET_ITEM =
  'https://kokhvzv5wh.execute-api.ap-northeast-1.amazonaws.com/dev/search/set/item/';
// セット品マスタ管理_一覧画面(GET)
export const API_URL_SET =
  'https://kokhvzv5wh.execute-api.ap-northeast-1.amazonaws.com/dev/set/';
// セット品マスタ管理_複製画面(メイン)(GET)
export const API_URL_DUP_SET_MAIN =
  'https://kokhvzv5wh.execute-api.ap-northeast-1.amazonaws.com/dev/set/main/{0}';
// セット品マスタ管理_複製画面(サブ)(GET)
export const API_URL_DUP_SET_ITEM =
  'https://kokhvzv5wh.execute-api.ap-northeast-1.amazonaws.com/dev/set/item/{0}';

/**
 * 注文画面
 */
// 注文情報／REST API(GET:一覧/GET:詳細/POST:登録)
export const API_URL_ORDER =
  'https://9mk7b3e1hk.execute-api.ap-northeast-1.amazonaws.com/dev/orders/';
// 注文情報／検索画面(GET)
export const API_URL_SEARCH_ORDER =
  'https://9mk7b3e1hk.execute-api.ap-northeast-1.amazonaws.com/dev/searchorders/';
// 注文情報／登録画面(GET)
export const API_URL_INPUT_ORDER =
  'https://9mk7b3e1hk.execute-api.ap-northeast-1.amazonaws.com/dev/inputorders/';
// 注文情報／詳細（発注者情報）(GET)
export const API_URL_USER_DEPARTMENT =
  'https://9mk7b3e1hk.execute-api.ap-northeast-1.amazonaws.com/dev/userdepartments/';

// 発注管理／REST API(GET:詳細/POST:登録/DELETE:削除)
export const API_URL_WORK_ORDER =
  'https://9mk7b3e1hk.execute-api.ap-northeast-1.amazonaws.com/dev/workorders/';
// 発注管理／更新(PUT)
export const API_URL_ORDER_STATUS =
  'https://9mk7b3e1hk.execute-api.ap-northeast-1.amazonaws.com/dev/orders/{0}/status';
// 発注管理／更新(PUT)
export const API_URL_ORDER_CANCEL =
  'https://9mk7b3e1hk.execute-api.ap-northeast-1.amazonaws.com/dev/orders/{0}/cancel/';
// 発注管理／登録画面(GET)
export const API_URL_INPUT_WORK_ORDER =
  'https://9mk7b3e1hk.execute-api.ap-northeast-1.amazonaws.com/dev/inputworkorders/';
// 発注管理／登録画面(GET)
export const API_URL_INPUT_ORDER_ITEM =
  'https://9mk7b3e1hk.execute-api.ap-northeast-1.amazonaws.com/dev/inputorderitems/';

// 発注管理_届先／REST API(GET:一覧/GET:詳細)
export const API_URL_ORDER_DELIVERIE =
  'https://9mk7b3e1hk.execute-api.ap-northeast-1.amazonaws.com/dev/orderdeliveries/';
// 発注管理_届先／検索画面(GET)
export const API_URL_SEARCH_ORDER_DELIVERIE =
  'https://9mk7b3e1hk.execute-api.ap-northeast-1.amazonaws.com/dev/searchorderdeliveries/';
// 発注管理_届先／REST API(GET:一覧/POST:登録/DELETE:削除)
export const API_URL_WORK_ORDER_DELIVERIE =
  'https://9mk7b3e1hk.execute-api.ap-northeast-1.amazonaws.com/dev/workorderdeliveries/';

// 発注管理_販促資材／REST API(GET:一覧)
export const API_URL_WORK_ORDER_ITEM_STOCK_INFO =
  'https://9mk7b3e1hk.execute-api.ap-northeast-1.amazonaws.com/dev/workitemstockinfo/';
// 発注管理_販促資材／REST API(GET:一覧)
export const API_URL_ORDER_ITEM =
  'https://9mk7b3e1hk.execute-api.ap-northeast-1.amazonaws.com/dev/orderitems2/';
// 発注管理_販促資材／REST API(GET:詳細)
export const API_URL_ORDER_ITEM_DETAIL =
  'https://9mk7b3e1hk.execute-api.ap-northeast-1.amazonaws.com/dev/orderitems/';
// 発注管理_販促資材／検索画面(GET)
export const API_URL_SEARCH_ORDER_ITEM =
  'https://9mk7b3e1hk.execute-api.ap-northeast-1.amazonaws.com/dev/searchorderitems2/';
// 発注管理_販促資材／REST API(POST:登録/DELETE:削除/GET:取得)
export const API_URL_WORK_ORDER_ITEM =
  'https://9mk7b3e1hk.execute-api.ap-northeast-1.amazonaws.com/dev/workorderitems/';
// 発注管理／注文関連WORK削除(DELETE)
export const API_URL_SUB_WORK_ORDERS =
  'https://9mk7b3e1hk.execute-api.ap-northeast-1.amazonaws.com/dev/subworkorders/';

// 注文情報/一括承認(PUT)
export const API_URL_APPROVAL_ORDER =
  'https://9mk7b3e1hk.execute-api.ap-northeast-1.amazonaws.com/dev/orders/bulk/approval/';

/**
 * 在庫メンテナンス画面
 */
// 在庫メンテナンス管理／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_STOCK_MAINTENANCE =
  'https://hmbsiffof6.execute-api.ap-northeast-1.amazonaws.com/dev/stockmaintenances/';
// 在庫メンテナンス管理／検索画面(GET)
export const API_URL_SEARCH_STOCK_MAINTENANCE =
  'https://hmbsiffof6.execute-api.ap-northeast-1.amazonaws.com/dev/searcstockmaintenances/';
// 在庫メンテナンス管理／登録画面(GET)
export const API_URL_INPUT_STOCK_MAINTENANCE =
  'https://hmbsiffof6.execute-api.ap-northeast-1.amazonaws.com/dev/inputstockmaintenances/';
// 在庫メンテナンス管理／更新(PUT)
export const API_URL_STOCK_MAINTENANCES_CANCEL =
  'https://hmbsiffof6.execute-api.ap-northeast-1.amazonaws.com/dev/stockmaintenances/{0}/cancel/';

// 製作指示／REST API(GET:一覧/GET:詳細)
export const API_URL_STOCK_PLANS =
  'https://8h8urrp5ag.execute-api.ap-northeast-1.amazonaws.com/dev/stockplans/';
// 製作指示／検索画面(GET)
export const API_URL_SEARCH_STOCK_PLANS =
  'https://8h8urrp5ag.execute-api.ap-northeast-1.amazonaws.com/dev/searchstockplans/';
// 製作指示／登録画面(GET)
export const API_URL_INPUT_STOCK_PLANS =
  'https://8h8urrp5ag.execute-api.ap-northeast-1.amazonaws.com/dev/inputstockplans/';
// 製作指示／製作指示登録(入庫予定登録)
export const API_URL_STOCK_PLAN =
  'https://8h8urrp5ag.execute-api.ap-northeast-1.amazonaws.com/dev/stockplan/';

/**
 * 計画情報画面　利用しない
 */
// 計画情報一覧／REST API(GET:一覧/GET:詳細/POST:登録/PUT:更新/DELETE:削除)
export const API_URL_EVENT =
  'https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/dev/events/';
// 計画情報一覧／検索画面(GET)
export const API_URL_SEARCH_EVENT =
  'https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/dev/searchevents/';
// 計画情報一覧／登録画面(GET)
export const API_URL_INPUT_EVENT =
  'https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/dev/inputevents/';
// 計画情報／更新(PUT)
export const API_URL_EVENTPLANINFODELIVERYGROUPS_STATUS =
  'https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/dev/eventplaninfodeliverygroups/{0}/status';

// 計画数入力・確認／イベント名プルダウン(GET)
export const API_URL_QUANTITY_PLAN_EVENTS =
  'https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/dev/inputquantityplanevents/';

// 計画数入力／REST API(GET:一覧/GET:詳細/POST:登録)
export const API_URL_QUANTITY_PLANS =
  'https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/dev/quantityplans/';
// 計画数入力／検索画面(GET)
export const API_URL_SEARCH_QUANTITY_PLANS =
  'https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/dev/searchquantityplans/';
// 計画数入力／登録画面(GET)
export const API_URL_INPUT_QUANTITY_PLANS =
  'https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/dev/inputquantityplans/';

// 計画数確認／REST API(GET:一覧/GET:詳細)
export const API_URL_EVENT_PLAN_INFO_DELIVERY_GROUPS =
  'https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/dev/eventplaninfodeliverygroups/';
// 計画数確認／検索画面(GET)
export const API_URL_SEARCH_EVENT_PLAN_INFO_DELIVERY_GROUPS =
  'https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/dev/searcheventplaninfodeliverygroups/';

/**
 * 帳票画面 利用しない
 */
// 棚卸帳票／検索画面(GET)
export const API_URL_SEARCH_INVENTORY_SUMMARYS =
  'https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/dev/searchinventorysummarys/';
// 棚卸帳票／IMD棚卸集計表作成・ダウンロード(GET)
export const API_URL_INVENTORY_SUMMARYS =
  'https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/dev/inventorysummarys/';
// IMD費用明細帳票／検索画面(GET)
export const API_URL_SEARCH_COST_SUMMARYS =
  'https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/dev/searchcostsummarys/';
// IMD費用明細帳票／IMD費用明細作成・ダウンロード(GET)
export const API_URL_COST_SUMMARYS =
  'https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/dev/costsummarys/';

/**
 * 業務バリデーションチェック
 */
// 販促資材マスタ／編集時業務チェック(POST)　利用しない
export const API_URL_CHECK_EDIT_ITEM_RESULT =
  'https://xxxxx.execute-api.ap-northeast-1.amazonaws.com/dev/checkedititemresult/';
// 注文情報履歴／編集時業務チェック(POST)
export const API_URL_CHECK_EDIT_ORDER_RESULT =
  'https://9mk7b3e1hk.execute-api.ap-northeast-1.amazonaws.com/dev/checkeditorderresult/';

/**
 * ファイル一括登録
 */
// ファイル一括登録／アップロード(POST)
export const API_URL_FILE_UPLOAD =
  'https://kokhvzv5wh.execute-api.ap-northeast-1.amazonaws.com/dev/batchupload';
// ファイル一括登録状況(JOB)／REST API(GET:一覧)
export const API_URL_FILE_UPLOAD_HISTORY =
  'https://ym0s5nb8vh.execute-api.ap-northeast-1.amazonaws.com/dev/job';
// ファイル一括登録状況(JOB)／検索画面(GET)
export const API_URL_SEARCH_FILE_UPLOAD_HISTORY =
  'https://ym0s5nb8vh.execute-api.ap-northeast-1.amazonaws.com/dev/searchjobs';
// ファイル一括登録状況詳細(JOB)／REST API(GET:一覧)
export const API_URL_UPLOAD_DETAIL =
  'https://ym0s5nb8vh.execute-api.ap-northeast-1.amazonaws.com/dev/joblog/{0}';
