<!-- 詳細画面-->
<p-sidebar
  [(visible)]="detailsNav"
  position="right"
  styleClass="p-sidebar-md"
  blockScroll="false"
  [autoZIndex]="true"
  [showCloseIcon]="false"
>
  <p-scrollPanel styleClass="custombar">
    <!-- 詳細情報-->
    <app-generate-display-information></app-generate-display-information>

  <!-- 登録資材表示領域-->
  <p-table
    #table
    [value]="subSearchResultsList"
    styleClass="p-datatable-striped master-input"
    selectionMode="single"
  >

    <!-- 一覧画面ヘッダー表示-->
    <ng-template pTemplate="header">
        <tr class="p-col-2" >
          <th [attr.colspan]="subColumnNum">登録資材</th>
        </tr>
      <tr class="p-col-2">
        <!-- APIヘッダーの表示 -->
          <ng-container *ngFor="let columnSub of subColumnOrder">
          <!-- <th [pSortableColumn]="columnSub.field"> -->
          <th>
            {{ columnSub.header }}
            <!-- <p-sortIcon [field]="columnSub.field"></p-sortIcon> -->
          </th>
        </ng-container>
      </tr>
    </ng-template>

    <!-- 一覧画面ボディ表示-->
    <ng-template pTemplate="body" let-subSearchResults>
      <tr>
        <!-- 一覧表示-->
        <ng-container *ngFor="let columnSub of subColumnOrder">
          <!-- 一覧項目値表示 -->
          <td>
            {{ subSearchResults[columnSub.field] }}
          </td>
        </ng-container>
      </tr>
    </ng-template>
  </p-table>
  </p-scrollPanel>
</p-sidebar>
