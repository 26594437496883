<!-- メイン領域-->
<mat-drawer-container class="main-container" autosize>
  <!-- 入力エラーメッセージ出力領域 -->
  <p-messages severity="error" *ngIf="inputErrorMessageList.length">
    <!-- 入力エラーメッセージが存在する場合、表示 -->
    <ng-template pTemplate>
      <div class="error-message-box p-d-flex p-jc-between">
        <div class="p-message-detail p-d-flex p-flex-column">
          <!-- 入力エラーメッセージ出力オブジェクトリスト(画面表示情報)分ループ-->
          <div *ngFor="let inputErrorMessage of inputErrorMessageList">
            届先グループコード：
            <!-- 届先グループコード -->
            {{ inputErrorMessage.itemId }}
            <br />

            <!-- エラーメッセージリスト分ループ-->
            <div
              *ngFor="let errorMessage of inputErrorMessage.errorMessageList"
            >
              <!-- カラム名称 -->
              {{ errorMessage.columnName }}：
              <!-- エラーメッセージ -->
              {{ errorMessage.message }}
              <br />
            </div>
            <br />
          </div>
        </div>
      </div>
    </ng-template>
  </p-messages>

  <!-- イベント画面表示領域-->
  <p-table
    [value]="eventSearchResults"
    styleClass="p-datatable-striped project-datatable"
  >
    <ng-template pTemplate="caption">
      <div class="p-d-flex p-jc-between">
        <div class="p-d-flex">
          <!-- 検索画面表示-->
          <button
            type="button"
            pButton
            icon="pi pi-search"
            (click)="search.toggle()"
            class="p-mr-2"
            pTooltip="検索"
            tooltipPosition="bottom"
          ></button>
          <!-- 登録-->
          <button
            type="button"
            pButton
            label="登録"
            (click)="checkRegistr()"
            class="p-mr-2 registrationButton"
          ></button>
          <!-- 一括入力 -->
          <button
            type="button"
            pButton
            label="一括入力"
            class="p-mr-2 bulkInputButton"
            (click)="onClickBulkInput()"
          ></button>
        </div>

        <!-- 計画変更プルダウン-->
        <div>
          {{ projectChangeInput.column_name }}
          <p-dropdown
            name="projectChangeDropdown"
            styleClass="projectChangeDropdown"
            [options]="projectChangeInput.column_code_list_multi"
            (onChange)="projectChange(table)"
            (onClick)="backNo = no.value"
            optionValue="value"
            optionLabel="name"
            [filter]="true"
            emptyFilterMessage="検索結果が存在しません"
            appendTo="body"
            [formControl]="no"
          >
          </p-dropdown>
        </div>

        <div>
          <!-- 計画一覧遷移ボタン-->
          <button
            type="button"
            pButton
            label="IMD数量計画一覧へ"
            (click)="returnProjectList()"
            class="p-mr-2 returnProjectList"
          ></button>
        </div>
      </div>
    </ng-template>

    <!-- 一覧画面ヘッダー表示-->
    <ng-template pTemplate="header">
      <tr class="p-col-2">
        <!-- APIヘッダーの表示 -->
        <ng-container *ngFor="let eventColumn of eventColumnOrder">
          <th>
            {{ eventColumn.header }}
          </th>
        </ng-container>
      </tr>
    </ng-template>

    <!-- 一覧画面ボディ表示-->
    <ng-template pTemplate="body" let-eventSearchResults>
      <tr>
        <!-- 一覧表示-->
        <ng-container *ngFor="let eventColumn of eventColumnOrder">
          <!-- 一覧項目値表示(詳細画面遷移項目) -->
          <td
            class="transitionDetail"
            (click)="eventDetail(eventSearchResults[eventColumn.field])"
            *ngIf="'P' == eventColumn.column_pkey; else notPkey"
          >
            <u>{{ eventSearchResults[eventColumn.field] }}</u>
          </td>
          <!-- 一覧項目値表示 -->
          <ng-template #notPkey>
            <!-- カラム判定 -->
            <td [ngSwitch]="eventColumn?.input_type">
              <span *ngSwitchCase="'NUMBER'">
                <!-- 数値の場合 -->
                {{ eventSearchResults[eventColumn.field] | number }}
              </span>
              <span *ngSwitchDefault>
                <!-- 上記以外の場合の場合 -->
                {{ eventSearchResults[eventColumn.field] }}
              </span>
            </td>
          </ng-template>
        </ng-container>
      </tr>
    </ng-template>
  </p-table>

  <!-- 計画数一覧画面表示領域-->
  <p-table
    #table
    [value]="searchResultsList"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="{first}~{last}件表示({totalRecords}件中)"
    [rowsPerPageOptions]="[10, 50, 100, 500, 1000]"
    [(first)]="pages"
    styleClass="p-datatable-striped project-datatable delivery-group-datatable"
    selectionMode="single"
  >
    <!-- 一覧画面ヘッダー表示-->
    <ng-template pTemplate="header" [formGroup]="bulkInputForm">
      <tr class="p-col-2">
        <!-- APIヘッダーの表示 -->
        <ng-container *ngFor="let column of columnOrder">
          <th [pSortableColumn]="column.field">
            {{ column.header }}
            <p-sortIcon [field]="column.field"></p-sortIcon>
          </th>
        </ng-container>
        <!-- API入力項目ヘッダーの表示 -->
        <ng-container *ngFor="let inputColumn of inputColumnOrder">
          <th>
            {{ inputColumn.column_name }} <br />

            <!-- 入力項目タイプを判定 -->
            <!-- 数字 -->
            <input
              [name]="inputColumn.column_id"
              type="number"
              [formControlName]="inputColumn.column_id"
              *ngIf="inputColumn.input_type == 'number'"
              pInputText
            />
            <!-- テキストエリア -->
            <textarea
              [name]="inputColumn.column_id"
              [maxlength]="inputColumn.column_hsize"
              [formControlName]="inputColumn.column_id"
              *ngIf="inputColumn.input_type == 'textarea'"
              pInputTextarea
            ></textarea>
          </th>
        </ng-container>
      </tr>
    </ng-template>

    <!-- 一覧画面ボディ表示-->
    <ng-template pTemplate="body" let-searchResults [formGroup]="inputForm">
      <!-- 子フォームグループ -->
      <tr [formGroupName]="searchResults[getPkeyColumn()]">
        <!-- 一覧表示-->
        <ng-container *ngFor="let column of columnOrder">
          <!-- 一覧項目値表示(詳細画面遷移項目) -->
          <td
            class="transitionDetail"
            (click)="detail(searchResults[getPkeyColumn()])"
            *ngIf="'P' == column.column_pkey; else notPkey"
          >
            <u>{{ searchResults[column.field] }}</u>
          </td>
          <!-- 一覧項目値表示 -->
          <ng-template #notPkey>
            <!-- カラム判定 -->
            <td [ngSwitch]="column?.input_type">
              <span *ngSwitchCase="'NUMBER'">
                <!-- 数値の場合 -->
                {{ searchResults[column.field] | number }}
              </span>
              <span *ngSwitchDefault>
                <!-- 上記以外の場合の場合 -->
                {{ searchResults[column.field] }}
              </span>
            </td>
          </ng-template>
        </ng-container>

        <!-- 入力項目表示 -->
        <ng-container *ngFor="let inputItem of inputItemList">
          <!-- 入力項目タイプを判定 -->
          <!-- 数字 -->
          <td *ngIf="inputItem.input_type == 'number'">
            <input
              [name]="inputItem.column_id"
              type="number"
              [class.errorItem]="
                checkErrorItem(
                  searchResults[getPkeyColumn()],
                  inputItem.column_id
                )
              "
              [formControlName]="inputItem.column_id"
              pInputText
            />
          </td>
          <!-- テキストエリア -->
          <td *ngIf="inputItem.input_type == 'textarea'">
            <textarea
              [name]="inputItem.column_id"
              [maxlength]="inputItem.column_hsize"
              [class.errorItem]="
                checkErrorItem(
                  searchResults[getPkeyColumn()],
                  inputItem.column_id
                )
              "
              [formControlName]="inputItem.column_id"
              pInputTextarea
            ></textarea>
          </td>
        </ng-container>
      </tr>
    </ng-template>
  </p-table>

  <!-- 検索画面-->
  <mat-drawer #search class="search-sidenav" mode="side">
    <!-- 検索画面非表示-->
    <button
      type="button"
      pButton
      icon="pi pi-arrow-left"
      (click)="search.toggle()"
      class="p-mr-2 search-sidenav-hide"
    ></button>

    <!-- 検索画面[検索項目生成(アイテムアダプター)]-->
    <app-generate-search
      [searchEndPoint]="searchEndPoint"
      [searchTemplateId]="searchTemplateId"
      (generateSearchInformation)="searchResult(false, table, $event)"
    ></app-generate-search>
  </mat-drawer>
</mat-drawer-container>

<!-- 詳細画面[表示項目生成(アイテムアダプター)]-->
<app-generate-display></app-generate-display>

<!-- ダイアログ -->
<p-confirmDialog
  #cd
  icon="pi pi-exclamation-triangle"
  class="dialog"
  [autoZIndex]="true"
  [focusTrap]="true"
>
  <p-footer>
    <div class="p-mb-3 buttonArea">
      <button
        type="button"
        label="はい"
        (click)="cd.accept()"
        class="p-button-raised p-mr-2 button"
        pButton
      ></button>
      <button
        type="button"
        label="いいえ"
        (click)="cd.reject()"
        class="p-button-outlined p-button-secondary button"
        pButton
      ></button>
    </div>
  </p-footer>
</p-confirmDialog>
