import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  LoadData,
  LoadingState,
} from 'src/app/shared/html-parts/loading/loading-state';
import {
  API_URL_SERACH_SET_ITEM
} from 'manager/http-constants_key';
import {
  MessageData,
  ToastMessageData,
} from 'src/app/shared/html-parts/message-common/message-data';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { TOAST } from 'src/app/shared/constant/primeng-constants';
import { CommonService } from 'src/app/shared/service/common.service';
import { ITEM_TEMPLATE, SET_MAIN_TEMPLATE } from 'manager/template-constant';
import { GENERATE_INPUT_TYPE, GENERATE_INPUT_TITLE } from '../../../constant';
// import { GenerateInputInformationComponent } from '../../../generate-input-information/generate-input-information.component';

@Component({
  selector: 'app-generate-input-set-material',
  templateUrl: './generate-input-set-material.component.html',
  styleUrls: ['./generate-input-set-material.component.scss'],
})

/**
 * 入力画面ダイアログ
 */
export class GenerateInputSetMaterialComponent implements OnInit {
  // /** 入力情報 */
  // @ViewChild(GenerateInputInformationComponent)
  // generateInputInformationComponent: GenerateInputInformationComponent;

  // インプット.入力項目生成条件
  // @Input() inputOueryParameters: object;

  // 資材検索.入力フォーム
  generateInputSetMaterialSearch: FormGroup = this.formBuilder.group(Object());

  // 資材検索.登録フォーム
  generateInputSetMaterialForm: FormGroup = this.formBuilder.group(Object());

  // 更新対象ID
  @Output() reloadID = new EventEmitter<string>();

  // アウトプット.検索フォーム
  @Output() generateInputForm = new EventEmitter<any>();

  // 画面.検索項目生成
  generateSearchList: any[] = new Array();

  /* 画面用プロパティ */
  // 入力画面ダイアログ表示フラグ
  inputModal: boolean;

  searchFlg: boolean;

  // 画面検索結果一覧格納先
  searchResultsList: any[] = new Array();

  // 画面ヘッダー情報格納先
  columnOrder: any[] = new Array();

  // 資材選択リスト格納先
  materialSelected: any[] = new Array();

  // ダイアログタイトル
  title: string = "資材選択";

  // 子画面検索値
  searchRadio: string;

  conditions: any[] = [{name: 'OR', key: 'OR'}, {name: 'AND', key: 'AND'}];

  VALUE: String;
  TYPE: "OR";

  constructor(
    public datePipe: DatePipe,
    private messageData: MessageData,
    private commonService: CommonService,
    private dbOperationService: DbOperationService,
    private loadingState: LoadingState,
    private formBuilder: FormBuilder) {
      this.generateInputSetMaterialSearch = this.formBuilder.group({
        VALUE:[""],
        TYPE:["OR"]
      })
    }

  ngOnInit(): void {
    this.searchRadio = "OR";
  }

  /**
   * 入力画面表示
   * @param deleted クリア状態
   */
  public initial(deleted: boolean) {
    this.inputModal = true;
    // 画面.入力フォームを初期化
    {
      this.searchResultsList = new Array();
      // 資材クリアされているなら、選択資材をクリアする
      if (deleted) { this.materialSelected = new Array(); }
      this.generateInputSetMaterialSearch = this.formBuilder.group({
        VALUE:[""],
        TYPE:["OR"]
      });
      this.searchRadio = "OR";
      this.searchFlg = false;
    }
    /* ヘッダー情報取得処理(画面用) */
    this.dbOperationService
      .getHeaderList(SET_MAIN_TEMPLATE.NEW_INPUT_TEMPLATE_MATERIAL_ID)
      .subscribe((response) => {
        this.columnOrder = response.body;
      });
  }

  // 検索の実施
  public onSubmit(){
    
    this.searchFlg = true;
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart();

    /* セット品マスタ一覧取得処理(画面用) */
    this.dbOperationService
      .getData(
        API_URL_SERACH_SET_ITEM,
        SET_MAIN_TEMPLATE.NEW_INPUT_TEMPLATE_MATERIAL_ID,
        this.generateInputSetMaterialSearch.value,
        true
      )
      .subscribe((response) => {
        // コード値の一覧情報が存在するか否か
        if (!this.commonService.checkNoneResponse(response)) {
          // セット品マスタ一覧情報のJSONをオブジェクトに格納する
          this.searchResultsList = response.body;
        } else {
          this.searchResultsList = new Array();
        }
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3);
      });

  }

  // 親画面に選択した資材を返却
  public returnGenerateInputSetMaterial(){
    // 資材が選択されているか否か
    if (!this.materialSelected.length) {
      // 資材が選択されていない場合

      // // 警告メッセージ
      // this.messageData.toastMessage(
      //   new ToastMessageData({
      //     severity: TOAST.WARN,
      //     summary: this.commonService.msg(MESSAGE_CODE.E00015),
      //     detail: this.commonService.msg(MESSAGE_CODE.E00016, '資材'),
      //   })
      // );

      return;
    } else {
      // 各資材の項目値でチェックする。1つもないまたは複数ある場合はエラー
      // const setExterior = this.materialSelected.filter(val => val.item_code.match(/-SET-/g))
      const setExterior = this.materialSelected.filter(val => val.col_var1 !== "")
      if (setExterior.length == 0) {
        // 警告メッセージ
        this.messageData.toastMessage(
          new ToastMessageData({
            severity: TOAST.WARN,
            summary: this.commonService.msg(MESSAGE_CODE.E80003),
            detail: this.commonService.msg(MESSAGE_CODE.E00040),
          })
        );
        return;
      }
      if (setExterior.length > 1) {
        // 警告メッセージ
        this.messageData.toastMessage(
          new ToastMessageData({
            severity: TOAST.WARN,
            summary: this.commonService.msg(MESSAGE_CODE.E80003),
            detail: this.commonService.msg(MESSAGE_CODE.E00041),
          })
        );
        return;
      }
    }

    // 親画面へ選択した値を渡す
    this.generateInputForm.emit(this.materialSelected);
    // 資材選択画面を閉じる
    this.inputModal = false;
  }
}
