import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

/** Auth0 */
import { AuthGuard } from '@auth0/auth0-angular';

/** コンポーネント */
// TOP
import { TopComponent } from './top/top.component';

// MASTER
import { BizdayComponent } from './master/bizday/bizday.component';
import { DeliveryComponent } from './master/delivery/delivery.component';
import { DepartmentComponent } from './master/department/department.component';
import { InformationComponent } from './master/information/information.component';
import { ItemComponent } from './master/item/item.component';
import { UserComponent } from './master/user/user.component';
import { SetMainComponent } from './master/set-main/set-main.component';
import { FileUploadMasterComponent } from './master/file-upload-master/file-upload-master.component';

// STOCK
import { ProductionInstructionComponent } from './stock/production-instruction/production-instruction.component';
import { RegisterStockMaintenanceComponent } from './stock/register-stock-maintenance/register-stock-maintenance.component';
import { StockMaintenanceComponent } from './stock/stock-maintenance/stock-maintenance.component';

// ORDER
import { CartComponent } from './order/cart/cart.component';
import { DeliverySearchComponent } from './order/delivery-search/delivery-search.component';
import { ItemSearchComponent } from './order/item-search/item-search.component';
import { ItemSearchListComponent } from './order/item-search-list/item-search-list.component';
import { OrderRegisterComponent } from './order/order-register/order-register.component';
import { OrderHistoryComponent } from './order/order-history/order-history.component';
import { QuantityComponent } from './order/quantity/quantity.component';
import { FileUploadOrderComponent } from './order/file-upload-order/file-upload-order.component';

// SECRETARIAT
import { RegisterShippingResultComponent } from './secretariat/register-shipping-result/register-shipping-result.component';
import { OutputComponent } from './secretariat/output/output.component';

import { FileUploadHistoryComponent } from './secretariat/file-upload-history/file-upload-history.component';
import { HistoryDetailComponent } from './secretariat/file-upload-history/history-detail/history-detail.component';

// PROJECT
import { ProjectListComponent } from './project/project-list/project-list.component';
import { ProjectNumberConfirmationComponent } from './project/project-number-confirmation/project-number-confirmation.component';
import { ProjectNumberInputComponent } from './project/project-number-input/project-number-input.component';

// REPORT
import { CostDetailsReportComponent } from './report/cost-details-report/cost-details-report.component';
import { InventoryReportComponent } from './report/inventory-report/inventory-report.component';

// MESSAGE
import { MessageComponent } from './message/message.component';

const routes: Routes = [
  /**
   * TOP
   */
  {
    // TOP画面
    path: '',
    component: TopComponent,
    canActivate: [AuthGuard],
  },
  {
    // TOP画面
    path: 'pages/top',
    component: TopComponent,
    canActivate: [AuthGuard],
  },

  /** --------------------------------------------------------- */

  /**
   * マスタ管理
   */
  {
    // 営業日マスタ画面
    path: 'pages/master/bizday',
    component: BizdayComponent,
    canActivate: [AuthGuard],
  },
  {
    // 届先マスタ画面
    path: 'pages/master/delivery',
    component: DeliveryComponent,
    canActivate: [AuthGuard],
  },
  {
    // 組織マスタ画面
    path: 'pages/master/department',
    component: DepartmentComponent,
    canActivate: [AuthGuard],
  },
  {
    // お知らせマスタ画面
    path: 'pages/master/information',
    component: InformationComponent,
    canActivate: [AuthGuard],
  },
  {
    // 販促資材マスタ画面
    path: 'pages/master/item',
    component: ItemComponent,
    canActivate: [AuthGuard],
  },
  {
    // ユーザーマスタ画面
    path: 'pages/master/user',
    component: UserComponent,
    canActivate: [AuthGuard],
  },
  {
    // セット品マスタ画面
    path: 'pages/master/setMain',
    component: SetMainComponent,
    canActivate: [AuthGuard],
  },
  {
    // ファイル一括登録(マスタ)
    path: 'pages/master/fileUploadMaster',
    component: FileUploadMasterComponent,
    canActivate: [AuthGuard],
  },

  /** --------------------------------------------------------- */

  /**
   * 在庫管理
   */
  {
    // 製作指示画面
    path: 'pages/stock/productionInstruction',
    component: ProductionInstructionComponent,
    canActivate: [AuthGuard],
  },
  {
    // 在庫メンテナンスファイル登録画面
    path: 'pages/stock/registerStockMaintenance',
    component: RegisterStockMaintenanceComponent,
    canActivate: [AuthGuard],
  },
  {
    // 在庫メンテナンス管理画面
    path: 'pages/stock/stockMaintenanc',
    component: StockMaintenanceComponent,
    canActivate: [AuthGuard],
  },

  /** --------------------------------------------------------- */

  /**
   * 発注管理
   */
  {
    // 発注選択画面
    path: 'pages/order/cart',
    component: CartComponent,
    canActivate: [AuthGuard],
  },
  {
    // 発注届先画面
    // TODO 森永で同一ユーザで3画面切り分けるために使用
    path: 'pages/order/delivery-search/:category1',
    component: DeliverySearchComponent,
    canActivate: [AuthGuard],
  },
  {
    // 発注販促資材画面
    // TODO 森永で同一ユーザで3画面切り分けるために使用
    path: 'pages/order/item-search/:category1',
    component: ItemSearchComponent,
    canActivate: [AuthGuard],
  },
  {
    // 発注販促資材リスト画面
    // TODO 森永で同一ユーザで3画面切り分けるために使用
    path: 'pages/order/item-search-list/:category1',
    component: ItemSearchListComponent,
    canActivate: [AuthGuard],
  },
  {
    // 発注実績画面
    path: 'pages/order/orderHistory',
    component: OrderHistoryComponent,
    canActivate: [AuthGuard],
  },
  {
    // 注文登録画面
    path: 'pages/order/orderRegister',
    component: OrderRegisterComponent,
    canActivate: [AuthGuard],
  },
  {
    // 発注画面
    // TODO 森永で同一ユーザで3画面切り分けるために使用
    path: 'pages/order/quantity/:category1',
    component: QuantityComponent,
    canActivate: [AuthGuard],
  },
  {
    // ファイル一括登録(配送指示)
    path: 'pages/order/fileUploadOrder',
    component: FileUploadOrderComponent,
    canActivate: [AuthGuard],
  },

  /** --------------------------------------------------------- */

  /**
   * 計画　利用しない
   */
  {
    // 計画一覧画面
    path: 'pages/project/project-list',
    component: ProjectListComponent,
    canActivate: [AuthGuard],
  },
  {
    // 計画数確認画面
    // TODO 森永で同一ユーザで3画面切り分けるために使用
    path: 'pages/project/project-number-confirmation/:category1',
    component: ProjectNumberConfirmationComponent,
    canActivate: [AuthGuard],
  },
  {
    // 計画数入力画面
    path: 'pages/project/project-number-input',
    component: ProjectNumberInputComponent,
    canActivate: [AuthGuard],
  },

  /** --------------------------------------------------------- */

  /**
   * 事務局
   */
  {
    // 情報出力画面
    path: 'pages/secretariat/output',
    component: OutputComponent,
    canActivate: [AuthGuard],
  },
  {
    // 発送ステータスファイル登録画面
    path: 'pages/secretariat/registerShippingResult',
    component: RegisterShippingResultComponent,
    canActivate: [AuthGuard],
  },
  {
    // ファイル一括登録状況
    path: 'pages/secretariat/fileUploadHistory',
    component: FileUploadHistoryComponent,
    canActivate: [AuthGuard],
  },
  {
    // ファイル一括登録状況詳細
    path: 'pages/secretariat/fileUploadHistory/historyDetail/:job_id',
    component: HistoryDetailComponent,
    canActivate: [AuthGuard],
  },

  /** --------------------------------------------------------- */

  /**
   * 帳票　利用しない
   */
  {
    // 費用明細帳票出力画面
    path: 'pages/report/costDetailsReport',
    component: CostDetailsReportComponent,
    canActivate: [AuthGuard],
  },
  {
    // 棚卸帳票出力画面
    path: 'pages/report/inventoryReport',
    component: InventoryReportComponent,
    canActivate: [AuthGuard],
  },

  /** --------------------------------------------------------- */

  /**
   * メッセージ
   */
  {
    // メッセージ画面
    path: 'pages/message',
    component: MessageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
