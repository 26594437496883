/**
 * プロジェクトタイトル
 */
export const TITLE = {
  // タイトルバー
  TITLE_BAR: 'Tool-EA-line',
  // タイトルフッダー
  TITLE_FOOTER: 'Tool-EA-line',
};

/**
 * 本番ドメイン
 */
export const PROD_DOMAIN = 'tool-ea-line.bridgital.jp';

/**
 * auth0設定情報(検証/開発)
 */
export const auth0_dev = {
  production: false,
  AUTH0_DOMAIN: 'tpn-hsj-dev.au.auth0.com', // ApplicationsのDomain(auth0情報)
  AUTH0_CLIENT_ID: 'pylb31T6omi1NbJlkpuo2ZIHdRhQf1c4', // ApplicationsのClient ID(auth0情報)
  AUDIENCE: 'https://aws-jwt-authorizer', // APIsのAPI Audience(auth0情報)
  API_URL: 'https://*', // トークン付与対象APIエンドポイント
};

/**
 * auth0設定情報(本番)
 */
export const auth0_prod = {
  production: false,
  AUTH0_DOMAIN: 'bridgital-standard-prod.jp.auth0.com', // ApplicationsのDomain(auth0情報)
  AUTH0_CLIENT_ID: 'JC2515zfVTRreyz0qqkl2nIIIWIscaTR', // ApplicationsのClient ID(auth0情報)
  AUDIENCE: 'https://bridgital-standard-prod.jp.auth0.com/api/v2/', // APIsのAPI Audience(auth0情報)
  API_URL: 'https://*', // トークン付与対象APIエンドポイント
};

/**
 * 国際化（i18n）設定
 */
export const environment_i18n = {
  production: false,
  defaultLang: 'ja',
};
