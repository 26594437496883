<!-- ステータス更新画面 -->
<p-dialog [header]="title" [(visible)]="updateModal" [modal]="true">
  <!-- 入力エラーメッセージ-->
  <app-generate-input-error-message></app-generate-input-error-message>

  <!-- 詳細情報-->
  <app-generate-display-information></app-generate-display-information>

  <!-- 金額情報-->
  <p-table [value]="moneyDisplayList" styleClass="p-datatable-sm">
    <!-- 詳細情報タイトル -->
    <ng-template pTemplate="header">
      <tr class="p-col-2">
        <th colspan="2">金額情報</th>
      </tr>
    </ng-template>
    <!-- 詳細情報出力箇所 -->
    <ng-template pTemplate="body" let-moneyDisplayInformation>
      <tr>
        <!-- カラム名称 -->
        <td class="table-body-label">
          {{ moneyDisplayInformation.column_name }}
        </td>

        <!-- カラムデータ -->
        <td class="table-body-data">
          {{ moneyDisplayInformation.data | number }}
          <!-- カラム判定 -->
          <!-- 注文数量以外の場合 -->
          <a *ngIf="moneyDisplayInformation.column_id != 'order_num'">円</a>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <!-- 改行 -->
  <br />

  <!-- 入力フォーム-->
  <app-generate-input-form
    (generateInputInformation)="insertGenerateInputInformation($event)"
    (generateInputErrorInformation)="
      outputGenerateInputErrorInformation($event)
    "
  ></app-generate-input-form>
</p-dialog>
