<!-- メイン領域-->
<mat-drawer-container class="main-container" autosize>
  <!-- 情報メッセージ出力領域 -->
  <p-messages severity="info" *ngIf="maxRowMessage.length">
    <!-- 情報メッセージが存在する場合、表示 -->
    <ng-template pTemplate>
      <div class="p-d-flex p-jc-between">
        <div class="p-message-detail p-d-flex p-flex-column">
          <!-- 情報メッセージ出力-->
          {{ maxRowMessage }}
        </div>
      </div>
    </ng-template>
  </p-messages>

  <!-- 一覧画面表示領域-->
  <p-table
    #table
    [value]="searchResultsList"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    currentPageReportTemplate="{first}~{last}件表示({totalRecords}件中)"
    [rowsPerPageOptions]="[10, 50, 100, 500, 1000]"
    styleClass="p-datatable-striped order-datatable"
    selectionMode="single"
    [(selection)]="updateSelected"
  >
    <ng-template pTemplate="caption">
      <div class="p-d-flex p-jc-between">
        <div class="p-d-flex">
          <!-- 検索画面表示-->
          <button
            type="button"
            pButton
            icon="pi pi-search"
            (click)="search.toggle()"
            class="p-mr-2"
            pTooltip="検索"
            tooltipPosition="bottom"
          ></button>
        </div>
        <div>
          <!-- 一括承認 -->
          <button
            type="button"
            pButton
            label="一括承認"
            class="p-mr-2 button"
            (click)="bulkApproval(table)"
            *ngIf="!(loginUser.department_type == 2 && loginUser.admit == 0)"
          ></button>
          <!-- 全件csv出力-->
          <button
            type="button"
            pButton
            icon="pi pi-file-o"
            (click)="exportCSV()"
            class="p-mr-2"
            pTooltip="全件CSV"
            tooltipPosition="bottom"
          ></button>
        </div>
      </div>
    </ng-template>

    <!-- 一覧画面ヘッダー表示-->
    <ng-template pTemplate="header">
      <tr class="p-col-2">
        <!-- チェックボックスヘッダーの表示 -->
        <th
          class="inputCheckbox"
          *ngIf="
            (loginUser.department_type == 2 && loginUser.admit == 1) ||
            loginUser.department_type == 1 ||
            loginUser.department_type == 9
          "
        >
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <!-- APIヘッダーの表示 -->
        <ng-container *ngFor="let column of columnOrder">
          <th pSortableColumn="{{ column.field }}">
            {{ column.header }}
            <p-sortIcon field="{{ column.field }}"></p-sortIcon>
          </th>
        </ng-container>

        <!-- 操作ヘッダーの表示 -->
        <th class="operation">
          <p>操作</p>
        </th>
      </tr>
    </ng-template>

    <!-- 一覧画面ボディ表示-->
    <ng-template pTemplate="body" let-searchResults>
      <tr>
        <!-- チェックボックス -->
        <td
          class="inputCheckbox"
          *ngIf="
            (loginUser.department_type == 2 &&
              loginUser.admit == 1 &&
              loginUser.department_code ==
                searchResults.create_department_code) ||
                loginUser.department_type == 1 ||
                loginUser.department_type == 9
          "
        >
          <p-tableCheckbox
            [value]="searchResults"
            *ngIf="changeCode(searchResults.status) == 1"
          ></p-tableCheckbox>
        </td>
        <!-- 一覧表示-->
        <ng-container *ngFor="let column of columnOrder">
          <!-- 一覧項目値表示(詳細画面遷移項目) -->
          <td
            class="transitionDetail"
            (click)="detail(searchResults[column.field])"
            *ngIf="'P' == column.column_pkey; else notPkey"
          >
            <u>{{ searchResults[column.field] }}</u>
          </td>
          <!-- 一覧項目値表示 -->
          <ng-template #notPkey>
            <td>
              {{ searchResults[column.field] }}
            </td>
          </ng-template>
        </ng-container>

        <!-- 操作表示 -->
        <td>
          <!-- 承認可否ボタン -->
          <button
            pButton
            pRipple
            (click)="
              update(
                searchResults[getPkeyColumn()],
                changeCode(searchResults.status)
              )
            "
            icon="pi pi-refresh"
            class="p-button-rounded p-button-info p-mr-2"
            pTooltip="承認可否"
            tooltipPosition="bottom"
            *ngIf="
              ((loginUser.department_type == 2 &&
                loginUser.admit == 1 &&
                loginUser.department_code ==
                  searchResults.create_department_code) ||
                loginUser.department_type == 1 ||
                loginUser.department_type == 9) &&
              changeCode(searchResults.status) == 1
            "
          ></button>

          <!-- 編集ボタン -->
          <button
            pButton
            (click)="
              edit(
                searchResults[getPkeyColumn()],
                changeCode(searchResults.status),
                changeCode(searchResults.category1)
              )
            "
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success p-mr-2"
            pTooltip="編集"
            tooltipPosition="bottom"
            *ngIf="
              ((loginUser.user_id == searchResults.input_user_id ||
                loginUser.department_type == 1 || loginUser.department_type == 9) &&
                changeCode(searchResults.status) == 1) ||
              ((loginUser.department_type == 1 || loginUser.department_type == 9) &&
                (changeCode(searchResults.status) == 3 || changeCode(searchResults.status) == 6)) ||
              (loginUser.user_id == searchResults.input_user_id &&
                changeCode(searchResults.exec_approval) == 0 &&
                changeCode(searchResults.status) == 6)
            "
          ></button>
          <!-- キャンセルボタン -->
          <button
            pButton
            (click)="delete(searchResults[getPkeyColumn()])"
            icon="pi pi-times"
            class="p-button-rounded p-button-danger"
            pTooltip="キャンセル"
            tooltipPosition="bottom"
            *ngIf="
              ((loginUser.user_id == searchResults.input_user_id ||
                loginUser.department_type == 1 || loginUser.department_type == 9) &&
                changeCode(searchResults.status) == 1) ||
              ((loginUser.department_type == 1 || loginUser.department_type == 9) &&
                changeCode(searchResults.status) == 6) ||
              (loginUser.user_id == searchResults.input_user_id &&
                changeCode(searchResults.exec_approval) == 0 &&
                changeCode(searchResults.status) == 6)
            "
          ></button>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <!-- 検索画面-->
  <mat-drawer #search class="search-sidenav" mode="side">
    <!-- 検索画面非表示-->
    <button
      type="button"
      pButton
      icon="pi pi-arrow-left"
      (click)="search.toggle()"
      class="p-mr-2 search-sidenav-hide"
    ></button>

    <!-- 検索画面[検索項目生成(アイテムアダプター)]-->
    <app-generate-search
      [searchEndPoint]="searchEndPoint"
      [searchTemplateId]="searchTemplateId"
      (generateSearchInformation)="searchResult(table, $event)"
    ></app-generate-search>
  </mat-drawer>
</mat-drawer-container>

<!-- 詳細画面[表示項目生成(アイテムアダプター)]-->
<app-generate-display></app-generate-display>

<!-- 更新画面 -->
<app-update-status
  (reloadID)="searchReplacement(table, $event)"
></app-update-status>

<!-- 登録画面[入力項目生成(アイテムアダプター)]-->
<app-generate-input
  (reloadID)="searchReplacement(table, $event)"
></app-generate-input>

<!-- 削除ダイアログ-->
<app-delete-dialog
  (reloadID)="searchReplacement(table, $event)"
></app-delete-dialog>
