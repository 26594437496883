import { Injectable } from '@angular/core';
import { PROD_DOMAIN } from 'manager/environment';
import * as devURL from 'manager/http-constants_dev';
import * as prodURL from 'manager/http-constants_prod';
import {
  MESSAGE_CODE,
  MESSAGE,
} from 'src/app/shared/constant/message-constant';
import {
  CHARACTER_CODE,
  CONSTANT,
  FILE_CONSTANT,
  FILE_EXTENSION,
  NUMBER,
} from 'src/app/shared/constant/constant';
import {
  API_RESPONSE,
  HEADER_LIST_API_CONSTANT,
} from 'src/app/shared/constant/api-constant';
import { AuthorityDecision } from 'manager/user-authority-constant';
import { SESSION_KEY } from '../constant/session-constants';
import * as userAuthority from 'manager/user-authority-constant';

@Injectable({
  providedIn: 'root',
})

/**
 * 共通機能
 */
export class CommonService {
  constructor() {}

  // 値リスト
  componentValueList: ComponentValue[] = new Array();

  /**
   * ログインユーザ権限判定
   * @param defaultValue デフォルト値 ※権限に一致しない場合、返却
   * @param authorityValue ログインユーザ権限返却値 ※ログインユーザ情報の権限により返却
   * @returns デフォルト値またはログインユーザ権限返却値の値が返却
   * 権限が最初に一致したログインユーザ権限返却値を返却
   * -------------------------------------------------------
   * 注:authorityValueに値を設定する場合は複雑な権限を先にする
   * 例：
   * ログインユーザ(department_type=1,admit=1)の場合
   * authorityValue = {
   * USER_1(department_type=1)='テスト1'
   * USER_5(department_type=1,admit=1)='テスト5'
   * }
   * 上記の場合、'テスト1'が返却される
   * ※解消法(以下のように設定)
   * authorityValue = {
   * USER_5(department_type=1,admit=1)='テスト5'
   * USER_1(department_type=1)='テスト1'
   * }
   * 上記の場合、'テスト5'が返却され、USER_5に一致しない場合、USER_1の'テスト1'が返却される
   */
  // TODO なおしてねっとから使用
  public decisionUserAuthority(
    defaultValue: any,
    authorityValue?: AuthorityDecision
  ): any {
    // ログインユーザ権限返却値が存在するか否か
    if (!authorityValue) {
      // ログインユーザ権限返却値が存在しない場合

      // デフォルト値を返却
      return defaultValue;
    }

    // セッションからログインユーザ情報
    const loginUser = JSON.parse(
      window.sessionStorage.getItem(SESSION_KEY.loginUserInformation)
    );

    // ログインユーザ情報が存在するか否か
    if (!loginUser) {
      // ログインユーザ情報が存在しない場合

      // デフォルト値を返却
      return defaultValue;
    }

    // ログインユーザ権限返却値分ループ
    Authority: for (const authorityValueKey in authorityValue) {
      // キー情報を元に権限情報を取得
      const userAuthorityInformation = userAuthority[authorityValueKey];

      // 権限情報が存在するか否か
      if (!userAuthorityInformation) {
        // 権限情報が存在しない場合

        continue;
      }

      // 権限情報設定フラグ
      let userAuthorityInformationFlag: boolean;

      // 権限情報分ループ
      for (const userAuthorityKey in userAuthorityInformation) {
        // 権限情報の項目に1つ以上、値が存在するか否か
        if (userAuthorityInformation[userAuthorityKey]) {
          // 権限情報の項目に1つ以上、値が存在する場合

          // 権限情報設定フラグをONにする
          userAuthorityInformationFlag = true;
          break;
        }
      }

      // 権限情報設定フラグをOFFの場合
      if (!userAuthorityInformationFlag) {
        // 次のログインユーザ権限返却値のループを実施
        continue;
      }

      // 権限情報分ループ
      for (const userAuthorityKey in userAuthorityInformation) {
        // 権限情報の項目が存在する かつ
        // 権限情報の項目とログインユーザの権限項目が一致するか否か
        if (
          userAuthorityInformation[userAuthorityKey] &&
          userAuthorityInformation[userAuthorityKey] !=
            loginUser[userAuthorityKey]
        ) {
          // 権限項目が一致しない場合

          // 次のログインユーザ権限返却値のループを実施
          continue Authority;
        }
      }

      // 全ての権限情報の項目とログインユーザ情報の権限項目が一致した場合

      // ログインユーザ権限返却値の値を返却
      return authorityValue[authorityValueKey];
    }

    // 全ての権限に一致しなかった場合

    // デフォルト値を返却
    return defaultValue;
  }

  /**
   * コンポーネント間値渡し
   * @param value 値
   * @param key キー
   */
  setComponentValue(value: any, key?: string) {
    // キーの値が存在するか否か
    if (
      this.getArrayObjectValue(this.componentValueList, 'key', 'value', key)
    ) {
      // キーの値が存在する場合

      // キーの値で既に存在するデータを削除する
      this.componentValueList = this.deleteArrayObjectValue(
        this.componentValueList,
        'key',
        key
      );
    }

    // 値リストにデータをセットする
    this.componentValueList.push(
      new ComponentValue({
        key: key,
        value: value,
      })
    );
  }

  /**
   * コンポーネント間値受け取り
   * @param キー 検索対象ArrayObject
   * @returns 値
   */
  getComponentValue(key?: string): string {
    // 値リストからキーデータを取得する
    const value = this.getArrayObjectValue(
      this.componentValueList,
      'key',
      'value',
      key
    );

    // 値リストにキーデータが存在しない場合
    if (!value) {
      return CONSTANT.EMPTY_STRING;
    }

    return value;
  }

  /**
   * if分岐において数値:0をtrueとする
   * @param value 分岐判定値
   * @returns boolean
   * ※通常のif分岐では数値0はfalseとなる為、
   * 共通部品を作成
   */
  public ifZeroPermission(value): boolean {
    // 分岐判定値の型を判定
    if ('[object Number]' == Object.prototype.toString.call(value)) {
      // 数値型の場合

      // 値が0か否か
      if (NUMBER.ZERO == value) {
        // 値が0の場合
        return true;
      }
      // 値が0以外の場合

      return value ? true : false;
    }

    // 数値型以外の場合
    return value ? true : false;
  }

  /**
   * ArrayObjectからプライマリーカラムを取得
   * @param ArrayObject 検索対象ArrayObject
   * @returns カラム
   */
  public getPkeyColumn(ArrayObject: Object[]): string {
    return this.getArrayObjectValue(
      ArrayObject,
      HEADER_LIST_API_CONSTANT.COLUMN_PKEY,
      HEADER_LIST_API_CONSTANT.FIELD,
      'P'
    );
  }

  /**
   * ArrayObjectからプライマリーカラム名称を取得
   * @param ArrayObject 検索対象ArrayObject
   * @returns カラム
   */
  public getPkeyColumnName(ArrayObject: Object[]): string {
    return this.getArrayObjectValue(
      ArrayObject,
      HEADER_LIST_API_CONSTANT.COLUMN_PKEY,
      HEADER_LIST_API_CONSTANT.HEADER,
      'P'
    );
  }

  /**
   * response判定(検索系の判定)
   * @param response レスポンス情報
   * @returns true レスポンスが存在しない場合
   * @returns false レスポンスが存在する場合
   */
  public checkNoneResponse(response: any): boolean {
    // responseが存在するか否か
    if (!response.body.length) {
      // responseが存在しない場合

      return true;
    }

    // responseのレコードが存在するか否か
    if (API_RESPONSE.NO_RECORD == response.body[0].Message) {
      // responseレコードが存在しない場合

      return true;
    }

    // responseが存在する場合
    return false;
  }

  /**
   * response複数判定(検索系の判定)
   * 非同期同時実行で使用
   * @param responseList レスポンス情報
   * @returns true 1件でもレスポンスが存在しない場合
   * @returns false 全てのレスポンスが存在する場合
   */
  public checkNoneResponseList(responseList: any[]): boolean {
    // response分ループ
    for (const response of responseList) {
      // response判定(検索系の判定)を実行
      if (this.checkNoneResponse(response)) {
        // responseレコードが存在しない場合

        return true;
      }
    }

    // 全てのレスポンスが存在する場合
    return false;
  }

  /**
   * response判定(登録、更新、削除系の判定)
   * @param response レスポンス情報
   * @returns true レスポンス正常
   * @returns false レスポンス異常
   */
  public checkRunningNormallyResponse(response: any): boolean {
    // レスポンスのHTTPステータスを判定
    if (200 != response.status) {
      // HTTPステータスが200以外の場合

      // 異常終了
      return false;
    }

    // HTTPステータスが200の場合
    // レスポンス結果が存在するか否か
    if (!response.body.length) {
      // レスポンス結果が存在しない場合

      // 異常終了
      return false;
    }

    // レスポンス結果を判定
    if (API_RESPONSE.SUCCESS != response.body[0].Message) {
      // レスポンス結果が正常以外の場合

      // 異常終了
      return false;
    }

    // 正常終了
    return true;
  }

  /**
   * response結合(検索系)
   * @param responseList レスポンス情報
   * @param leadJoinNumber 先頭結合行数
   *
   * leadJoinNumber:先頭結合行数に3を入れた場合、0~2行目を結合
   * 上記以外を、そのまま返却
   *
   * memo:db-operation.createForkJoinTask作成したforkJoinのレスポンス結合に使用する
   * @returns response レスポンス結合情報
   */
  public JoinSearchResponseList(
    responseList: any[],
    leadJoinNumber: number
  ): any {
    // レスポンス情報が存在するか否か
    if (!responseList.length) {
      // レスポンス情報が存在しない場合

      return null;
    }

    // 返却用レスポンスリスト
    let returnResponseList: any = new Array();

    // response分ループ
    responseList.forEach((responseData, index) => {
      // ループ回数が0回目か否か
      if (!index) {
        // ループ回数が0回目の場合

        // status等の込みデータを格納
        returnResponseList[0] = responseData;
      } else {
        // ループ回数が1回目以降の場合

        // ループ回数が先頭結合行数以下か否か
        if (index < leadJoinNumber) {
          // 先頭結合行数以下の場合

          // コード値の一覧情報が存在するか否か
          if (!this.checkNoneResponse(responseData)) {
            // コード値の一覧情報が存在する場合

            // 返却用レスポンスにレスポンスボディをマージする
            returnResponseList[0].body = returnResponseList[0].body.concat(
              responseData.body
            );
          }
        } else {
          // 返却用レスポンスリストにレスポンス情報を追加する
          returnResponseList[returnResponseList.length] = responseData;
        }
      }
    });

    // 結合したレスポンス用返却用オブジェクトを返却
    return returnResponseList;
  }

  /**
   * response結合(登録、更新、削除系)
   * 非同期同時実行、レスポンスのerrorがObjectの場合で使用
   * @param responseList レスポンス情報
   * @returns response レスポンス結合情報
   */
  public JoinInsertObjectResponseList(responseList: any[]): any {
    // レスポンス情報が存在するか否か
    if (!responseList.length) {
      // レスポンス情報が存在しない場合

      return null;
    }

    // レスポンス用返却用オブジェクトを生成
    let response: any = {
      // HTTPステータス
      status: 200,
      // 内部情報
      body: [{ result: API_RESPONSE.SUCCESS, error: new Object() }],
    };

    // response分ループ
    for (const responseData of responseList) {
      // レスポンスのHTTPステータスを判定
      if (200 != responseData.status) {
        // HTTPステータスが200以外の場合

        // レスポンスのHTTPステータスを格納
        response.status = responseData.status;
      }

      // レスポンス返却値のresultが成功か否か
      if (API_RESPONSE.SUCCESS != responseData.body[0].result) {
        // レスポンス返却値のresultが成功以外の場合

        // レスポンス返却値のresultを格納
        response.body[0].result = responseData.body[0].result;
      }

      // レスポンス返却値のエラーを格納
      response.body[0].error = Object.assign(
        response.body[0].error,
        responseData.body[0].error
      );
    }

    // 結合したレスポンス用返却用オブジェクトを返却
    return response;
  }

  /**
   * response結合(登録、更新、削除系)
   * 非同期同時実行、レスポンスのerrorがArrayの場合で使用
   * @param responseList レスポンス情報
   * @returns response レスポンス結合情報
   */
  public JoinInsertArrayResponseList(responseList: any[]): any {
    // レスポンス情報が存在するか否か
    if (!responseList.length) {
      // レスポンス情報が存在しない場合

      return null;
    }

    // レスポンス用返却用オブジェクトを生成
    let response: any = {
      // HTTPステータス
      status: 200,
      // 内部情報
      body: [{ result: API_RESPONSE.SUCCESS, error: new Array() }],
    };

    // response分ループ
    for (const responseData of responseList) {
      // レスポンスのHTTPステータスを判定
      if (200 != responseData.status) {
        // HTTPステータスが200以外の場合

        // レスポンスのHTTPステータスを格納
        response.status = responseData.status;
      }

      // レスポンス返却値のresultが成功か否か
      if (API_RESPONSE.SUCCESS != responseData.body[0].result) {
        // レスポンス返却値のresultが成功以外の場合

        // レスポンス返却値のresultを格納
        response.body[0].result = responseData.body[0].result;
      }

      // レスポンス返却値のエラーを格納
      response.body[0].error = response.body[0].error.concat(
        responseData.body[0].error
      );
    }

    // 結合したレスポンス用返却用オブジェクトを返却
    return response;
  }

  /**
   * API URL生成
   * @param endPointKey REST APIエンドポイントキー
   * @param replaceArray 置き換える文字列
   * @returns URL
   */
  public url(endPointKey: string, ...replaceArray: any[]): string {
    // エンドポイント取得
    let endPoint =
      PROD_DOMAIN == window.location.host
        ? prodURL[endPointKey]
        : devURL[endPointKey];

    // エンドポイントが取得されたか否か
    if (!endPoint) {
      // エンドポイントが取得されなかった場合

      console.error(
        this.msg(
          MESSAGE_CODE.E80000,
          PROD_DOMAIN == window.location.host ? '本番用' : '開発用',
          endPointKey
        )
      );

      return CONSTANT.EMPTY_STRING;
    }

    // 置き換える文字列分ループ
    replaceArray.forEach((replace, index) => {
      // REST APIエンドポイントに置き換え文字列が存在するか否か
      if (
        endPoint.includes(CONSTANT.LEFT_BRACE + index + CONSTANT.RIGHT_BRACE)
      ) {
        // 置き換え文字列が存在する場合

        // 文字を置き換える
        endPoint = endPoint.replace(
          CONSTANT.LEFT_BRACE + index + CONSTANT.RIGHT_BRACE,
          replace
        );
      } else {
        // 置き換え文字列が存在しない場合

        // 文字列の最後に連結する
        endPoint = endPoint.concat(replace);
      }
    });

    // URLを返却
    return endPoint;
  }

  /**
   * メッセージ内容取得
   * @param msgkey メッセージキー
   * @param replaceArray 置き換える文字列
   * @returns メッセージ内容
   */
  public msg(msgkey: string, ...replaceArray: any[]): string {
    // メッセージ情報取得
    let message = MESSAGE[msgkey];

    // メッセージ情報が存在するか否か
    if (!message) {
      // メッセージ情報が存在しない場合

      return CONSTANT.EMPTY_STRING;
    }

    // 置き換える文字列分ループ
    replaceArray.forEach((replace, index) => {
      message = message.replace(
        CONSTANT.LEFT_BRACE + index + CONSTANT.RIGHT_BRACE,
        replace
      );
    });

    return message;
  }

  /**
   * ファイル情報を取得
   * @param file ファイル情報
   */
  public getFile(file): any {
    return new Promise((resolve) => {
      // FileReader オブジェクトを生成
      const reader = new FileReader();
      // ファイルの中身を読み取る
      reader.readAsText(file, CHARACTER_CODE.SJIS);
      // ファイル情報の読み込みが正常終了の場合、ファイル情報を返却
      reader.onload = (event) => resolve(event.target.result);
      // ファイル情報の読み込みが異常終了の場合、コンソールにエラー出力
      reader.onerror = (error) => console.error(error);
    });
  }

  /**
   * ファイル情報をbase64化
   * @param file ファイル情報
   */
  public getBase64(file): any {
    return new Promise((resolve) => {
      // FileReader オブジェクトを生成
      const reader = new FileReader();
      // ファイルをbase64URL化しFileReaderに格納
      reader.readAsDataURL(file);
      // ファイル情報の読み込みが正常終了の場合、Base64化したファイル情報を返却
      reader.onload = (event) => resolve(event.target.result);
      // ファイル情報の読み込みが異常終了の場合、コンソールにエラー出力
      reader.onerror = (error) => console.error(error);
    });
  }

  /**
   * CSV or TSVファイル情報から配列を生成
   * @param fileInformation ファイル情報
   * @param extension 拡張子 .csv or .tsvのみ設定可能
   * @returns オブジェクト配列
   *
   * 返却レスポンスサンプル
   * "information_id,title"\r\n"1007","配送情報"\r\n"1207","【重要】臨時メンテナンスの実施について"
   * ↓
   * [
   *   {
   *     information_id: "1007",
   *     title: "配送情報"
   *   },
   *   {
   *     information_id: "1207",
   *     title: "【重要】臨時メンテナンスの実施について"
   *   }
   * ]
   */
  public createCsvDataObject(
    fileInformation: string,
    extension: string
  ): object[] {
    // ファイル情報の必須判定
    if (!fileInformation) {
      // ファイル情報が存在しない場合

      return null;
    }

    // ファイル拡張子の判定
    if (!(FILE_EXTENSION.CSV == extension || FILE_EXTENSION.TSV == extension)) {
      // ファイル拡張子が".csv"又は".tsv"以外の場合

      return null;
    }

    // 改行コードで配列化
    const informationList: string[] = fileInformation.split(
      FILE_CONSTANT.LINE_CODE
    );

    // ヘッダー部を格納先を宣言
    let header: string[];

    // 拡張子がcsv又はtsvか否か
    if (FILE_EXTENSION.CSV == extension) {
      // 拡張子がcsvの場合

      // カンマ区切りでヘッダーを格納
      header = informationList[0].split(CONSTANT.COMMA);
    } else if (FILE_EXTENSION.TSV == extension) {
      // 拡張子がtsvの場合

      // タブ区切りでヘッダーを格納
      header = informationList[0].split(FILE_CONSTANT.TAB);
    }

    // データ部(先頭行を削除)
    informationList.shift();

    // レスポンス配列を宣言
    let dataArrayObject: object[] = new Array();

    // データ部をループ
    for (let lineData of informationList) {
      // 行データが存在するか否か
      if (!lineData) {
        // 行データが存在しない場合

        continue;
      }

      // データ格納先を宣言
      let data: any[];

      // データ内の改行コード(LF、CR)を削除する
      lineData = lineData.replace(
        new RegExp(FILE_CONSTANT.NEWLINE, 'g'),
        CONSTANT.EMPTY_STRING
      );
      lineData = lineData.replace(
        new RegExp(FILE_CONSTANT.RETUREN, 'g'),
        CONSTANT.EMPTY_STRING
      );

      // 拡張子がcsv又はtsvか否か
      if (FILE_EXTENSION.CSV == extension) {
        // 拡張子がcsvの場合

        // 頭とお尻のダブルクォートを除く
        if (lineData.startsWith('"')) {
          lineData = lineData.slice(1);
        }
        if (lineData.endsWith('"')){
          lineData = lineData.slice(0,-1)
        }
        // ダブルクォートカンマ区切りでデータ部のデータを格納
        data = lineData.split(CONSTANT.COMMA_IN_DOUBLE_QUOTES);
      } else if (FILE_EXTENSION.TSV == extension) {
        // 拡張子がtsvの場合

        // ダブルクォーテーションを全て削除
        lineData = lineData.replace(
          new RegExp(CONSTANT.DOUBLE_QUOTATION, 'g'),
          CONSTANT.EMPTY_STRING
        );
        // タブ区切りでデータ部のデータを格納
        data = lineData.split(FILE_CONSTANT.TAB);
      }

      // 格納用オブジェクトを生成
      let objectData = new Object();

      // データをループ
      data.forEach((data, index) => {
        // ヘッダーでプロパティをを生成し、データを格納
        objectData[header[index]] = data;
      });

      // 格納用オブジェクトをレスポンス配列に格納
      dataArrayObject.push(objectData);
    }

    return dataArrayObject;
  }

  /**
   * CSV or TSVファイルデータチェック
   * @param fileInformation ファイル情報
   * @param extension 拡張子 .csv or .tsvのみ設定可能
   * @returns メッセージコード
   *
   */
  public checkCsvDataObject(
    fileInformation: string,
    extension: string
  ): string {
    // ファイル情報の必須判定
    if (!fileInformation) {
      // ファイル情報が存在しない場合
      return MESSAGE_CODE.E00031;
    }

    // ファイル拡張子の判定
    if (!(FILE_EXTENSION.CSV == extension || FILE_EXTENSION.TSV == extension)) {
      // ファイル拡張子が".csv"又は".tsv"以外の場合
      return MESSAGE_CODE.E00030;
    }

    // 改行コードで配列化
    const informationList: string[] = fileInformation.split(
      FILE_CONSTANT.LINE_CODE
    );

    // ヘッダー部の格納先を宣言
    let header: string[];

    // 拡張子がcsv又はtsvか否か
    if (FILE_EXTENSION.CSV == extension) {
      // 拡張子がcsvの場合

      // カンマ区切りでヘッダーを格納
      header = informationList[0].split(CONSTANT.COMMA);
    } else if (FILE_EXTENSION.TSV == extension) {
      // 拡張子がtsvの場合

      // タブ区切りでヘッダーを格納
      header = informationList[0].split(FILE_CONSTANT.TAB);
    }

    // データ部(先頭行を削除)
    informationList.shift();
    let counter = 0;

    // データ部をループ
    for (let lineData of informationList) {
      // 行データが存在するか否か
      if (!lineData) {
        // 行データが存在しない場合
        continue;
      } else {
        counter = counter + 1;
      }

      // データ格納先を宣言
      let data: any[];

      // 拡張子がcsv又はtsvか否か
      if (FILE_EXTENSION.CSV == extension) {
        // 拡張子がcsvの場合

        // 頭とお尻のダブルクォートチェック
        if (lineData.startsWith('"')) {
          lineData = lineData.slice(1);
        } else {
          return MESSAGE_CODE.E00032;
        }
        if (lineData.endsWith('"')){
          lineData = lineData.slice(0,-1)
        } else {
          return MESSAGE_CODE.E00032;
        }
        // ダブルクォートカンマ区切りでデータ部のデータを格納
        data = lineData.split(CONSTANT.COMMA_IN_DOUBLE_QUOTES);
      } else if (FILE_EXTENSION.TSV == extension) {
        // 拡張子がtsvの場合

        // ダブルクォーテーションを全て削除
        lineData = lineData.replace(
          new RegExp(CONSTANT.DOUBLE_QUOTATION, 'g'),
          CONSTANT.EMPTY_STRING
        );
        // タブ区切りでデータ部のデータを格納
        data = lineData.split(FILE_CONSTANT.TAB);
      }

      if (FILE_EXTENSION.CSV == extension) {
        // データをループ
        for (let col of data) {
          // データ内に区切り文字がないかチェック
          if (col.indexOf(',"') >= NUMBER.ZERO || col.indexOf('",') >= NUMBER.ZERO) {
            return MESSAGE_CODE.E00032;
          }
        }
      }
      // ヘッダーの個数とデータの個数をチェック
      if (header.length != data.length) {
        return MESSAGE_CODE.E00033;
      }
    }
    // データ行数をチェック
    if (counter == 0) {
      return MESSAGE_CODE.E00031;
    }

    return MESSAGE_CODE.T00000;
  }

  /**
   * ArrayObjectにキー項目+値を追加
   * @param ArrayObject 検索対象ArrayObject
   * @param addKey 追加キー
   * @param addValue 追加値
   * @returns 該当データ配列
   *
   * 例 追加キー:test,追加値:データ
   * [{field: "user_id", header: "ユーザID"},{field: "user_name", header: "ユーザ名称"}]
   * → [{test: "データ",field: "user_id", header: "ユーザID"},{test: "データ",field: "user_name", header: "ユーザ名称"}]
   */
  public addKeyValueArrayObject(
    ArrayObject: Object[],
    addKey: string,
    addValue: string
  ): any[] {
    // ArrayObjectが存在するか否か
    if (!ArrayObject) {
      // ArrayObjectが存在しない場合

      return new Array();
    }

    // ArrayObjectが存在するか否か
    if (!ArrayObject.length) {
      // ArrayObjectが存在しない場合

      return new Array();
    }

    // 検索対象ArrayObjectをループ
    for (const object of ArrayObject) {
      // 検索対象ArrayObjectに追加キー+追加値を作成
      object[addKey] = addValue;
    }

    // 検索対象ArrayObjectを返却
    return ArrayObject;
  }

  /**
   * ArrayObjectのキー項目を変更
   * @param ArrayObject 検索対象ArrayObject
   * @param beforeChangeKey 変更前キー
   * @param afterChangeKey 変更後キー
   * @returns 該当データ配列
   *
   * 例 変更前キー:field,変更後キー:test
   * [{field: "user_id", header: "ユーザID"},{field: "user_name", header: "ユーザ名称"}]
   * → [{test: "user_id", header: "ユーザID"},{test: "user_name", header: "ユーザ名称"}]
   */
  public changeKeyArrayObject(
    ArrayObject: Object[],
    beforeChangeKey: string,
    afterChangeKey: string
  ): any[] {
    // ArrayObjectが存在するか否か
    if (!ArrayObject) {
      // ArrayObjectが存在しない場合

      return new Array();
    }

    // ArrayObjectが存在するか否か
    if (!ArrayObject.length) {
      // ArrayObjectが存在しない場合

      return new Array();
    }

    // 返却用配列を作成
    let returnArrayObject = new Array();

    // 検索対象ArrayObjectをループ
    for (const object of ArrayObject) {
      // 返却用オブジェクトを作成
      let returnObject: object = new Object();

      // 検索対象Objectキー分ループ
      for (const key in object) {
        // 検索対象Objectキーが変更前キーに一致するか否か
        if (key == beforeChangeKey) {
          // 変更前キーに一致する場合

          // 検索対象Objectから返却用オブジェクトに変更後キーでデータを作成
          returnObject[afterChangeKey] = object[key];
        } else {
          // 変更前キーに一致しない場合

          // 検索対象Objectから返却用オブジェクトに変更前キーでデータを作成
          returnObject[key] = object[key];
        }
      }

      // 返却用オブジェクトを返却用配列に格納
      returnArrayObject.push(returnObject);
    }

    // 返却用配列を返却
    return returnArrayObject;
  }

  /**
   * ArrayObjectから該当キーで配列を生成
   * @param ArrayObject 検索対象ArrayObject
   * @param searchKey 検索キー
   * @returns 該当データ配列
   *
   * 例 検索キー:header
   * [{field: "user_id", header: "ユーザID"},{field: "user_name", header: "ユーザ名称"}]
   * → ["ユーザID", "ユーザ名称"]
   */
  public createArrayGetArrayObject(
    ArrayObject: Object[],
    searchKey: string
  ): any[] {
    // ArrayObjectが存在するか否か
    if (!ArrayObject) {
      // ArrayObjectが存在しない場合

      return new Array();
    }

    // ArrayObjectが存在するか否か
    if (!ArrayObject.length) {
      // ArrayObjectが存在しない場合

      return new Array();
    }

    // ArrayObjectから対象のsearchKeyでArrayを作成
    let ArrayData = ArrayObject.map((val) => {
      return val[searchKey];
    });

    // 配列から空要素除去
    return ArrayData.filter((v) => v);
  }

  /**
   * ArrayObjectから該当キーで配列オブジェクトを作成
   * @param ArrayObject 検索対象ArrayObject
   * @param searchKeyList 検索キー
   * @returns 該当データ
   *
   * 例 検索キー:field
   * [{field: "user_id", header: "ユーザID"},{field: "user_name", header: "ユーザ名称"}]
   * → [{field: "user_id"},{field: "user_name"}]
   */
  public createArrayObjectSearchKeyString(
    ArrayObject: Object[],
    ...searchKeyList: string[]
  ): any[] {
    // ArrayObjectが存在するか否か
    if (!ArrayObject) {
      // ArrayObjectが存在しない場合

      return new Array();
    }

    // ArrayObjectが存在するか否か
    if (!ArrayObject.length) {
      // ArrayObjectが存在しない場合

      return new Array();
    }

    // 返却用配列を作成
    let returnArrayObject = new Array();

    // 検索対象ArrayObjectをループ
    for (const object of ArrayObject) {
      // 返却用オブジェクトを作成
      let returnObject: object = new Object();

      // 検索対象Objectキー分ループ
      for (const key in object) {
        // 検索対象Objectキーが検索キーに存在するか否か
        if (searchKeyList.includes(key)) {
          // 検索キーに存在する場合

          // 検索対象Objectから返却用オブジェクトに該当データを作成
          returnObject[key] = object[key];
        }
      }

      // 返却用オブジェクトの中身が存在するか否か
      if (!Object.keys(returnObject).length) {
        // 中身が存在しない場合

        // 次の検索対象ArrayObjectを実施
        continue;
      }

      // 返却用オブジェクトを返却用配列に格納
      returnArrayObject.push(returnObject);
    }

    // 返却用配列を返却
    return returnArrayObject;
  }

  /**
   * ArrayObjectから該当キーで配列オブジェクトを作成
   * @param ArrayObject 検索対象ArrayObject
   * @param searchKey 検索キー
   * @param searchValueList 検索値
   * @returns 該当データ
   *
   * 例 検索キー:field,検索値:user_id,user_name
   * [{field: "user_id", header: "ユーザID"},{field: "user_name", header: "ユーザ名称"},{field: "user_name", header: "ユーザカナ"}]
   * → [{field: "user_id", header: "ユーザID"},{field: "user_name", header: "ユーザ名称"}]
   */
  public createArrayObjectSearchValueString(
    ArrayObject: Object[],
    searchKey: string,
    ...searchValueList: string[]
  ): any[] {
    // ArrayObjectが存在するか否か
    if (!ArrayObject) {
      // ArrayObjectが存在しない場合

      return new Array();
    }

    // ArrayObjectが存在するか否か
    if (!ArrayObject.length) {
      // ArrayObjectが存在しない場合

      return new Array();
    }

    // 返却用配列を作成
    let array = new Array();

    // 検索対象ArrayObjectをループ
    for (const object of ArrayObject) {
      // 検索値をループ
      searchValueList.forEach((data) => {
        // 検索対象ArrayObjectの検索キーと検索値を判定
        if (object[searchKey] == data) {
          // 検索対象ArrayObjectの検索キーと検索値が一致した場合

          // 検索対象ArrayObjectの取得キーを配列に格納
          array.push(object);
        }
      });
    }

    // 返却用配列を返却
    return array;
  }

  /**
   * ArrayObjectから検索した該当データを取得する
   * @param ArrayObject 検索対象ArrayObject
   * @param searchKey 検索キー
   * @param getKey 取得キー
   * @param searchValue 検索値
   * @returns 該当データ
   *
   * 例 検索キー:field,取得キー:header,検索値:user_name
   * [{field: "user_id", header: "ユーザID"},{field: "user_name", header: "ユーザ名称"}]
   * → ユーザ名称
   */
  public getArrayObjectValue(
    ArrayObject: Object[],
    searchKey: string,
    getKey: string,
    searchValue: string | number
  ): any {
    // ArrayObjectが存在するか否か
    if (!ArrayObject || !ArrayObject.length) {
      return null;
    }

    // ArrayObjectから対象のobjectを取得
    const object = ArrayObject.filter((val) => val[searchKey] == searchValue);

    // objectが取得できたか否か
    if (!object.length) {
      return null;
    }

    // objectから対象データを取得
    return object[0][getKey];
  }

  /**
   * ArrayObjectから検索した該当データを削除する
   * @param ArrayObject 検索対象ArrayObject
   * @param searchKey 検索キー
   * @param searchValue 検索値
   * @returns 該当データを削除したArrayObject
   *
   * 例 検索キー:field,検索値:user_name
   * [{field: "user_id", header: "ユーザID"},{field: "user_name", header: "ユーザ名称"}]
   * → [{field: "user_id", header: "ユーザID"}]
   */
  public deleteArrayObjectValue(
    ArrayObject: Object[],
    searchKey: string,
    searchValue: string | number
  ): any {
    // ArrayObjectが存在するか否か
    if (!ArrayObject || !ArrayObject.length) {
      return null;
    }

    // ArrayObjectから対象のobject以外を取得
    const object = ArrayObject.filter((val) => val[searchKey] != searchValue);

    // objectから対象データを取得
    return object;
  }

  /**
   * ArrayObjectから検索した該当データを複数取得する
   * @param ArrayObject 検索対象ArrayObject
   * @param searchKey 検索キー
   * @param getKey 取得キー
   * @param searchValue 検索値(文字 or 配列)
   * @returns 該当データ配列
   *
   * 例 検索キー:field,取得キー:header,検索値:user_name(文字)
   * [{field: "user_id", header: "ユーザID"},{field: "user_name", header: "ユーザ名称"},{field: "user_name", header: "ユーザカナ"}]
   * → ["ユーザ名称", "ユーザカナ"]
   *
   * 例 検索キー:field,取得キー:header,検索値:user_id,user_name(配列)
   * [{field: "user_id", header: "ユーザID"},{field: "user_name", header: "ユーザ名称"},{field: "user_name_kana", header: "ユーザカナ"}]
   * → ["ユーザID", "ユーザ名称"]
   */
  public getArrayObjectMultipleValue(
    ArrayObject: object[],
    searchKey: string,
    getKey: string,
    searchValue: any
  ): string[] {
    // ArrayObjectが存在するか否か
    if (!ArrayObject) {
      // ArrayObjectが存在しない場合

      return new Array();
    }

    // 取得データを格納
    let array = new Array();

    // 検索値の型を判定
    if ('[object String]' == Object.prototype.toString.call(searchValue)) {
      // 文字列型の場合

      // ArrayObjectから対象のobjectを取得
      const object = ArrayObject.filter((val) => val[searchKey] == searchValue);

      // 対象のobjectから取得キーの値を取得
      array = this.createArrayGetArrayObject(object, getKey);
    } else if (
      '[object Array]' == Object.prototype.toString.call(searchValue)
    ) {
      // 配列型の場合

      // 検索対象ArrayObjectをループ
      for (const object of ArrayObject) {
        // 検索値をループ
        searchValue.forEach((data) => {
          // 検索対象ArrayObjectの検索キーと検索値を判定
          if (object[searchKey] == data) {
            // 検索対象ArrayObjectの検索キーと検索値が一致した場合

            // 検索対象ArrayObjectの取得キーを配列に格納
            array.push(object[getKey]);
          }
        });
      }
    }

    // 取得データを返却
    return array;
  }

  /**
   * ArrayObjectから検索した該当データの行番号を取得
   * @param ArrayObject 検索対象ArrayObject
   * @param searchKey 検索キー
   * @param searchValue 検索値
   * @returns 行番号
   *
   * 例 検索キー:field,検索値:user_name
   * [{field: "user_id", header: "ユーザID"},{field: "user_name", header: "ユーザ名称"},{field: "user_name_kana", header: "ユーザカナ"}]
   * → 1
   */
  public getRowNumberArrayObject(
    ArrayObject: object[],
    searchKey: string,
    searchValue: string
  ): number {
    // ArrayObjectが存在するか否か
    if (!ArrayObject || !ArrayObject.length) {
      return null;
    }

    // 検索対象ArrayObjectから検索キーと検索値に一致する行番号を返却
    return ArrayObject.indexOf(
      ArrayObject.find((object) => {
        return object[searchKey] === searchValue;
      })
    );
  }

  /**
   * ArrayObjectから検索した該当データの数値を合算
   * @param ArrayObject 検索対象ArrayObject
   * @param searchKey 検索キー
   * @returns 合算値
   *
   * 例 検索キー:num
   * [{field: "user_id", header: "ユーザID",num:1},{field: "user_name", header: "ユーザ名称",num:"1"},{field: "user_name_kana", header: "ユーザカナ",num:3}]
   * → 5
   */
  public sumNumberArrayObject(
    ArrayObject: object[],
    searchKey: string
  ): number {
    // ArrayObjectが存在するか否か
    if (!ArrayObject || !ArrayObject.length) {
      return null;
    }

    // 検索対象Arrayを生成
    const resultArray: any[] = this.createArrayGetArrayObject(
      ArrayObject,
      searchKey
    );

    // 検索対象Arrayが存在するか否か
    if (!resultArray.length) {
      // 検索対象Arrayが存在しない場合

      return NUMBER.ZERO;
    }

    // 変換用reducer関数を生成
    const reducer = (accumulator, currentValue) =>
      accumulator + Number(currentValue);

    // 返却値を返却
    return resultArray.reduce(reducer);
  }

  public paeseStrToBoolean(str: string): boolean {
    // 文字列を判定
    return (str == 'true') ? true : false;
  
  }
}

/** コンポーネント間_値受け渡しオブジェクト */
export class ComponentValue {
  // 値受け渡しキー
  private _key: string;

  // 値
  private _value: string;

  constructor(init?: Partial<ComponentValue>) {
    Object.assign(this, init);
  }

  set key(key: string) {
    this._key = key;
  }

  get key(): string {
    return this._key;
  }

  set value(value: string) {
    this._value = value;
  }

  get value(): string {
    return this._value;
  }
}
