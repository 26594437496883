<!-- 入力エラーメッセージページアンカー -->
<a id="generateInputErrorTop"></a>
<!-- 入力エラーメッセージ出力領域 -->
<p-messages severity="error" *ngIf="generateInputErrorMessageList.length">
  <!-- 入力エラーメッセージが存在する場合、表示 -->
  <ng-template pTemplate>
    <div class="error-message-box p-d-flex p-jc-between">
      <div class="p-message-detail p-d-flex p-flex-column">
        <!-- 入力エラーメッセージ出力オブジェクトリスト(画面表示情報)分ループ-->
        <div
          *ngFor="
            let generateInputErrorVerification of generateInputErrorMessageList
          "
        >
          <!-- カラム名称 -->
          {{ generateInputErrorVerification.columnName }}：
          <!-- カラムエラーメッセージ -->
          {{ generateInputErrorVerification.columnErrorMessage }}
          <br />
        </div>
      </div>
    </div>
  </ng-template>
</p-messages>
