import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_URL_VALIDATION_RESULT } from 'manager/http-constants_key';
import { map } from 'rxjs/operators';
import { CommonService } from '../service/common.service';

@Injectable({
  providedIn: 'root',
})
/**
 * 共通バリデーションチェック.サービス
 */
export class ValidatorService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  /**
   * 入力項目値バリデーションチェック(単項目)
   * @param tblId テーブル名
   * @param colId カラム名
   * @param val 入力値
   * @returns JSONデータ
   */
   public getValidatorResult(
    tblId: string,
    colId: string,
    val: string
  ): Observable<any> {
    // LambdaのEndpointを生成
    const url = this.commonService.url(
      API_URL_VALIDATION_RESULT,
      '?TableID=',
      tblId,
      '&ColumnID=',
      colId,
      '&Value=',
      encodeURI(this.replaceValue(val))
    );

    // 取得データの返却
    return this.http
      .get(url, { observe: 'response' })
      .pipe(map((res: HttpResponse<any>) => res));
  }

  
    /**
   * URLで使用できない文字を置き換える
   * @param val 
   * @returns 
   */
     private replaceValue(val: string):string{
      try{
        return val.replace(/\+/g, "[pls]").replace(/#/g, "[shp]").replace(/&/g, "[and]").replace(/;/g, "[scl]").replace(/"/g, "[dbq]").replace(/'/g, "[sgq]").replace(/}/g, "[rcb]").replace(/\\/g, "[bsl]")
      }catch(e){
        //対象データ変換できない場合（配列、Null、未定義など）、そのまま戻す
        return val
      }
    }
}
