import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-csv-error-message',
  templateUrl: './csv-error-message.component.html',
})

/**
 * CSVファイル登録エラーメッセージ
 */
export class CsvErrorMessageComponent implements OnInit {
  // CSVファイル登録エラーメッセージ出力オブジェクトリスト(画面表示情報)
  csvErrorMessageList: Object[] = new Array();

  constructor() {}

  ngOnInit(): void {}

  /**
   * csvファイルエラーメッセージ表示
   * @param csvErrorMessage CSVエラーメッセージオブジェクト
   */
  public initial(csvErrorMessageList: Object[]) {
    // エラーメッセージをプロパティに格納
    this.csvErrorMessageList = csvErrorMessageList;
  }

  /**
   * csvファイルエラーメッセージクリア
   */
  public clear() {
    // csvファイルエラーメッセージをクリア
    this.csvErrorMessageList = new Array();
  }
}
