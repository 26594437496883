import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/** モジュール */
// ルーティング
import { PagesRoutingModule } from './pages-routing.module';

// 共通
import { SharedModule } from 'src/app//shared/shared.module';
import { LibraryModule } from 'src/app/library/library.module';

/** コンポーネント */
// TOP
import { TopComponent } from './top/top.component';
import { NoticeComponent } from './top/notice/notice.component';
import { NoticeListComponent } from './top/notice/notice-list/notice-list.component';

// MASTER
import { BizdayComponent } from './master/bizday/bizday.component';
import { DeliveryComponent } from './master/delivery/delivery.component';
import { DepartmentComponent } from './master/department/department.component';
import { InformationComponent } from './master/information/information.component';
import { ItemComponent } from './master/item/item.component';
import { ItemDetailComponent } from './master/item/item-detail/item-detail.component';
import { StockComponent } from './master/item/stock/stock.component';
import { UserComponent } from './master/user/user.component';
import { SetMainComponent } from './master/set-main/set-main.component';
import { SetMainDetailComponent } from './master/set-main/set-main-detail/set-main-detail.component';
import { FileUploadMasterComponent } from './master/file-upload-master/file-upload-master.component';

// STOCK
import { ProductionInstructionComponent } from './stock/production-instruction/production-instruction.component';
import { RegisterStockMaintenanceComponent } from './stock/register-stock-maintenance/register-stock-maintenance.component';
import { StockMaintenanceComponent } from './stock/stock-maintenance/stock-maintenance.component';

// ORDER
import { BreadcrumbComponent } from './order/breadcrumb/breadcrumb.component';
import { CartComponent } from './order/cart/cart.component';
import { CartDeliveryComponent } from './order/cart/cart-delivery/cart-delivery.component';
import { CartItemComponent } from './order/cart/cart-item/cart-item.component';
import { DeliverySearchComponent } from './order/delivery-search/delivery-search.component';
import { ItemSearchComponent } from './order/item-search/item-search.component';
import { ItemSearchListComponent } from './order/item-search-list/item-search-list.component';
import { ItemSearchListManagementDetailComponent } from './order/item-search-list/item-search-list-management-detail/item-search-list-management-detail.component';
import { OrderRegisterComponent } from './order/order-register/order-register.component';
import { QuantityComponent } from './order/quantity/quantity.component';
import { OrderHistoryComponent } from './order/order-history/order-history.component';
import { UpdateStatusComponent } from './order/order-history/update-status/update-status.component';
import { FileUploadOrderComponent } from './order/file-upload-order/file-upload-order.component';

// SECRETARIAT
import { RegisterShippingResultComponent } from './secretariat/register-shipping-result/register-shipping-result.component';
import { OutputComponent } from './secretariat/output/output.component';

import { FileUploadHistoryComponent } from './secretariat/file-upload-history/file-upload-history.component';
import { HistoryDetailComponent } from './secretariat/file-upload-history/history-detail/history-detail.component';

// PROJECT
import { ProjectListComponent } from './project/project-list/project-list.component';
import { ProjectNumberConfirmationComponent } from './project/project-number-confirmation/project-number-confirmation.component';
import { ProjectNumberInputComponent } from './project/project-number-input/project-number-input.component';
import { ProjectManagementDetailComponent } from './project/project-list/project-management-detail/project-management-detail.component';

// REPORT
import { CostDetailsReportComponent } from './report/cost-details-report/cost-details-report.component';
import { InventoryReportComponent } from './report/inventory-report/inventory-report.component';

// MESSAGE
import { MessageComponent } from './message/message.component';

@NgModule({
  declarations: [
    // TOP
    TopComponent,
    NoticeComponent,
    NoticeListComponent,

    // MASTER
    BizdayComponent,
    DeliveryComponent,
    DepartmentComponent,
    InformationComponent,
    ItemComponent,
    ItemDetailComponent,
    StockComponent,
    UserComponent,
    SetMainComponent,
    SetMainDetailComponent,
    FileUploadMasterComponent,

    // STOCK
    ProductionInstructionComponent,
    RegisterStockMaintenanceComponent,
    StockMaintenanceComponent,

    // ORDER
    BreadcrumbComponent,
    CartComponent,
    CartDeliveryComponent,
    CartItemComponent,
    DeliverySearchComponent,
    ItemSearchComponent,
    ItemSearchListComponent,
    ItemSearchListManagementDetailComponent,
    OrderRegisterComponent,
    QuantityComponent,
    OrderHistoryComponent,
    UpdateStatusComponent,
    FileUploadOrderComponent,

    // SECRETARIAT
    RegisterShippingResultComponent,
    OutputComponent,

    FileUploadHistoryComponent,
    HistoryDetailComponent,

    // PROJECT
    ProjectListComponent,
    ProjectNumberConfirmationComponent,
    ProjectNumberInputComponent,
    ProjectManagementDetailComponent,

    // REPORT
    CostDetailsReportComponent,
    InventoryReportComponent,

    // MESSAGE
    MessageComponent,
  ],
  imports: [CommonModule, PagesRoutingModule, SharedModule, LibraryModule],
})
export class PagesModule {}
