import { Component, OnInit } from '@angular/core';
import { REGISTER_SHIPPING_RESULT_TEMPLATE } from 'manager/template-constant';
import { EXEC_TYPE } from 'src/app/shared/html-parts/upload-file/csv-upload/constant';
import { FILE_EXTENSION } from 'src/app/shared/constant/constant';

@Component({
  selector: 'app-register-shipping-result',
  templateUrl: './register-shipping-result.component.html',
  styleUrls: ['./register-shipping-result.component.scss'],
})

/**
 * 発送ステータスファイル登録画面
 */
export class RegisterShippingResultComponent implements OnInit {
  // CSVファイル登録実行タイプ
  execType: string = EXEC_TYPE.UPDATE;

  // CSVファイル確認画面ヘッダーテンプレート
  csvUploadHeaderTemplateId: number =
    REGISTER_SHIPPING_RESULT_TEMPLATE.CONFIRMATION_HEADER_TEMPLATE_ID;

  // 許容ファイル拡張子
  toleranceExtension: string = FILE_EXTENSION.CSV;

  constructor() {}

  ngOnInit(): void {}
}
