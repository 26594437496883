import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { DICVALUES_API_CONSTANT } from 'src/app/shared/constant/api-constant';
import { CONSTANT, LOADING_KEY } from 'src/app/shared/constant/constant';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { CommonService } from 'src/app/shared/service/common.service';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { LoadingState } from '../../../loading/loading-state';
import { CSV_UPLOAD_DATA } from '../csv-upload';

@Component({
  selector: 'app-csv-upload-data',
  templateUrl: './csv-upload-data.component.html',
  styleUrls: ['./csv-upload-data.component.scss'],
})

/**
 * CSVファイル確認画面
 */
export class CsvUploadDataComponent implements OnInit {
  // インプット.csvファイル確認画面ヘッダーテンプレート
  @Input() csvUploadHeaderTemplateId: number;

  // アウトプット.登録データ
  @Output()
  outputCsvUploadData: EventEmitter<CSV_UPLOAD_DATA> = new EventEmitter();

  // アウトプット.取り込み状態フラグ(true:データ取り込み中,false:取り込みデータなし)
  @Output() captureFlag: EventEmitter<boolean> = new EventEmitter();

  /* 画面用プロパティ */
  // 画面ヘッダー情報格納先
  columnOrder: any[] = new Array();

  // 画面CSVデータ格納先
  csvUploadData: CSV_UPLOAD_DATA;

  // 辞書値格納先
  dicValuesList: any = new Object();

  // ページ数
  pages: number = 0;

  constructor(
    private dbOperationService: DbOperationService,
    private commonService: CommonService,
    private confirmationService: ConfirmationService,
    private loadingState: LoadingState
  ) {}

  ngOnInit(): void {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart(LOADING_KEY.FILE_UPLOAD);

    /* 確認画面ヘッダー情報取得処理(画面用) */
    this.dbOperationService
      .getHeaderList(this.csvUploadHeaderTemplateId)
      .subscribe((response) => {
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadEnd(LOADING_KEY.FILE_UPLOAD);

        // ヘッダー情報格納
        this.columnOrder = response.body;

        // ヘッダー情報分ループ
        for (const headerInformation of response.body) {
          // ヘッダー情報に使用辞書番号が存在するか否か
          if (!headerInformation.column_dictionary_id) {
            // 使用辞書番号が存在しない場合
            continue;
          }

          /* 辞書情報を取得 */
          {
            // 使用辞書番号で辞書番号を取得する
            this.dbOperationService
              .getDicValues(headerInformation.column_dictionary_id)
              .subscribe((dicvalues) => {
                // 辞書オブジェクトを格納
                this.dicValuesList = Object.assign(this.dicValuesList, {
                  [headerInformation.column_dictionary_id]:
                    dicvalues.body[0].dic_value,
                });
              });
          }
        }
      });
  }

  /**
   * 確認画面表示
   * @param list csvデータオブジェクトリスト
   */
  public initial(csvUploadData: CSV_UPLOAD_DATA) {
    // csvデータオブジェクトリストを画面CSVデータに格納
    this.csvUploadData = csvUploadData;

    // ページ数を初期化
    this.pages = 0;
  }

  /**
   * 確認画面クリア
   */
  public clear() {
    // 確認画面をクリア
    this.csvUploadData = undefined;

    // データ取り込みフラグをOFF
    this.captureFlag.emit(false);
  }

  /**
   * コート値変換
   * @param spDicId 辞書番号
   * @param spCode コード値
   * @returns コード名称
   */
  protected changeCodeValue(spDicId: string, code: string): string {
    // 辞書情報を取得
    const dicValue = this.dicValuesList[spDicId];

    // 辞書情報が存在するか否か
    if (!dicValue) {
      // 辞書情報が存在しない場合

      return code;
    }

    // 名称データを取得
    const codeValue = this.commonService.getArrayObjectValue(
      dicValue,
      DICVALUES_API_CONSTANT.CODE,
      DICVALUES_API_CONSTANT.NAME,
      code
    );

    return codeValue;
  }

  /**
   * 登録ボタン
   */
  protected insert() {
    // 登録ダイアログを表示
    this.confirmationService.confirm({
      message: this.commonService.msg(MESSAGE_CODE.I00006),
      header: CONSTANT.EMPTY_ONE_STRING,
      icon: 'pi pi-info-circle',
      accept: () => {
        //　登録処理を実施
        this.outputCsvUploadData.emit(this.csvUploadData);
      },
    });
  }
}
