import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { API_URL_EVENT, API_URL_INPUT_EVENT } from 'manager/http-constants_key';
import { PROJECT_LIST_TEMPLATE } from 'manager/template-constant';
import { DATE_FORMAT } from 'src/app/shared/constant/constant';
import { GenerateDisplayInformationComponent } from 'src/app/shared/generate/generate-display/generate-display-information/generate-display-information.component';
import { GENERATE_INPUT_TYPE } from 'src/app/shared/generate/generate-input/constant';
import { GenerateInputComponent } from 'src/app/shared/generate/generate-input/generate-input.component';
import { DeleteDialogComponent } from 'src/app/shared/html-parts/delete-dialog/delete-dialog.component';

@Component({
  selector: 'app-project-management-detail',
  templateUrl: './project-management-detail.component.html',
  styleUrls: [
    '../../../../shared/generate/generate-display/generate-display.component.scss',
    './project-management-detail.component.scss',
  ],
})

/**
 * 計画管理者詳細画面
 */
export class ProjectManagementDetailComponent implements OnInit {
  // 更新対象ID
  @Output() reloadID = new EventEmitter<string>();

  // 削除対象ID
  @Output() reloadDeleteID = new EventEmitter<string>();

  /** 詳細情報 */
  @ViewChild(GenerateDisplayInformationComponent)
  generateDisplayInformationComponent: GenerateDisplayInformationComponent;

  /** 編集画面 */
  @ViewChild(GenerateInputComponent)
  generateInputComponent: GenerateInputComponent;

  /** 削除ダイアログ */
  @ViewChild(DeleteDialogComponent)
  deleteDialogComponent: DeleteDialogComponent;

  // 入力項目生成条件
  inputOueryParameters: object = {
    category1: 2,
  };

  /* 画面用プロパティ */
  // 詳細画面表示フラグ
  detailsNav: boolean;

  // 詳細ID
  pkeyId: string;

  // 行データ
  rowData: any;

  constructor(public datePipe: DatePipe) {}

  ngOnInit(): void {}

  /**
   * 詳細情報表示
   * @param inputGenerateDisplay 詳細情報入力オブジェクト(オブジェクトorリスト)
   * @param pkeyId 選択対象ID
   * @param rowData 行データ
   */
  public initial(inputGenerateDisplay: any, pkeyId: string, rowData: any) {
    // 詳細画面表示
    this.detailsNav = true;

    // 詳細情報
    this.generateDisplayInformationComponent.initial(
      inputGenerateDisplay,
      pkeyId
    );

    // 選択対象IDを格納
    this.pkeyId = pkeyId;

    // 行データを格納
    this.rowData = rowData;
  }

  /**
   * 編集画面表示
   */
  protected edit() {
    // 編集画面表示
    this.generateInputComponent.initial(
      GENERATE_INPUT_TYPE.EDIT,
      API_URL_INPUT_EVENT,
      API_URL_EVENT,
      PROJECT_LIST_TEMPLATE.MANAGEMENT_INPUT_TEMPLATE_ID,
      this.pkeyId
    );
  }

  /**
   * 複製画面表示
   */
  protected duplicate() {
    // 複製画面表示
    this.generateInputComponent.initial(
      GENERATE_INPUT_TYPE.DUPLICATE,
      API_URL_INPUT_EVENT,
      API_URL_EVENT,
      PROJECT_LIST_TEMPLATE.DUPLICATE_INPUT_TEMPLATE_ID,
      this.pkeyId
    );
  }

  /**
   * 削除処理
   * @param pkeyId IDコード(クリックされたID)
   */
  protected delete() {
    // 削除ダイアログにより削除処理
    this.deleteDialogComponent.deleteDialog(
      API_URL_EVENT,
      'イベントID',
      this.pkeyId
    );
  }

  /**
   * 表示有効期限判定
   * @returns true:ボタン表示、false:ボタン非表示]
   */
  // TODO 森永案件で利用
  protected outputDispValid(): boolean {
    // 行データが存在するか否か
    if (!this.rowData) {
      // 行データが存在しない場合
      return false;
    }

    // 現在日付
    const nowDate = this.datePipe.transform(
      new Date().setDate(new Date().getDate()),
      DATE_FORMAT.DATE
    );

    // 現在日付が表示有効期限開始日より前か否か
    if (
      nowDate <=
      this.datePipe.transform(this.rowData.disp_valid_from, DATE_FORMAT.DATE)
    ) {
      // 表示有効期限開始日より前の場合

      return true;
    }

    return false;
  }

  /**
   * 親画面へ返却する
   */
  public return(reloadID: string) {
    // 更新対象IDにプライマリキーをセット
    this.reloadID.emit(reloadID);
  }

  /**
   * 親画面へ返却する
   */
  public returnDelete(reloadID: string) {
    // 削除対象IDにプライマリキーをセット
    this.reloadDeleteID.emit(reloadID);
  }
}
