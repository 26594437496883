export const ITEM_CONSTANT = {
  // csvファイル名
  CSV_FILENAME: 'item',
  // 画像登録最大サイズ
  FILE_SIZE: 500000,
  // 対象テーブル
  TARGET_TABLE: 'item_stockinfo',
};

/** 入庫登録 */
// フラグ
export const STOCK_FLAG = {
  // 入庫登録
  STOCK_REGIST: 'stock-regist',
  // 在庫調整
  ADJUST_STOCK: 'adjust-stock',
};
// タイトル
export const STOCK_TITLE = {
  // 入庫登録
  STOCK_REGIST: '入庫登録',
  // 在庫調整
  ADJUST_STOCK: '在庫調整',
};
