import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { API_URL_OUTPUT } from 'manager/http-constants_key';
import { SECRETARIAT_OUTPUT_TEMPLATE } from 'manager/template-constant';
import { CommonService } from 'src/app/shared/service/common.service';
import { CONSTANT, LOADING_KEY } from 'src/app/shared/constant/constant';
import { DatePipe } from '@angular/common';
import { SECRETARIAT_OUTPUT_CONSTANT } from './constant';
import { TOAST } from 'src/app/shared/constant/primeng-constants';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import {
  LoadData,
  LoadingState,
} from 'src/app/shared/html-parts/loading/loading-state';
import {
  MessageData,
  ToastMessageData,
} from 'src/app/shared/html-parts/message-common/message-data';
import { API_RESPONSE } from 'src/app/shared/constant/api-constant';

@Component({
  selector: 'app-output',
  templateUrl: './output.component.html',
  styleUrls: ['./output.component.scss'],
})

/**
 * 情報出力画面
 */
export class OutputComponent implements OnInit {
  /* 画面用プロパティ */
  // 画面ヘッダー情報格納先
  columnOrder: any[] = new Array();

  // 画面検索結果一覧格納先
  outputResultsList: any[] = new Array();

  // 出力対象id選択状態
  idSelected: number;

  constructor(
    private confirmationService: ConfirmationService,
    private dbOperationService: DbOperationService,
    private commonService: CommonService,
    private messageData: MessageData,
    public datePipe: DatePipe,
    private loadingState: LoadingState
  ) {}

  ngOnInit(): void {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart();

    // ヘッダー情報取得処理(画面用)
    this.dbOperationService
      .getHeaderList(SECRETARIAT_OUTPUT_TEMPLATE.SEARCH_RESULTS_TEMPLATE_ID)
      .subscribe((response) => {
        this.columnOrder = response.body;
      });

    // 出力対象一覧取得処理を実施
    this.outputResult();
  }

  /**
   * 出力対象一覧取得処理
   */
  protected outputResult() {
    // 出力対象id選択状態をクリア
    this.idSelected = null;

    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.U00007),
      })
    );

    // 出力対象一覧取得処理(画面用)
    this.dbOperationService
      .getData(
        API_URL_OUTPUT,
        SECRETARIAT_OUTPUT_TEMPLATE.SEARCH_RESULTS_FILE_TEMPLATE_ID
      )
      .subscribe((response) => {
        // コード値の一覧情報が存在するか否か
        if (!this.commonService.checkNoneResponse(response)) {
          // 出力対象一覧情報のJSONをオブジェクトに格納する
          this.outputResultsList = response.body;
        } else {
          this.outputResultsList = new Array();
        }

        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3);
      });
  }

  /**
   * ファイル作成ボタン押下
   */
  protected onClickCreateFile() {
    /* 出力対象必須チェック確認 */
    // 出力対象が選択されているか否か
    if (!this.idSelected) {
      // 出力対象が選択されていない場合

      // メッセージを出力
      this.messageData.toastMessage(
        new ToastMessageData({
          severity: TOAST.WARN,
          summary: this.commonService.msg(MESSAGE_CODE.T00006),
          detail: this.commonService.msg(MESSAGE_CODE.T00007),
        })
      );
      return;
    }

    /* 確認ダイアログ表示 */
    this.confirmationService.confirm({
      message: this.commonService.msg(MESSAGE_CODE.T00005),
      header: CONSTANT.EMPTY_ONE_STRING,
      icon: 'pi pi-info-circle',
      accept: () => {
        /* ファイル作成 */
        // 画面カスタムロードフラグをON(ロード中状態)
        this.loadingState.customLoadStart(
          new LoadData({
            loadingText: this.commonService.msg(MESSAGE_CODE.U00008),
          }),
          LOADING_KEY.FILE_DOWNLOAD
        );

        // 使用するテンプレートID格納先
        let csvTemplateId;

        // id の値に応じてどのデータを取得するか決定
        switch (this.idSelected) {
          case SECRETARIAT_OUTPUT_CONSTANT.ID_ORDER_NORMAL:
            // ID値が1の場合

            // 注文情報(通常,新規)のテンプレートを設定
            csvTemplateId = SECRETARIAT_OUTPUT_TEMPLATE.CSV_ORDER_TEMPLATE_ID;
            break;

          case SECRETARIAT_OUTPUT_CONSTANT.ID_ORDER_SEND:
            // ID値が2の場合

            // 注文情報(送込)のテンプレートを設定
            csvTemplateId =
              SECRETARIAT_OUTPUT_TEMPLATE.CSV_SEND_ORDER_TEMPLATE_ID;
            break;

          case SECRETARIAT_OUTPUT_CONSTANT.ID_STOCK_MAINTENANCE:
            // ID値が3の場合

            // 在庫メンテナンスのテンプレートを設定
            csvTemplateId = SECRETARIAT_OUTPUT_TEMPLATE.CSV_STOCK_MAINTENANCE;
            break;

          default:
            // 画面ロードフラグをOFF(ロード終了)
            this.loadingState.loadEnd(LOADING_KEY.FILE_DOWNLOAD);
            return;
        }

        /* 出力情報取得処理(CSV/TSV用)  */
        this.dbOperationService
          .updateData(API_URL_OUTPUT, this.idSelected, {
            TemplateID: csvTemplateId,
            CodeConvFLAG: 0,
          })
          .subscribe((response) => {
            // 画面ロードフラグをOFF(ロード終了)
            this.loadingState.loadEnd(LOADING_KEY.FILE_DOWNLOAD);

            // 出力情報が存在するか否か
            if (this.commonService.checkNoneResponse(response)) {
              // 出力情報が存在しない場合

              // エラーメッセージ表示
              this.messageData.toastMessage(
                new ToastMessageData({
                  severity: TOAST.ERROR,
                  summary: this.commonService.msg(MESSAGE_CODE.E00002),
                  detail: this.commonService.msg(
                    MESSAGE_CODE.S00001,
                    this.commonService.getArrayObjectValue(
                      this.outputResultsList,
                      SECRETARIAT_OUTPUT_CONSTANT.OUTPUT_ID,
                      SECRETARIAT_OUTPUT_CONSTANT.OUTPUT_NAME,
                      this.idSelected
                    )
                  ),
                })
              );

              return;
            }

            // ファイル作成が正常終了か否か
            if (!this.commonService.checkRunningNormallyResponse(response)) {
              // ファイル作成が異常終了の場合

              // 出力メッセージがFAILか否か
              if (API_RESPONSE.FAIL == response.body[0].Message) {
                // 出力メッセージがFAILの場合

                // エラーメッセージ表示
                this.messageData.toastMessage(
                  new ToastMessageData({
                    severity: TOAST.ERROR,
                    summary: this.commonService.msg(MESSAGE_CODE.E00002),
                    detail: this.commonService.msg(MESSAGE_CODE.E00024),
                  })
                );
              } else {
                // 出力メッセージがFAIL以外の場合

                // エラーメッセージ表示(バックエンドレスポンスを表示)
                this.messageData.toastMessage(
                  new ToastMessageData({
                    severity: TOAST.ERROR,
                    summary: this.commonService.msg(MESSAGE_CODE.E00002),
                    detail: response.body[0].Message,
                  })
                );
              }

              return;
            }

            // ファイル作成が正常終了の場合

            // 正常メッセージ表示
            this.messageData.toastMessage(
              new ToastMessageData({
                severity: TOAST.SUCCESS,
                summary: this.commonService.msg(MESSAGE_CODE.T00002),
                detail: this.commonService.msg(MESSAGE_CODE.T00013),
              })
            );
          });
      },
    });
  }
}
