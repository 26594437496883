/**
 * ヘッダータイトル設定
 */

/**
 * TOP
 */
// TOP画面
export const TopComponent = {
  default: 'TOP',
};

/**
 * マスタ管理
 */
// 営業日マスタ画面
export const BizdayComponent = {
  default: '営業日',
};

// 届先マスタ画面
export const DeliveryComponent = {
  default: '届先',
};

// 組織マスタ画面
export const DepartmentComponent = {
  default: '組織',
};

// お知らせマスタ画面
export const InformationComponent = {
  default: 'お知らせ',
};

// 販促資材マスタ画面
export const ItemComponent = {
  default: '資材',
};

// ユーザーマスタ画面
export const UserComponent = {
  default: 'ユーザ',
};

// セット品マスタ画面
export const SetMainComponent = {
  default: 'セット品',
};

// ファイル一括登録画面(マスタ)
export const FileUploadMasterComponent = {
  default: 'ファイル一括登録(マスタ)',
};

/** --------------------------------------------------------- */

/**
 * 在庫管理
 */
// 製作指示画面(入荷指示画面)
export const ProductionInstructionComponent = {
  default: '入荷指示',
};

// 在庫メンテナンスファイル登録画面
export const RegisterStockMaintenanceComponent = {
  default: '在庫登録・変更',
};

// 在庫メンテナンス管理画面(入庫・在庫調整履歴画面)
export const StockMaintenanceComponent = {
  default: '入庫・在庫調整履歴',
};

/** --------------------------------------------------------- */

/**
 * 発注管理
 */
// 発注選択画面
export const CartComponent = {
  default: '発注選択',
};

// 発注届先画面
export const DeliverySearchComponent = {
  default: '',
  'pages/order/delivery-search/1': '届先選択',
  'pages/order/delivery-search/2': '届先選択(新規)',
  'pages/order/delivery-search/3': '届先選択(送込)',
};

// 発注販促資材画面
export const ItemSearchComponent = {
  default: '',
  'pages/order/item-search/1': '資材選択',
  'pages/order/item-search/2': '資材選択(新規)',
};

// 発注販促資材リスト画面
export const ItemSearchListComponent = {
  default: '',
  'pages/order/item-search-list/3': '資材選択(送込)',
};

// 発注実績画面
export const OrderHistoryComponent = {
  default: '配送指示履歴',
};

// 注文登録画面
export const OrderRegisterComponent = {
  default: '注文登録',
};

// 発注画面
export const QuantityComponent = {
  'pages/order/quantity/1': '数量',
  'pages/order/quantity/2': '数量(新規)',
  'pages/order/quantity/3': '数量(送込)',
};

// ファイル一括登録画面(配送依頼)
export const FileUploadOrderComponent = {
  default: 'ファイル一括登録(配送依頼)',
};

/** --------------------------------------------------------- */

/**
 * 事務局
 */
// 情報出力画面
export const OutputComponent = {
  default: '情報出力',
};

// 発送ステータスファイル登録画面
export const RegisterShippingResultComponent = {
  default: '出荷ステータス更新',
};

// ファイル一括登録状況(JOB)
export const FileUploadHistoryComponent = {
  default: 'ファイル一括登録状況(JOB)',
};

// ファイル一括登録状況詳細(JOB)
export const HistoryDetailComponent = {
  default: 'ファイル一括登録状況詳細(JOB)',
};

/** --------------------------------------------------------- */

