import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

/** モジュール */
// ルーティング
import { sharedRoutingModule } from './shared-routing.module';

// 共通
import { LibraryModule } from 'src/app/library/library.module';

/** コンポーネント */
// GENERATE
import { GenerateDisplayComponent } from './generate/generate-display/generate-display.component';
import { GenerateDisplayInformationComponent } from './generate/generate-display/generate-display-information/generate-display-information.component';
import { GenerateInputComponent } from './generate/generate-input/generate-input.component';
import { GenerateInputInformationComponent } from './generate/generate-input/generate-input-information/generate-input-information.component';
import { GenerateInputFormComponent } from './generate/generate-input/generate-input-information/generate-input-form/generate-input-form.component';
import { GenerateInputErrorMessageComponent } from './generate/generate-input/generate-input-information/generate-input-error-message/generate-input-error-message.component';
import { GenerateSearchComponent } from './generate/generate-search/generate-search.component';
import { GenerateInputSetMaterialComponent } from './generate/generate-input/generate-input-information/generate-input-form/generate-input-set-material/generate-input-set-material.component';

// HTML_PARTS
import { DeleteDialogComponent } from './html-parts/delete-dialog/delete-dialog.component';
import { FooterComponent } from './html-parts/footer/footer.component';
import { HeaderComponent } from './html-parts/header/header.component';
import { MessageCommonComponent } from './html-parts/message-common/message-common.component';
import { CsvUploadComponent } from './html-parts/upload-file/csv-upload/csv-upload.component';
import { CsvErrorMessageComponent } from './html-parts/upload-file/csv-upload/csv-error-message/csv-error-message.component';
import { CsvUploadButtonComponent } from './html-parts/upload-file/csv-upload/csv-upload-button/csv-upload-button.component';
import { CsvUploadDataComponent } from './html-parts/upload-file/csv-upload/csv-upload-data/csv-upload-data.component';
import { LoadingComponent } from './html-parts/loading/loading.component';

// VALIDATOR
import { ValidatorMessageComponent } from './validator/validator-message.component';

// ローディング状態クラス
import { LoadingState } from './html-parts/loading/loading-state';

// メッセージクラス
import { MessageData } from './html-parts/message-common/message-data';

@NgModule({
  declarations: [
    // GENERATE
    GenerateDisplayComponent,
    GenerateDisplayInformationComponent,
    GenerateInputComponent,
    GenerateInputInformationComponent,
    GenerateInputFormComponent,
    GenerateInputErrorMessageComponent,
    GenerateSearchComponent,
    GenerateInputSetMaterialComponent,

    // HTML_PARTS
    DeleteDialogComponent,
    FooterComponent,
    HeaderComponent,
    MessageCommonComponent,
    CsvUploadComponent,
    CsvErrorMessageComponent,
    CsvUploadButtonComponent,
    CsvUploadDataComponent,
    LoadingComponent,

    // VALIDATOR
    ValidatorMessageComponent,
  ],
  imports: [CommonModule, sharedRoutingModule, LibraryModule],
  exports: [
    // GENERATE
    GenerateDisplayComponent,
    GenerateDisplayInformationComponent,
    GenerateInputComponent,
    GenerateInputInformationComponent,
    GenerateInputFormComponent,
    GenerateInputErrorMessageComponent,
    GenerateSearchComponent,
    GenerateInputSetMaterialComponent,

    // HTML_PARTS
    DeleteDialogComponent,
    FooterComponent,
    HeaderComponent,
    MessageCommonComponent,
    CsvUploadComponent,
    CsvErrorMessageComponent,
    CsvUploadButtonComponent,
    CsvUploadDataComponent,
    LoadingComponent,

    // VALIDATOR
    ValidatorMessageComponent,
  ],
  providers: [LoadingState, MessageData],
})
export class SharedModule {}
