import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { API_URL_ITEM, API_URL_STOCK } from 'manager/http-constants_key';
import { STOCK_FLAG, STOCK_TITLE } from '../constant';
import { Validator } from 'src/app/shared/validator/validator';
import { GenerateDisplayInformationComponent } from 'src/app/shared/generate/generate-display/generate-display-information/generate-display-information.component';
import { InputGenerateDisplay } from 'src/app/shared/generate/generate-display/generate-display-information/generate-display-information';
import { ITEM_TEMPLATE } from 'manager/template-constant';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { CommonService } from 'src/app/shared/service/common.service';
import { MessageData } from 'src/app/shared/html-parts/message-common/message-data';
import {
  LoadData,
  LoadingState,
} from 'src/app/shared/html-parts/loading/loading-state';

@Component({
  selector: 'app-stock',
  templateUrl: './stock.component.html',
  styleUrls: ['./stock.component.scss'],
})

/**
 * 入庫登録画面
 */
//  TODO 入力項目をアイテムアダプター化予定
export class StockComponent implements OnInit {
  // 更新対象ID
  @Output() reloadID = new EventEmitter<string>();

  /** 詳細情報 */
  @ViewChild(GenerateDisplayInformationComponent)
  generateDisplayInformationComponent: GenerateDisplayInformationComponent;

  // 画面.入力フォーム
  inputStockForm: FormGroup = this.formBuilder.group({
    stock_change_num: [
      '',
      {
        asyncValidators: [
          this.validator.CheckValidator(
            'stock_maintenance',
            'real_stock_change_num1',
            'NUMBER'
          ),
        ],
      },
    ],
    stock_change_date: [
      '',
      {
        asyncValidators: [
          this.validator.CheckValidator(
            'stock_maintenance',
            'real_stock_change_date1',
            'DATE'
          ),
        ],
      },
    ],
  });

  /* 画面用プロパティ */
  // 選択対象ID
  pkeyId: string;

  // 入庫登録／在庫調整フラグ
  stockInfo: string;

  // 画面タイトル
  title: string;

  // 入庫登録画面ダイアログ表示フラグ
  inputStockModal: boolean;

  // 入力項目値格納先(確認画面用)
  regist_number: string;
  regist_date: string;

  // 確認画面表示フラグ
  confirmationFlag: boolean;

  // 登録ボタン押下フラグ(データ登録中にボタン押下させないフラグ)
  insertFlag: boolean;

  constructor(
    private messageData: MessageData,
    private formBuilder: FormBuilder,
    private dbOperationService: DbOperationService,
    private validator: Validator,
    private commonService: CommonService,
    private loadingState: LoadingState
  ) {}

  ngOnInit() {}

  /**
   * 入庫登録／在庫調整
   * @param stockInfo 入庫登録／在庫調整フラグ
   * @param pkeyId 選択対象ID
   */
  public initial(stockInfo: string, pkeyId: string) {
    // 入庫登録／在庫調整画面を表示
    this.inputStockModal = true;

    // 入力フォーム入力値初期化処理
    this.inputStockForm.reset();

    // 入力フォーム状態初期化
    this.resetFlag();

    // タイトルを設定
    if (stockInfo == STOCK_FLAG.STOCK_REGIST) {
      // 入庫登録を設定
      this.title = STOCK_TITLE.STOCK_REGIST;
    } else {
      // 在庫調整を設定
      this.title = STOCK_TITLE.ADJUST_STOCK;
    }

    // 詳細情報表示
    let inputGenerateDisplay: InputGenerateDisplay = new InputGenerateDisplay();
    inputGenerateDisplay.endPoint = API_URL_ITEM;
    inputGenerateDisplay.templateId = ITEM_TEMPLATE.STOCK_OUTPUT_TEMPLATE_ID;
    this.generateDisplayInformationComponent.initial(
      inputGenerateDisplay,
      pkeyId
    );

    // 入庫登録／在庫調整フラグを格納
    this.stockInfo = stockInfo;

    // 選択対象IDを格納
    this.pkeyId = pkeyId;
  }

  /**
   * 入力項目値確認
   */
  public inputVerification() {
    // 確認画面用に入力値を取得
    this.regist_number = this.inputStockForm.get('stock_change_num').value;
    this.regist_date = this.inputStockForm.get('stock_change_date').value;

    // 確認画面を表示
    this.confirmationFlag = true;
  }

  /**
   * 登録処理
   */
  public insertInputForm() {
    // 登録ボタン押下フラグをONにする(登録、戻るボタン非活性化)
    this.insertFlag = true;

    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: '',
        background_color: '',
        opacity: 0.3,
      })
    );

    // 入庫登録／在庫調整判定
    if (this.stockInfo == STOCK_FLAG.STOCK_REGIST) {
      // 入庫登録の場合

      // IDを設定
      this.inputStockForm.value['item_code'] = String(this.pkeyId);

      // 入庫登録の登録
      this.dbOperationService
        .insertData(API_URL_STOCK, this.inputStockForm.value)
        .subscribe((response) => {
          // 入力レスポンスメッセージを表示
          if (
            this.messageData.responseToastMessage(
              response,
              null,
              this.commonService.msg(MESSAGE_CODE.I00001)
            )
          ) {
            // 正常終了の場合

            // 更新対象IDに選択対象IDをセット
            this.reloadID.emit(this.pkeyId);

            // 入力画面ダイアログを閉じる
            this.inputStockModal = false;
          } else {
            // 異常終了の場合

            // 入力フォーム状態初期化
            this.resetFlag();
          }

          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadEnd();
        });
    } else {
      // 在庫調整の場合

      // 在庫調整の登録
      this.dbOperationService
        .updateData(API_URL_STOCK, this.pkeyId, this.inputStockForm.value)
        .subscribe((response) => {
          // 入力レスポンスメッセージを表示
          if (
            this.messageData.responseToastMessage(
              response,
              null,
              this.commonService.msg(MESSAGE_CODE.I00001)
            )
          ) {
            // 正常終了の場合

            // 更新対象IDに選択対象IDをセット
            this.reloadID.emit(this.pkeyId);

            // 入力画面ダイアログを閉じる
            this.inputStockModal = false;
          } else {
            // 異常終了の場合

            // 入力フォーム状態初期化
            this.resetFlag();
          }

          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadEnd();
        });
    }
  }

  /**
   * 入力フォーム状態初期化
   */
  private resetFlag() {
    // 確認画面表示フラグを初期化
    this.confirmationFlag = false;

    // 登録ボタン押下フラグを初期化
    this.insertFlag = false;
  }
}
