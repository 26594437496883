import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  API_URL_INPUT_ORDER,
  API_URL_ORDER,
  API_URL_ORDER_STATUS,
} from 'manager/http-constants_key';
import { ORDER_PERFORMANCE_CONSTANT, STATUS_UPDATE_TITLE } from '../constant';
import { ORDER_HISTORY_TEMPLATE } from 'manager/template-constant';
import { ORDER$STATUS } from 'src/app/shared/constant/db-constant';
import { GenerateDisplayInformationComponent } from 'src/app/shared/generate/generate-display/generate-display-information/generate-display-information.component';
import { InputGenerateDisplay } from 'src/app/shared/generate/generate-display/generate-display-information/generate-display-information';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { GenerateInputErrorMessageComponent } from 'src/app/shared/generate/generate-input/generate-input-information/generate-input-error-message/generate-input-error-message.component';
import { GenerateInputFormComponent } from 'src/app/shared/generate/generate-input/generate-input-information/generate-input-form/generate-input-form.component';
import { GENERATE_INPUT_TYPE } from 'src/app/shared/generate/generate-input/constant';
import { MessageData } from 'src/app/shared/html-parts/message-common/message-data';
import {
  LoadData,
  LoadingState,
} from 'src/app/shared/html-parts/loading/loading-state';
import { LOADING_KEY } from 'src/app/shared/constant/constant';

@Component({
  selector: 'app-update-status',
  templateUrl: './update-status.component.html',
  styleUrls: ['./update-status.component.scss'],
})

/**
 * 発注実績 ステータス更新画面
 */
export class UpdateStatusComponent implements OnInit {
  /** 詳細情報 */
  @ViewChild(GenerateDisplayInformationComponent)
  generateDisplayInformationComponent: GenerateDisplayInformationComponent;

  /** 入力エラーメッセージ */
  @ViewChild(GenerateInputErrorMessageComponent)
  generateInputErrorMessageComponent: GenerateInputErrorMessageComponent;

  /** 入力フォーム */
  @ViewChild(GenerateInputFormComponent)
  generateInputFormComponent: GenerateInputFormComponent;

  // 更新対象ID
  @Output() reloadID = new EventEmitter<string>();

  /* 画面用プロパティ */
  // 画面タイトル
  title: string;

  // IDコード
  pkeyId: string;

  // 発注ステータス
  // TODO 森永では未使用
  status: string;

  // ステータス更新画面ダイアログ表示フラグ
  updateModal: boolean;

  // 金額情報出力オブジェクトリスト
  moneyDisplayList: any[] = new Array();

  constructor(
    private dbOperationService: DbOperationService,
    private commonService: CommonService,
    private messageData: MessageData,
    private loadingState: LoadingState
  ) {}

  ngOnInit() {}

  /**
   * 更新画面表示
   * @param pkeyId 対象データのID
   * @param status 発注ステータス // TODO 森永では未使用
   */
  public initial(pkeyId, status) {
    // 更新画面ダイアログを表示
    this.updateModal = true;

    // 対象データのIDをプロパティに格納
    this.pkeyId = pkeyId;

    // ステータスをプロパティに格納
    // TODO 森永では未使用
    this.status = status;

    // 入力エラーメッセージをクリア
    this.generateInputErrorMessageComponent.clear();

    // 配送依頼(ステータス)タイトルを設定
    this.title = STATUS_UPDATE_TITLE.DELIVERY_REQUEST;

    // 配送依頼(ステータス)テンプレートIDで詳細情報表示
    let inputGenerateDisplay: InputGenerateDisplay = new InputGenerateDisplay();
    inputGenerateDisplay.endPoint = API_URL_ORDER;
    inputGenerateDisplay.templateId =
      ORDER_HISTORY_TEMPLATE.APPROVAL_REQUEST_OUTPUT_TEMPLATE_ID;
    this.generateDisplayInformationComponent.initial(
      inputGenerateDisplay,
      pkeyId
    );

    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart();

    // 金額情報取得処理
    this.dbOperationService
      .getDisplayData(
        API_URL_ORDER,
        ORDER_HISTORY_TEMPLATE.MONEY_OUTPUT_TEMPLATE_ID,
        pkeyId
      )
      .subscribe((response) => {
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3);

        // 金額情報が取得されたか否か
        if (!this.commonService.checkNoneResponse(response)) {
          // 金額情報が取得された場合

          // 金額情報を設定
          this.moneyDisplayList = response.body;

          // 金額合計を追加
          this.moneyDisplayList.push({
            column_name: '注文合計金額',
            data: this.moneyDisplayList[0].data * this.moneyDisplayList[1].data,
          });
        }
      });

    // 入力フォーム表示
    this.generateInputFormComponent.initial(
      GENERATE_INPUT_TYPE.NEW,
      API_URL_INPUT_ORDER,
      null,
      ORDER_HISTORY_TEMPLATE.APPROVAL_REQUEST_INPUT_TEMPLATE_ID,
      null
    );
  }

  /**
   * 入力エラー情報出力
   * @param generateInputErrorInformation 入力フォームエラー情報
   */
  public outputGenerateInputErrorInformation(
    generateInputErrorInformation: any
  ) {
    // 入力フォームエラー情報を出力
    this.generateInputErrorMessageComponent.initial(
      generateInputErrorInformation
    );
  }

  /**
   * 登録処理
   */
  public insertGenerateInputInformation(generateInputInformation: any): void {
    // 寄贈可否の判定
    if (
      ORDER$STATUS.STATUS_ONE ==
      generateInputInformation.value[ORDER_PERFORMANCE_CONSTANT.AVAILABILITY]
    ) {
      // 寄贈可能の場合

      // 承認済み(ステータス)を設定
      generateInputInformation.value[ORDER_PERFORMANCE_CONSTANT.STATUS] =
        ORDER$STATUS.STATUS_SIX;
    } else {
      // 寄贈不可の場合

      // キャンセル(ステータス)を設定
      generateInputInformation.value[ORDER_PERFORMANCE_CONSTANT.STATUS] =
        ORDER$STATUS.STATUS_NINE;
    }

    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: '',
        background_color: '',
        opacity: 0.3,
      }),
      LOADING_KEY.DATA_UPDATE
    );

    // DBアップデート
    this.dbOperationService
      .updateData(
        API_URL_ORDER_STATUS,
        this.pkeyId,
        generateInputInformation.value
      )
      .subscribe((response) => {
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadEnd(LOADING_KEY.DATA_UPDATE);

        // 入力レスポンスメッセージを表示
        if (
          this.messageData.responseToastMessage(
            response,
            this.commonService.msg(MESSAGE_CODE.T00001),
            this.commonService.msg(MESSAGE_CODE.U00002)
          )
        ) {
          // 正常終了の場合

          // 更新対象IDに選択対象IDをセット
          this.reloadID.emit(this.pkeyId);

          // 入力画面ダイアログを閉じる
          this.updateModal = false;
        } else {
          // 異常終了の場合

          // 入力フォーム状態初期化
          this.generateInputFormComponent.resetFlag();
        }
      });
  }
}
