import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_URL_SEARCH_INVENTORY_SUMMARYS } from 'manager/http-constants_key';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonService } from 'src/app/shared/service/common.service';

@Injectable({
  providedIn: 'root',
})

/**
 * 棚卸帳票出力サービスクラス
 */
export class InventoryReportService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  /**
   * 検索条件情報を取得
   * @returns JSONデータ
   */
  public getSearchData(): Observable<any> {
    // LambdaのEndpointを生成
    const url = this.commonService.url(API_URL_SEARCH_INVENTORY_SUMMARYS);

    // 検索条件情報を返却する
    return this.http
      .get(url, { observe: 'response' })
      .pipe(map((res: HttpResponse<any>) => res));
  }
}
