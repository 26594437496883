export const SET_MAIN_CONSTANT = {
  // csvファイル名
  CSV_FILENAME: 'setMain',
  // 画像登録最大サイズ
  FILE_SIZE: 500000,
  // 対象テーブル
  TARGET_TABLE: 'set_main',
  // CSV出力用対象テーブル
  TARGET_TABLE_CSV: 'set_main_item_item',
};
