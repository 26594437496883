<!-- 詳細情報出力オブジェクトリスト(画面表示情報)分ループ-->
<ng-container *ngFor="let outputGenerateDisplay of outputGenerateDisplayList">
  <!-- 詳細情報出力領域 -->
  <p-table
    [value]="outputGenerateDisplay.generateDisplay"
    styleClass="p-datatable-sm"
  >
    <!-- 詳細情報タイトル -->
    <ng-template pTemplate="header">
      <tr class="p-col-2">
        <th colspan="2">{{ outputGenerateDisplay.title }}</th>
      </tr>
    </ng-template>
    <!-- 詳細情報出力箇所 -->
    <ng-template pTemplate="body" let-generateDisplayInformation>
      <tr>
        <!-- カラム名称 -->
        <td class="table-body-label">
          {{ generateDisplayInformation.column_name }}
        </td>

        <!-- カラムデータ -->
        <!-- データ判定 -->
        <!-- プライマリーキーの場合 -->
        <td
          class="table-body-data"
          *ngIf="'P' == generateDisplayInformation.column_pkey; else notPkey"
        >
          {{ generateDisplayInformation.data }}
        </td>

        <!-- プライマリーキー以外の場合 -->
        <ng-template #notPkey>
          <!-- カラム判定 -->
          <td
            class="table-body-data"
            [ngSwitch]="generateDisplayInformation?.input_type"
          >
            <span *ngSwitchCase="'IMAGE'">
              <!-- 画像の場合 -->
              <img [src]="generateDisplayInformation.data" alt="image" />
            </span>
            <span *ngSwitchDefault>
              <!-- TODO 特定カラムID判定は暫定対応-->
              <span
                *ngIf="
                  generateDisplayInformation.column_id == 'item_tanka' ||
                    generateDisplayInformation.column_id ==
                      'decision_unitprice' ||
                    generateDisplayInformation.column_id == 'delivery_fee' ||
                    generateDisplayInformation.column_id ==
                      'approval_decision_unitprice' ||
                    generateDisplayInformation.column_id ==
                      'initial_item_tanka' ||
                    generateDisplayInformation.column_id ==
                      'initial_item_mediation_cost1' ||
                    generateDisplayInformation.column_id ==
                      'item_mediation_cost1' ||
                    generateDisplayInformation.column_id ==
                      'initial_item_mediation_cost2' ||
                    generateDisplayInformation.column_id ==
                      'item_mediation_cost2' ||
                    generateDisplayInformation.column_id == 'pdf_link';
                  else PkExcepting
                "
              >
                {{ generateDisplayInformation.data | number }}
              </span>
              <span
                *ngIf="
                  generateDisplayInformation.column_id == 'pdf_link' &&
                  generateDisplayInformation.data
                "
              >
                <a
                  href="{{ generateDisplayInformation.data }}"
                  target="_blank"
                  rel="noopener"
                  >リンク</a
                >
              </span>

              <!-- 特定カラムID以外の場合 -->
              <ng-template #PkExcepting>
                {{ generateDisplayInformation.data }}
              </ng-template>
            </span>
          </td>
        </ng-template>
      </tr>
    </ng-template>
  </p-table>
  <!-- 改行 -->
  <br />
</ng-container>
