import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { SESSION_KEY } from 'src/app/shared/constant/session-constants';
import { InputGenerateDisplay } from 'src/app/shared/generate/generate-display/generate-display-information/generate-display-information';
import { GenerateDisplayInformationComponent } from 'src/app/shared/generate/generate-display/generate-display-information/generate-display-information.component';
import { 
  API_URL_DISPLAY_SET_MAIN,
  API_URL_DISPLAY_SET_ITEM,
  API_URL_SET
} from 'manager/http-constants_key';
import { SET_MAIN_TEMPLATE } from 'manager/template-constant';
import { CommonService } from 'src/app/shared/service/common.service';
import { SET_MAIN_CONSTANT } from '../constant';
import { TOAST } from 'src/app/shared/constant/primeng-constants';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import {
  CONSTANT,
  IMAGE_EXTENSION,
  NO_IMAGE_FILE_NAME,
} from 'src/app/shared/constant/constant';
import {
  MessageData,
  ToastMessageData,
} from 'src/app/shared/html-parts/message-common/message-data';
import {
  LoadData,
  LoadingState,
} from 'src/app/shared/html-parts/loading/loading-state';

@Component({
  selector: 'app-set-main-detail',
  templateUrl: './set-main-detail.component.html',
  styleUrls: [
    '../../../../shared/generate/generate-display/generate-display.component.scss',
    './set-main-detail.component.scss',
  ],
})

/**
 * 販促資材マスタ詳細画面
 */
export class SetMainDetailComponent {
  // 更新対象ID
  @Output() reloadID = new EventEmitter<string>();

  /** 詳細情報 */
  @ViewChild(GenerateDisplayInformationComponent)
  generateDisplayInformationComponent: GenerateDisplayInformationComponent;


  // ログインユーザ情報
  loginUser = JSON.parse(
    window.sessionStorage.getItem(SESSION_KEY.loginUserInformation)
  );

  /* 画面用プロパティ */
  // 詳細画面表示フラグ
  detailsNav: boolean;

  // 登録資材ヘッダー情報格納先
  columnOrder: any[] = new Array();

  // 登録資材検索結果一覧格納先
  searchResultsList: any[] = new Array();

  // 登録資材ヘッダー情報格納先(サブ)
  subColumnOrder: any[] = new Array();

  // 登録資材検索結果一覧格納先(サブ)
  subSearchResultsList: any[] = new Array();

  // 詳細ID
  pkeyId: string;

  // ヘッダ数
  columnNum: number;
  // ヘッダ数(サブ)
  subColumnNum: number;

  constructor(
    private dbOperationService: DbOperationService,
    private messageData: MessageData,
    private commonService: CommonService,
    private loadingState: LoadingState
  ) {}

  /**
   * 詳細情報表示
   * @param pkeyId 選択対象ID
   */
  public initial(pkeyId: string) {
    // 詳細画面表示
    this.detailsNav = true;

    // 詳細情報(メイン)
    let inputGenerateDisplay: InputGenerateDisplay = new InputGenerateDisplay();
    inputGenerateDisplay.endPoint = API_URL_DISPLAY_SET_MAIN;
    inputGenerateDisplay.templateId = SET_MAIN_TEMPLATE.OUTPUT_TEMPLATE_ID;
    this.generateDisplayInformationComponent.initial(
      inputGenerateDisplay,
      pkeyId
    );

    /* 登録資材ヘッダー情報取得処理(画面用)(サブ) */
    this.dbOperationService
      .getHeaderList(SET_MAIN_TEMPLATE.OUTPUT_TEMPLATE2_ID)
      .subscribe((response) => {
        this.subColumnOrder = response.body;
        this.subColumnNum = this.subColumnOrder.length;
      });
    /* 登録資材一覧取得処理(画面用) */
    this.dbOperationService
      .getDisplayData(
        API_URL_DISPLAY_SET_ITEM,
        SET_MAIN_TEMPLATE.OUTPUT_TEMPLATE2_ID,
        pkeyId
      )
      .subscribe((response) => {
        // コード値の一覧情報が存在するか否か
        if (!this.commonService.checkNoneResponse(response)) {
          // セット品マスタ一覧情報のJSONをオブジェクトに格納する
          this.subSearchResultsList = response.body;
        } else {
          this.subSearchResultsList = new Array();
        }
      });

    // 選択対象IDを格納
    this.pkeyId = pkeyId;
  }

  /**
   * ファイルアップロード
   * @param event 画像情報
   * @param fileUpload ボタン動作状態
   */
  public uploadFile(event, fileUpload) {
    // 画像拡張子を取得
    const imageExtension = event.files[0].name.substring(
      event.files[0].name.lastIndexOf(CONSTANT.PERIOD)
    );

    // 画像拡張子が".jpg" or ".jpeg" or ".png"か否か判定
    if (
      !(
        IMAGE_EXTENSION.JPG == imageExtension ||
        IMAGE_EXTENSION.JPEG == imageExtension ||
        IMAGE_EXTENSION.PNG == imageExtension
      )
    ) {
      // 画像拡張子が".jpg" or ".jpeg" or ".png"以外の場合

      // 画像拡張子エラーメッセージ
      this.messageData.toastMessage(
        new ToastMessageData({
          severity: TOAST.ERROR,
          summary: this.commonService.msg(MESSAGE_CODE.E00009),
          detail: this.commonService.msg(
            MESSAGE_CODE.E00010,
            'jpg又はjpeg又はpng'
          ),
        })
      );

      // ボタン動作状態をクリア
      fileUpload.clear();

      // 処理を終了
      return;
    }

    // 画像ファイルサイズの判定
    if (SET_MAIN_CONSTANT.FILE_SIZE < event.files[0].size) {
      // 画像サイズが500KB以上の場合

      // 画像サイズエラーメッセージ
      this.messageData.toastMessage(
        new ToastMessageData({
          severity: TOAST.ERROR,
          summary: this.commonService.msg(MESSAGE_CODE.E00009),
          detail: this.commonService.msg(MESSAGE_CODE.E00011, '500KB'),
        })
      );

      // ボタン動作状態をクリア
      fileUpload.clear();

      // 処理を終了
      return;
    }

    // 画像登録
    this.dbOperationService.insertImage(this.pkeyId, event.files[0]);

    // 画像登録完了メッセージ
    this.messageData.toastMessage(
      new ToastMessageData({
        severity: TOAST.SUCCESS,
        summary: this.commonService.msg(MESSAGE_CODE.I00003),
        detail: this.commonService.msg(MESSAGE_CODE.T00003),
      })
    );

    // ボタン動作状態をクリア
    fileUpload.clear();
  }

  /**
   * 画像ダウンロード
   */
  protected imageDownload() {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00013),
      })
    );

    // 画像URl取得処理
    this.dbOperationService
      .getDisplayData(
        API_URL_SET,
        /** 仮実装 */
        // ITEM_TEMPLATE.OUTPUT_IMAGE_TEMPLATE_ID,
        null,
        this.pkeyId
      )
      .subscribe((response) => {
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3);

        // 詳細IDの画像URlが存在するか否か
        if (this.commonService.checkNoneResponse(response)) {
          // 詳細IDの画像URlが存在しない場合

          // 画像ダウンロード失敗メッセージ
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.WARN,
              summary: this.commonService.msg(MESSAGE_CODE.E00022),
              detail: this.commonService.msg(MESSAGE_CODE.E00023),
            })
          );

          return;
        }

        // 画像ファイル名が画像無しファイル名か否か
        if (response.body[0].data.match(NO_IMAGE_FILE_NAME)) {
          // 画像無しファイル名の場合

          // 画像ダウンロード失敗メッセージ
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.WARN,
              summary: this.commonService.msg(MESSAGE_CODE.E00022),
              detail: this.commonService.msg(MESSAGE_CODE.E00023),
            })
          );

          return;
        }

        // 画像ダウンロードを実行
        window.location.href = response.body[0].data;
      });
  }

  /**
   * 親画面へ返却する
   */
  public return(reloadID: string) {
    // 更新対象IDにプライマリキーをセット
    this.reloadID.emit(reloadID);
  }
}
