<!-- 入庫登録画面 -->
<p-dialog [header]="title" [(visible)]="inputStockModal" [modal]="true">
  <!-- 詳細情報-->
  <div class="generateDisplayInformation">
    <app-generate-display-information></app-generate-display-information>
  </div>

  <!-- 入力画面 -->
  <div class="inputStockForm" *ngIf="!confirmationFlag">
    <form [formGroup]="inputStockForm">
      <!-- 入力領域 -->
      <p-table [value]="[{}]" styleClass="p-datatable-sm">
        <!-- 入力タイトル -->
        <ng-template pTemplate="header">
          <tr>
            <th colspan="2">入力</th>
          </tr>
        </ng-template>
        <!-- 入力ボデイ -->
        <ng-template pTemplate="body">
          <!-- 調整数 or 入庫数 -->
          <tr>
            <!-- カラム名称 -->
            <td class="label-center">
              <label class="p-col-fixed">
                <ng-container *ngIf="stockInfo == 'stock-regist'">
                  入庫数
                </ng-container>
                <ng-container *ngIf="stockInfo == 'adjust-stock'">
                  調整数
                </ng-container>
              </label>
            </td>
            <!-- 入力項目 -->
            <td>
              <div class="ui-fluid">
                <input
                  name="stock_change_num"
                  type="number"
                  maxlength="10"
                  formControlName="stock_change_num"
                  pInputText
                />
              </div>
              <!-- エラーメッセージ出力箇所 -->
              <app-validator-message
                [control]="inputStockForm.get('stock_change_num')"
              >
              </app-validator-message>
            </td>
          </tr>
          <!-- 登録日 or 入庫日 -->
          <tr>
            <!-- カラム名称 -->
            <td class="label-center">
              <label class="p-col-fixed">
                <ng-container *ngIf="stockInfo == 'stock-regist'">
                  入庫日
                </ng-container>
                <ng-container *ngIf="stockInfo == 'adjust-stock'">
                  登録日
                </ng-container>
              </label>
            </td>
            <!-- 入力項目 -->
            <td>
              <div class="ui-fluid">
                <p-calendar
                  [name]="'stock_change_date'"
                  [showIcon]="true"
                  [yearNavigator]="true"
                  [monthNavigator]="true"
                  dateFormat="yy/mm/dd"
                  dataType="string"
                  appendTo="body"
                  yearRange="2000:2999"
                  [formControlName]="'stock_change_date'"
                  translate
                >
                </p-calendar>
                <!-- エラーメッセージ出力箇所 -->
                <app-validator-message
                  [control]="inputStockForm.get('stock_change_date')"
                >
                </app-validator-message>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </form>

    <!-- ボタンエリア -->
    <div class="buttonArea">
      <button
        class="button"
        type="submit"
        label="入力チェック"
        (click)="inputVerification()"
        [disabled]="!inputStockForm.valid"
        pButton
      ></button>
    </div>
  </div>

  <!-- 入力確認 -->
  <div class="inputStockConfirmation" *ngIf="confirmationFlag">
    <!-- 確認情報出力領域 -->
    <p-table styleClass="p-datatable-sm" [value]="[{}]">
      <!-- 確認情報タイトル -->
      <ng-template pTemplate="header">
        <tr class="p-col-2">
          <th colspan="2">確認</th>
        </tr>
      </ng-template>
      <!-- 確認情報出力箇所 -->
      <ng-template pTemplate="body">
        <!-- 調整数 or 入庫数 -->
        <tr>
          <!-- カラム名称 -->
          <td class="label-center">
            <ng-container *ngIf="stockInfo == 'stock-regist'">
              入庫数
            </ng-container>
            <ng-container *ngIf="stockInfo == 'adjust-stock'">
              調整数
            </ng-container>
          </td>
          <!-- 入力項目値 -->
          <td class="ui-fluid confirmationData">
            {{ regist_number }}
          </td>
        </tr>
        <!-- 登録日 or 入庫日 -->
        <tr>
          <!-- カラム名称 -->
          <td class="label-center">
            <ng-container *ngIf="stockInfo == 'stock-regist'">
              入庫日
            </ng-container>
            <ng-container *ngIf="stockInfo == 'adjust-stock'">
              登録日
            </ng-container>
          </td>
          <!-- 入力項目値 -->
          <td class="ui-fluid confirmationData">
            {{ regist_date }}
          </td>
        </tr>
      </ng-template>
    </p-table>

    <!-- ボタンエリア -->
    <div class="buttonArea">
      <button
        class="button"
        type="button"
        label="登録"
        (click)="insertInputForm()"
        [disabled]="insertFlag"
        pButton
      ></button>
      <button
        class="button"
        type="button"
        label="戻る"
        (click)="confirmationFlag = false"
        [disabled]="insertFlag"
        pButton
      ></button>
    </div>
  </div>
</p-dialog>
