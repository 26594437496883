<!-- メイン領域-->
<mat-drawer-container class="main-container" autosize>
  <!-- 一覧画面表示領域-->
  <p-table
    #table
    styleClass="p-datatable-striped master-datatable"
  >
    <ng-template pTemplate="caption">
      <div class="fileUploadMaster">
        <div id="radioGroup">
          <div *ngFor="let fileType of fileTypeList" class="radioBtn">
            <p-radioButton name="sFileTypeList" [value]="fileType.type_code" [(ngModel)]="selectFileType"></p-radioButton>
            <label [for]="fileType.type_code">{{fileType.type_name}}</label>
          </div>
        </div>
        <br>
        <p-fileUpload name="fileUpload" 
                      customUpload="true"
                      (uploadHandler)="uploadFile($event)"
                      multiple="multiple"
                      chooseLabel="ファイル選択" uploadLabel="登録" cancelLabel="キャンセル"
                      >
          <ng-template pTemplate="content">
            <ul *ngIf="uploadedFiles.length">
              <li *ngFor="let file of uploadedFiles">{{file.name}} - {{file.size}} bytes</li>
            </ul>
          </ng-template>
        </p-fileUpload>
      </div>
      <div id="complate_msg">
        <p>アップロードが完了しました。(ジョブID：{{sendJobId}})</p> 
        <p>ジョブステータスは、ジョブ管理画面にてご確認お願いします。</p>
        <button
          type="button"
          (click)="toFileUploadHistory()"
          class="p-mr-2"
          label="JOB管理"
          tooltipPosition="bottom"
          pButton
        ></button>
      </div>
    </ng-template>
    <router-outlet></router-outlet>
  </p-table>
</mat-drawer-container>