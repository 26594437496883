/** 入力エラーメッセージオブジェクト */
export class InputErrorMessage {
  // 販促資材ID
  private _itemId: string;

  // エラーメッセージリスト
  private _errorMessageList: ErrorMessage[];

  set itemId(itemId: string) {
    this._itemId = itemId;
  }

  get itemId(): string {
    return this._itemId;
  }

  set errorMessageList(errorMessageList: ErrorMessage[]) {
    this._errorMessageList = errorMessageList;
  }

  get errorMessageList(): ErrorMessage[] {
    return this._errorMessageList;
  }
}

/** エラーメッセージオブジェクト */
export class ErrorMessage {
  // カラムID
  private _columnId: string;

  // カラム名称
  private _columnName: string;

  // エラーメッセージ
  private _message: string;

  set columnId(columnId: string) {
    this._columnId = columnId;
  }

  get columnId(): string {
    return this._columnId;
  }

  set columnName(columnName: string) {
    this._columnName = columnName;
  }

  get columnName(): string {
    return this._columnName;
  }

  set message(message: string) {
    this._message = message;
  }

  get message(): string {
    return this._message;
  }
}
