import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { REGISTER_STOCK_MAINTENANCE_TEMPLATE } from 'manager/template-constant';
import { CONSTANT, FILE_EXTENSION } from 'src/app/shared/constant/constant';
import { LoadingState } from 'src/app/shared/html-parts/loading/loading-state';
import { EXEC_TYPE } from 'src/app/shared/html-parts/upload-file/csv-upload/constant';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';

@Component({
  selector: 'app-register-stock-maintenance',
  templateUrl: './register-stock-maintenance.component.html',
  styleUrls: ['./register-stock-maintenance.component.scss'],
})

/**
 * 在庫メンテナンスファイル登録画面
 */
export class RegisterStockMaintenanceComponent implements OnInit {
  // 在庫メンテナンス区分
  workType: FormControl = this.formBuilder.control(
    CONSTANT.EMPTY_STRING,
    Object()
  );

  // CSVファイル登録実行タイプ
  execType: string = EXEC_TYPE.UPDATE;

  // CSVファイル確認画面ヘッダーテンプレート
  csvUploadHeaderTemplateId: number =
    REGISTER_STOCK_MAINTENANCE_TEMPLATE.CONFIRMATION_HEADER_TEMPLATE_ID;

  // 許容ファイル拡張子
  toleranceExtension: string = FILE_EXTENSION.CSV;

  // 業務区分変更プルダウン
  workChangeInput: any;

  // 登録用追加データ
  addData: Object;

  // データ取り込み状態
  captureFlag: boolean;

  constructor(
    private dbOperationService: DbOperationService,
    private loadingState: LoadingState,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart();

    /* 計画変更プルダウン取得処理 */
    // 計画変更プルダウン取得
    this.dbOperationService.getDicValues(300).subscribe((response) => {
      // 計画変更プルダウンを格納
      this.workChangeInput = response.body[0];

      // 業務変更プルダウンオブジェクトを生成
      // 登録用追加データに業務区分変更プルダウンの1行目をセット
      this.addData = {
        workType: response.body[0].dic_value[0].code,
      };

      // 画面ロードフラグをOFF(ロード終了)
      this.loadingState.loadEnd();
    });
  }

  /**
   * 業務変更プルダウン
   */
  public workChange() {
    // 登録用追加データにカラムID及びプルダウンデータを格納
    this.addData = {
      workType: this.workType.value,
    };
  }

  /**
   * 業務変更プルダウンの無効化状態
   * @param captureFlag 取り込み状態フラグ
   */
  public disabledWorkChange(captureFlag: boolean) {
    // 取り込み状態フラグがtrueか否か
    if (captureFlag) {
      // 取り込み状態フラグがtrueの場合

      // 在庫メンテナンス区分を非活性化
      this.workType.disable();
    } else {
      // 取り込み状態フラグがfalseの場合

      // 在庫メンテナンス区分を活性化
      this.workType.enable();
    }
  }
}
