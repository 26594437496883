import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  API_URL_EVENT,
  API_URL_INPUT_EVENT,
  API_URL_SEARCH_EVENT,
  API_URL_SUB_WORK_ORDERS,
  API_URL_WORK_ORDER,
  API_URL_WORK_ORDER_ITEM,
} from 'manager/http-constants_key';
import { ITEM_SEARCH_LIST_TEMPLATE } from 'manager/template-constant';
import { CONSTANT, DATE_FORMAT } from 'src/app/shared/constant/constant';
import {
  DEPARTMENT$DEPARTMENT_TYPE,
  USER$ADMIT,
} from 'src/app/shared/constant/db-constant';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { SESSION_KEY } from 'src/app/shared/constant/session-constants';
import { InputGenerateDisplay } from 'src/app/shared/generate/generate-display/generate-display-information/generate-display-information';
import { GenerateDisplayComponent } from 'src/app/shared/generate/generate-display/generate-display.component';
import { GENERATE_INPUT_TYPE } from 'src/app/shared/generate/generate-input/constant';
import { GenerateInputComponent } from 'src/app/shared/generate/generate-input/generate-input.component';
import { ExportFileService } from 'src/app/shared/html-parts/export-file/export-file.service';
import {
  LoadData,
  LoadingState,
} from 'src/app/shared/html-parts/loading/loading-state';
import { MessageData } from 'src/app/shared/html-parts/message-common/message-data';
import { CommonService } from 'src/app/shared/service/common.service';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { ITEM_SEARCH_LIST_CONSTANT } from './constant';
import { ItemSearchListManagementDetailComponent } from './item-search-list-management-detail/item-search-list-management-detail.component';

@Component({
  selector: 'app-item-search-list',
  templateUrl: './item-search-list.component.html',
  styleUrls: ['../order.component.scss', './item-search-list.component.scss'],
})

/**
 * 発注販促資材リスト画面
 */
export class ItemSearchListComponent implements OnInit {
  /** 詳細画面 */
  @ViewChild(GenerateDisplayComponent)
  generateDisplayItemsComponent: GenerateDisplayComponent;

  /** 詳細画面(管理・承認ユーザ) */
  @ViewChild(ItemSearchListManagementDetailComponent)
  itemSearchListManagementDetailComponent: ItemSearchListManagementDetailComponent;

  /** 新規登録画面 */
  @ViewChild(GenerateInputComponent)
  generateInputComponent: GenerateInputComponent;

  // ログインユーザ情報
  loginUser = JSON.parse(
    window.sessionStorage.getItem(SESSION_KEY.loginUserInformation)
  );

  // カテゴリ1(クエリパラメータ)
  // TODO 森永で同一ユーザで3画面切り分けるために使用
  category1: string = null;

  // 検索項目生成エンドポイント
  public searchEndPoint: string = API_URL_SEARCH_EVENT;

  // 検索項目生成テンプレートID
  searchTemplateId: number =
    // ログインユーザを判定
    DEPARTMENT$DEPARTMENT_TYPE.TYPE_FORMER == this.loginUser.department_type &&
    USER$ADMIT.ADMIT_GENERAL == this.loginUser.admit
      ? // ログインユーザが一般の場合
        ITEM_SEARCH_LIST_TEMPLATE.GENERAL_SEARCH_TEMPLATE_ID
      : // ログインユーザが管理・承認ユーザの場合
        ITEM_SEARCH_LIST_TEMPLATE.MANAGEMENT_SEARCH_TEMPLATE_ID;

  // 検索項目生成条件
  searchOueryParameters: object = {
    category1: this.route.snapshot.params.category1,
  };

  // 入力項目生成条件
  inputOueryParameters: object = {
    category1: this.route.snapshot.params.category1,
  };

  /* 画面用プロパティ */
  // 画面ヘッダー情報格納先
  columnOrder: any[] = new Array();

  // 画面検索結果一覧格納先
  searchResultsList: any[] = new Array();

  // 検索条件保持
  generateSearchItems: Object;

  // 検索結果合計計画数
  searchSumPlanNum: number = 0;

  // 最大表示件数メッセージ
  maxRowMessage: string = "";

  constructor(
    private dbOperationService: DbOperationService,
    private messageData: MessageData,
    private router: Router,
    private commonService: CommonService,
    public datePipe: DatePipe,
    private loadingState: LoadingState,
    private exportFileService: ExportFileService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // パスパラメータの変更を検知
    // TODO 森永で同一ユーザで3画面切り分けるために使用
    this.route.paramMap.subscribe((paramsMap) => {
      // パスパラメータを格納
      // this.category1 = paramsMap.get('category1');

      // 画面ロードフラグをON(ロード中状態)
      this.loadingState.loadStart();

      /* ヘッダー情報取得処理(画面用) */
      this.dbOperationService
        .getHeaderList(
          // ログインユーザを判定
          DEPARTMENT$DEPARTMENT_TYPE.TYPE_FORMER ==
            this.loginUser.department_type &&
            USER$ADMIT.ADMIT_GENERAL == this.loginUser.admit
            ? // ログインユーザが一般の場合
              ITEM_SEARCH_LIST_TEMPLATE.GENERAL_SEARCH_RESULTS_TEMPLATE_ID
            : // ログインユーザが管理・承認ユーザの場合
              ITEM_SEARCH_LIST_TEMPLATE.MANAGEMENT_SEARCH_RESULTS_TEMPLATE_ID
        )
        .subscribe((response) => {
          this.columnOrder = response.body;
        });

      // 検索処理を実施
      this.searchResult();
    });
  }

  /**
   * 検索処理
   * @param table テーブル状態
   * @param generateSearchItems 検索条件
   */
  public searchResult(table?: any, generateSearchItems?: Object) {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00003),
      })
    );

    // 検索条件に無条件でカテゴリを追加
    // TODO 森永案件で使用
    {
      // 初期表示か否か
      if (!generateSearchItems) {
        // 初期表示の場合

        // 検索条件オブジェクトを生成
        generateSearchItems = new Object();
      }

      // 検索条件にカテゴリ1を追加
      // generateSearchItems['category1'] = this.category1;
    }

    // CSV出力用に検索条件を保持
    this.generateSearchItems = generateSearchItems;

    // 実行回数取得
    this.dbOperationService.getRunCountRows(
      ITEM_SEARCH_LIST_CONSTANT.TARGET_TABLE, 
      // ログインユーザを判定
      DEPARTMENT$DEPARTMENT_TYPE.TYPE_FORMER ==
        this.loginUser.department_type &&
        USER$ADMIT.ADMIT_GENERAL == this.loginUser.admit
        ? // ログインユーザが一般の場合
          ITEM_SEARCH_LIST_TEMPLATE.GENERAL_SEARCH_RESULTS_TEMPLATE_ID
        : // ログインユーザが管理・承認ユーザの場合
          ITEM_SEARCH_LIST_TEMPLATE.MANAGEMENT_SEARCH_RESULTS_TEMPLATE_ID,
      generateSearchItems
      ).subscribe((response) => {
        let runCount: number = response.body.RunCount;
        let maxRowsCount: number = response.body.MaxRowsCount;
      
        /* 販促資材マスタ一覧取得処理(画面用) */
        this.dbOperationService
          .getForkJoinData(
            runCount,
            API_URL_EVENT,
            // ログインユーザを判定
            DEPARTMENT$DEPARTMENT_TYPE.TYPE_FORMER ==
              this.loginUser.department_type &&
              USER$ADMIT.ADMIT_GENERAL == this.loginUser.admit
              ? // ログインユーザが一般の場合
                ITEM_SEARCH_LIST_TEMPLATE.GENERAL_SEARCH_RESULTS_TEMPLATE_ID
              : // ログインユーザが管理・承認ユーザの場合
                ITEM_SEARCH_LIST_TEMPLATE.MANAGEMENT_SEARCH_RESULTS_TEMPLATE_ID,
            generateSearchItems
          )
          .subscribe((response) => {
            // コード値の一覧情報が存在するか否か
            if (!this.commonService.checkNoneResponse(response)) {
              // 販促資材マスタ一覧情報のJSONをオブジェクトに格納する
              this.searchResultsList = response.body;

              // 計画一覧情報の計画数を合算値をセット
              this.searchSumPlanNum = this.commonService.sumNumberArrayObject(
                response.body,
                ITEM_SEARCH_LIST_CONSTANT.SUM_PLAN_NUM
              );
            } else {
              this.searchResultsList = new Array();

              // 計画数の合算値をセット
              this.searchSumPlanNum = 0;
            }

            // 画面ロードフラグをOFF(ロード終了)
            this.loadingState.loadSleepEnd(0.3);
            // 検索・取得が終了したので、件数判定
            console.log('searchResultsList: '+this.searchResultsList.length);
            if (this.searchResultsList.length >= maxRowsCount) {
              // 最大表示件数に等しいなら、メッセージ表示する
              this.maxRowMessage = "最新のデータ" + maxRowsCount + "件が表示されています。";
            } else {
              this.maxRowMessage = "";
            }
          });
      });

    // テーブル状態が存在するか否か
    if (table) {
      // テーブル状態が存在する場合

      // テーブル状態をリセット
      table.reset();
    }
  }

  /**
   * 詳細情報画面表示
   * @param pkeyId IDコード(クリックされたID)
   * @param rowData 行データ
   */
  protected detail(pkeyId: string, rowData: any) {
    // ログインユーザの判定
    if (
      DEPARTMENT$DEPARTMENT_TYPE.TYPE_OTHER == this.loginUser.department_type
    ) {
      // ログインユーザが管理ユーザの場合

      // 詳細画面表示(計画管理者詳細画面(編集・複製・削除ボタンが存在)へ遷移)
      let inputGenerateDisplay: InputGenerateDisplay =
        new InputGenerateDisplay();
      inputGenerateDisplay.endPoint = API_URL_EVENT;
      inputGenerateDisplay.templateId =
        ITEM_SEARCH_LIST_TEMPLATE.MANAGEMENT_OUTPUT_TEMPLATE_ID;
      this.itemSearchListManagementDetailComponent.initial(
        inputGenerateDisplay,
        pkeyId,
        rowData
      );
    } else {
      // ログインユーザが管理ユーザ以外の場合

      // 詳細画面表示(表示項目生成(アイテムアダプター)へ遷移)
      let inputGenerateDisplay: InputGenerateDisplay =
        new InputGenerateDisplay();
      inputGenerateDisplay.endPoint = API_URL_EVENT;
      inputGenerateDisplay.templateId =
        // ログインユーザを判定
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_FORMER ==
          this.loginUser.department_type &&
        USER$ADMIT.ADMIT_GENERAL == this.loginUser.admit
          ? // ログインユーザが一般の場合
            ITEM_SEARCH_LIST_TEMPLATE.GENERAL_OUTPUT_TEMPLATE_ID
          : // ログインユーザが承認ユーザの場合
            ITEM_SEARCH_LIST_TEMPLATE.MANAGEMENT_OUTPUT_TEMPLATE_ID;
      this.generateDisplayItemsComponent.initial(inputGenerateDisplay, pkeyId);
    }
  }

  /**
   * 新規登録画面表示
   */
  protected new() {
    // 新規登録画面表示
    this.generateInputComponent.initial(
      GENERATE_INPUT_TYPE.NEW,
      API_URL_INPUT_EVENT,
      API_URL_EVENT,
      ITEM_SEARCH_LIST_TEMPLATE.NEW_INPUT_TEMPLATE_ID,
      null
    );
  }

  /**
   * 計画入力
   * @param itemCode 販促資材コード(クリックされたID)
   */
  public planInput(itemCode: string) {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.I00012),
      })
    );

    // カート登録
    this.dbOperationService
      .insertData(API_URL_WORK_ORDER_ITEM, {
        item_code: itemCode,
        category1: this.category1,
      })
      .subscribe((response) => {
        // レスポンスを判定
        if (
          this.messageData.responseToastMessage(
            response,
            this.commonService.msg(MESSAGE_CODE.I00014),
            this.commonService.msg(MESSAGE_CODE.I00015, '資材')
          )
        ) {
          // レスポンスが正常終了の場合

          // TODO 森永案件のみ処理を追加
          // 注文情報(WORK)登録
          this.dbOperationService
            .insertData(API_URL_WORK_ORDER, {
              category1: this.category1,
            })
            .subscribe((response) => {
              // 画面ロードフラグをOFF(ロード終了)
              this.loadingState.loadEnd();

              // レスポンスを判定
              if (this.commonService.checkRunningNormallyResponse(response)) {
                // レスポンスが正常終了の場合

                // 発注管理_数量へ遷移
                // TODO 森永で同一ユーザで3画面切り分けるために使用
                this.router.navigate([
                  'pages/order/quantity/' + this.category1,
                ]);
              } else {
                // 発注管理_届先選択へ遷移
                // TODO 森永で同一ユーザで3画面切り分けるために使用
                this.router.navigate([
                  'pages/order/delivery-search/' + this.category1,
                ]);
              }
            });
        }
      });
  }

  /**
   * 計画数確認画面へ遷移
   * @param pkeyId IDコード(クリックされたID)
   */
  protected planConfirmation(pkeyId: string) {
    // 計画数確認画面へ遷移
    this.router.navigate(['pages/project/project-number-confirmation/3'], {
      queryParams: { no: pkeyId },
    });
  }

  /**
   * 再検索処理＆データ置換
   * @param table テーブル状態
   * @param pkeyId コード値
   */
  public searchReplacement(table: any, pkeyId: string) {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00004),
      })
    );

    /* データが新規,複製の場合 */
    // 返却されたコード値が存在するか否か
    if (!pkeyId) {
      // 返却されたコード値が存在しない場合

      // 検索処理を実施(テーブル状態初期化,全検索)
      this.searchResult(table);

      return;
    }

    /* データが編集かつソート実施済みの場合 */
    // テーブル状態がソート済みか否か
    if (table.sortField) {
      // テーブル状態がソート済みの場合

      // 検索処理を実施(テーブル状態初期化,検索条件あり)
      this.searchResult(table, this.generateSearchItems);

      return;
    }

    /* データが編集かつソート未実施の場合 */
    this.dbOperationService
      .getSingleData(
        API_URL_EVENT,
        // ログインユーザを判定
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_FORMER ==
          this.loginUser.department_type &&
          USER$ADMIT.ADMIT_GENERAL == this.loginUser.admit
          ? // ログインユーザが一般の場合
            ITEM_SEARCH_LIST_TEMPLATE.GENERAL_SEARCH_RESULTS_TEMPLATE_ID
          : // ログインユーザが管理・承認ユーザの場合
            ITEM_SEARCH_LIST_TEMPLATE.MANAGEMENT_SEARCH_RESULTS_TEMPLATE_ID,
        this.getPkeyColumn(),
        pkeyId
      )
      .subscribe((response) => {
        // 一覧情報分ループ
        for (const body of response.body) {
          /* 検索結果一覧から該当データを更新 */
          // 検索結果一覧格納先から対象のデータの行数を抽出
          const indexTargetData = this.commonService.getRowNumberArrayObject(
            this.searchResultsList,
            'no2',
            body.no2
          );

          // 検索結果一覧情報のJSONをオブジェクトに置換する
          this.searchResultsList[indexTargetData] = body;
        }

        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadEnd();
      });
  }

  /**
   * データ削除処理
   * @param pkeyId コード値
   */
  public searchDeleteReplacement(pkeyId: string) {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00004),
      })
    );

    // 検索結果一覧から該当データを削除
    this.searchResultsList = this.commonService.deleteArrayObjectValue(
      this.searchResultsList,
      this.getPkeyColumn(),
      pkeyId
    );

    // 画面ロードフラグをOFF(ロード終了)
    this.loadingState.loadEnd();
  }

  /**
   * CSV出力
   */
  protected exportCSV() {
    // csvファイル名の設定
    const fileName =
      ITEM_SEARCH_LIST_CONSTANT.CSV_FILENAME +
      CONSTANT.UNDERBAR +
      this.datePipe.transform(new Date(), DATE_FORMAT.DATETIME_HYPHEN);

    // CSVファイル出力
    this.exportFileService.exportTemplateCsv(
      fileName,
      ITEM_SEARCH_LIST_CONSTANT.TARGET_TABLE,
      API_URL_EVENT,
      // ログインユーザを判定
      DEPARTMENT$DEPARTMENT_TYPE.TYPE_FORMER ==
        this.loginUser.department_type &&
        USER$ADMIT.ADMIT_GENERAL == this.loginUser.admit
        ? // ログインユーザが一般の場合
          ITEM_SEARCH_LIST_TEMPLATE.GENERAL_CSV_TEMPLATE_ID
        : // ログインユーザが管理・承認ユーザの場合
          ITEM_SEARCH_LIST_TEMPLATE.MANAGEMENT_CSV_TEMPLATE_ID,
      this.generateSearchItems
    );
  }

  /**
   * 表示有効期限判定
   * @param rowData 行データ
   * @returns true:ボタン表示、false:ボタン非表示]
   */
  // TODO 森永案件で利用
  protected outputDispValid(rowData: any): boolean {
    // 現在日付
    const nowDate = this.datePipe.transform(
      new Date().setDate(new Date().getDate()),
      DATE_FORMAT.DATE
    );

    // 現在日付が表示有効期限内か否か
    if (
      nowDate >=
        this.datePipe.transform(rowData.disp_valid_from, DATE_FORMAT.DATE) &&
      nowDate <=
        this.datePipe.transform(rowData.disp_valid_to, DATE_FORMAT.DATE)
    ) {
      // 表示有効期限内の場合

      return true;
    }

    return false;
  }

  /**
   * プライマリーカラムを取得
   */
  protected getPkeyColumn(): string {
    return this.commonService.getPkeyColumn(this.columnOrder);
  }
}
