import { Component, Input, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/shared/service/login.service';
import {
  USER$ADMIT,
  DEPARTMENT$DEPARTMENT_TYPE,
} from 'src/app/shared/constant/db-constant';
import { LoadingState } from '../loading/loading-state';
import { COMPONENT_VALUE_KEY, LOADING_KEY } from '../../constant/constant';
import { CommonService } from '../../service/common.service';
import { TITLE } from 'manager/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})

/**
 * ヘッダー
 */
export class HeaderComponent implements OnInit {
  // ヘッダー判定フラグ(true:通常モード、false:メッセージモード)
  @Input() headerFlag: boolean;

  // タイトルヘッダーを設定
  title: string;

  // カートボタン表示フラグ
  cartDisplayFlag: boolean = false;

  // メニュー情報
  menuitems: MenuItem[] = new Array();

  constructor(
    private router: Router,
    private commonService: CommonService,
    private loginService: LoginService,
    private loadingState: LoadingState
  ) {}

  ngAfterContentChecked() {
    // ヘッダー判定フラグが通常モードか否か
    if (this.headerFlag) {
      // ヘッダー判定フラグが通常モードの場合

      // タイトルヘッダーを設定
      this.title = this.commonService.getComponentValue(
        COMPONENT_VALUE_KEY.HEADER_TITLE
      );
    } else {
      // ヘッダー判定フラグがメッセージモードの場合

      // タイトルフッターをタイトルヘッダーに設定
      this.title = TITLE.TITLE_FOOTER;
    }
  }

  ngOnInit() {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart(LOADING_KEY.HEADER_MENU);

    // ログインユーザ情報取得処理
    this.loginService.getLoginUser().subscribe((response) => {
      // TODO 森永では不要
      /*
      // ユーザ情報の組織種別を判定
      if (
        2 == response.body[0].department_type ||
        9 == response.body[0].department_type
      ) {
        // 2:寄贈先 or 9:その他

        // カートボタンを表示
        this.cartDisplayFlag = true;
      }
      */

      // メニュー情報設定
      this.menu(response.body[0]);

      // 画面ロードフラグをOFF(ロード終了)
      this.loadingState.loadSleepEnd(0.3, LOADING_KEY.HEADER_MENU);
    });
  }

  /**
   * メニュー情報設定
   * @param loginUser ログインユーザ
   */
  private menu(loginUser: any) {
    /**
     * 配送依頼カテゴリ
     */
    {
      /* 配送依頼カテゴリを生成 */
      let order: object = new Object();
      order['label'] = '配送指示';
      order['items'] = new Array();

      /* 配送依頼登録（通常）*/
      if (
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_MANAGEMENT == loginUser.department_type ||
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_ORDER == loginUser.department_type ||
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_OTHER == loginUser.department_type
      ) {
        // 1:※プロジェクト毎に変動 or
        // 9:その他

        const item: object = {
          label: '配送依頼登録',
          icon: 'pi pi-search',
          routerLink: 'pages/order/item-search/1',
          routerLinkActiveOptions: 'active',
        };
        order['items'].push(item);
      }

      /* 配送依頼登録（新規）*/
      // TODO スタンダードで不要の為、コメントアウト　後日削除予定
      // if (
      //   DEPARTMENT$DEPARTMENT_TYPE.TYPE_FORMER == loginUser.department_type ||
      //   DEPARTMENT$DEPARTMENT_TYPE.TYPE_OTHER == loginUser.department_type
      // ) {
      //   // 1:※プロジェクト毎に変動 or
      //   // 9:その他

      //   const item: object = {
      //     label: '配送依頼登録（新規）',
      //     icon: 'pi pi-search',
      //     routerLink: 'pages/order/item-search/2',
      //     routerLinkActiveOptions: 'active',
      //   };
      //   order['items'].push(item);
      // }

      /* 届先選択 */
      // TODO 森永で不要の為、コメントアウト
      /*
      if (
        DEPARTMENT_TYPE.TYPE_DESTINATION == loginUser.department_type ||
        DEPARTMENT_TYPE.TYPE_OTHER == loginUser.department_type
      ) {
        // 2:寄贈先 or 9:その他

        const item: object = {
          label: '届先選択',
          icon: 'pi pi-search',
          routerLink: 'pages/order/delivery-search',
          routerLinkActiveOptions: 'active',
        };
        order['items'].push(item);
      }
      */

      /* 選択リスト */
      // TODO 森永で不要の為、コメントアウト
      /*
      if (
        DEPARTMENT_TYPE.TYPE_DESTINATION == loginUser.department_type ||
        DEPARTMENT_TYPE.TYPE_OTHER == loginUser.department_type
      ) {
        // 2:寄贈先 or 9:その他

        const item: object = {
          label: '選択リスト',
          icon: 'pi pi-search',
          routerLink: 'pages/order/cart',
          routerLinkActiveOptions: 'active',
        };
        order['items'].push(item);
      }
      */

      /* 注文登録 */
      // TODO 森永で不要の為、コメントアウト
      /*
      if (
        DEPARTMENT_TYPE.TYPE_DESTINATION == loginUser.department_type ||
        DEPARTMENT_TYPE.TYPE_OTHER == loginUser.department_type
      ) {
        // 2:発注担当 or 9:その他

        const item: object = {
          label: '注文登録',
          icon: 'pi pi-search',
          routerLink: 'pages/order/orderRegister',
          routerLinkActiveOptions: 'active',
        };
        order['items'].push(item);
      }
      */

      /* 注文履歴 */
      if (
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_MANAGEMENT == loginUser.department_type ||
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_ORDER == loginUser.department_type ||
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_OFFICE == loginUser.department_type ||
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_OTHER == loginUser.department_type
      ) {
        // 1:※プロジェクト毎に変動 or
        // 9:その他

        const item: object = {
          label: '配送指示履歴',
          icon: 'pi pi-list',
          routerLink: 'pages/order/orderHistory',
          routerLinkActiveOptions: 'active',
        };
        order['items'].push(item);
      }
      /* ファイル一括登録(配送依頼) */
      if (
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_MANAGEMENT == loginUser.department_type ||
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_ORDER == loginUser.department_type ||
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_OTHER == loginUser.department_type) {
        // 9:その他
        const item: object = {
          label: 'ファイル一括登録(配送依頼)',
          icon: 'pi pi-upload',
          routerLink: 'pages/order/fileUploadOrder',
          routerLinkActiveOptions: 'active',
        };
        order['items'].push(item);
      }

      // 配送依頼カテゴリのメニューが存在するか否か
      if (order['items'].length) {
        // 配送依頼カテゴリのメニューが存在する場合

        // 配送依頼カテゴリをメニューに追加
        this.menuitems.push(order);
      }
    }

    /**
     * 計画カテゴリ
     */
    // if (
    //   DEPARTMENT$DEPARTMENT_TYPE.TYPE_FORMER == loginUser.department_type ||
    //   DEPARTMENT$DEPARTMENT_TYPE.TYPE_OTHER == loginUser.department_type
    // ) {
    //   // 1:※プロジェクト毎に変動 or
    //   // 9:その他

    //   /* 計画カテゴリを生成 */
    //   let project: object = new Object();
    //   project['label'] = '計画作成';
    //   project['items'] = new Array();

    //   /* IMD数量計画（新規） */
    //   {
    //     const item: object = {
    //       label: 'IMD数量計画（新規）',
    //       icon: 'pi pi-file',
    //       routerLink: 'pages/project/project-list',
    //       routerLinkActiveOptions: 'active',
    //     };
    //     project['items'].push(item);
    //   }

    //   /* IMD数量計画（送込）*/
    //   if (
    //     DEPARTMENT$DEPARTMENT_TYPE.TYPE_FORMER == loginUser.department_type ||
    //     DEPARTMENT$DEPARTMENT_TYPE.TYPE_OTHER == loginUser.department_type
    //   ) {
    //     // 1:※プロジェクト毎に変動 or
    //     // 9:その他

    //     const item: object = {
    //       label: 'IMD数量計画（送込）',
    //       icon: 'pi pi-search',
    //       routerLink: 'pages/order/item-search-list/3',
    //       routerLinkActiveOptions: 'active',
    //     };
    //     project['items'].push(item);
    //   }

    //   // 計画カテゴリのメニューが存在するか否か
    //   if (project['items'].length) {
    //     // マスタカテゴリのメニューが存在する場合

    //     // マスタカテゴリをメニューに追加
    //     this.menuitems.push(project);
    //   }
    // }
    

    /**
     * 在庫メンテナンスカテゴリ
     */
    {
      /* 在庫メンテナンスカテゴリを生成 */
      let stock: object = new Object();
      stock['label'] = '在庫メンテナンス';
      stock['items'] = new Array();

      /* 製作指示 */
      if (
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_MANAGEMENT == loginUser.department_type ||
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_OTHER == loginUser.department_type) {
        // 9:その他

        const item: object = {
          label: '入荷指示',
          icon: 'pi pi-upload',
          routerLink: 'pages/stock/productionInstruction',
          routerLinkActiveOptions: 'active',
        };
        stock['items'].push(item);
      }

      /* 在庫登録・変更 */
      if (
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_OFFICE == loginUser.department_type ||
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_SUPPLIER_WAREHOUSE == loginUser.department_type ||
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_OTHER == loginUser.department_type
      ) {
        // 3:事務局 or
        // 9:その他

        const item: object = {
          label: '在庫登録・変更',
          icon: 'pi pi-upload',
          routerLink: 'pages/stock/registerStockMaintenance',
          routerLinkActiveOptions: 'active',
        };
        stock['items'].push(item);
      }

      /* 在庫メンテナンス管理 */
      if (
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_MANAGEMENT == loginUser.department_type ||
        // (DEPARTMENT$DEPARTMENT_TYPE.TYPE_ORDER == loginUser.department_type && USER$ADMIT.ADMIT_MANAGEMENT == loginUser.admit) ||
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_OFFICE == loginUser.department_type ||
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_SUPPLIER_WAREHOUSE == loginUser.department_type ||
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_OTHER == loginUser.department_type
      ) {
        // (1:※プロジェクト毎に変動 and ユーザ情報.権限レベルが1:管理者) or
        // 3:事務局 or
        // 9:その他

        const item: object = {
          label: '入庫・在庫調整履歴',
          icon: 'pi pi-list',
          routerLink: 'pages/stock/stockMaintenanc',
          routerLinkActiveOptions: 'active',
        };
        stock['items'].push(item);
      }

      // 計画カテゴリのメニューが存在するか否か
      if (stock['items'].length) {
        // マスタカテゴリのメニューが存在する場合

        // マスタカテゴリをメニューに追加
        this.menuitems.push(stock);
      }
    }

    /**
     * マスタ管理カテゴリ
     */
    {
      /* マスタ管理カテゴリを生成 */
      let master: object = new Object();
      master['label'] = '管理';
      master['items'] = new Array();

      /* 資材マスタ */
      if (
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_MANAGEMENT == loginUser.department_type ||
        // DEPARTMENT$DEPARTMENT_TYPE.TYPE_ORDER == loginUser.department_type ||
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_OFFICE == loginUser.department_type ||
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_SUPPLIER_WAREHOUSE == loginUser.department_type ||
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_OTHER == loginUser.department_type
      ) {
        // 1:※プロジェクト毎に変動 or
        // 9:その他

        const item: object = {
          label: '資材',
          icon: 'pi pi-file',
          routerLink: 'pages/master/item',
          routerLinkActiveOptions: 'active',
        };
        master['items'].push(item);
      }

      /* 届先マスタ */
      if (
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_MANAGEMENT == loginUser.department_type ||
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_ORDER == loginUser.department_type ||
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_SUPPLIER_WAREHOUSE == loginUser.department_type ||
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_OTHER == loginUser.department_type
      ) {
        // 1:※プロジェクト毎に変動 or
        // 9:その他

        const item: object = {
          label: '届先',
          icon: 'pi pi-file',
          routerLink: 'pages/master/delivery',
          routerLinkActiveOptions: 'active',
        };
        master['items'].push(item);
      }

      /* 営業日マスタ */
      if (
        // DEPARTMENT$DEPARTMENT_TYPE.TYPE_MANAGEMENT == loginUser.department_type ||
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_OFFICE == loginUser.department_type ||
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_OTHER == loginUser.department_type
      ) {
        // 9:その他

        const item: object = {
          label: '営業日',
          icon: 'pi pi-file',
          routerLink: 'pages/master/bizday',
          routerLinkActiveOptions: 'active',
        };
        master['items'].push(item);
      }

      /* ユーザーマスタ */
      if (
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_MANAGEMENT == loginUser.department_type ||
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_OFFICE == loginUser.department_type ||
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_SUPPLIER_WAREHOUSE == loginUser.department_type ||
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_OTHER == loginUser.department_type) {
        // 9:その他

        const item: object = {
          label: 'ユーザ',
          icon: 'pi pi-file',
          routerLink: 'pages/master/user',
          routerLinkActiveOptions: 'active',
        };
        master['items'].push(item);
      }

      /* 組織マスタ */
      if (
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_MANAGEMENT == loginUser.department_type ||
        // DEPARTMENT$DEPARTMENT_TYPE.TYPE_ORDER == loginUser.department_type ||
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_OFFICE == loginUser.department_type ||
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_SUPPLIER_WAREHOUSE == loginUser.department_type ||
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_OTHER == loginUser.department_type) {
        // 9:その他

        const item: object = {
          label: '組織',
          icon: 'pi pi-file',
          routerLink: 'pages/master/department',
          routerLinkActiveOptions: 'active',
        };
        master['items'].push(item);
      }

      /* お知らせマスタ */
      if (
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_MANAGEMENT == loginUser.department_type ||
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_OFFICE == loginUser.department_type ||
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_OTHER == loginUser.department_type) {
        // 9:その他

        const item: object = {
          label: 'お知らせ',
          icon: 'pi pi-file',
          routerLink: 'pages/master/information',
          routerLinkActiveOptions: 'active',
        };
        master['items'].push(item);
      }

      /* セット品マスタ 2022/08の二次リリース時に有効化 */
      if (
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_MANAGEMENT == loginUser.department_type ||
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_ORDER == loginUser.department_type ||
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_OFFICE == loginUser.department_type ||
        // DEPARTMENT$DEPARTMENT_TYPE.TYPE_SUPPLIER_WAREHOUSE == loginUser.department_type ||
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_OTHER == loginUser.department_type) {
        // 9:その他

        const item: object = {
          label: 'セット品',
          icon: 'pi pi-file',
          routerLink: 'pages/master/setMain',
          routerLinkActiveOptions: 'active',
        };
        master['items'].push(item);
      }
      /* ファイル一括登録(マスタ) */
      if (
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_MANAGEMENT == loginUser.department_type || 
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_ORDER == loginUser.department_type ||
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_OTHER == loginUser.department_type) {
        // 9:その他
        const item: object = {
          label: 'ファイル一括登録(マスタ)',
          icon: 'pi pi-upload',
          routerLink: 'pages/master/fileUploadMaster',
          routerLinkActiveOptions: 'active',
        };
        master['items'].push(item);
      }

      /* ファイル一括登録状況(JOB) */
      if (
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_MANAGEMENT == loginUser.department_type || 
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_ORDER == loginUser.department_type ||
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_OTHER == loginUser.department_type) {
        // 9:その他
        const item: object = {
          label: 'ファイル一括登録状況(JOB)',
          icon: 'pi pi-file',
          routerLink: 'pages/secretariat/fileUploadHistory',
          routerLinkActiveOptions: 'active',
        };
        master['items'].push(item);
      }

      // マスタ管理カテゴリのメニューが存在するか否か
      if (master['items'].length) {
        // マスタ管理カテゴリのメニューが存在する場合

        // マスタ管理カテゴリをメニューに追加
        this.menuitems.push(master);
      }
    }

    /**
     * 事務局カテゴリ
     */
    {
      /* 事務局カテゴリを生成 */
      let secretariat: object = new Object();
      secretariat['label'] = '事務局';
      secretariat['items'] = new Array();

      /* 出荷ステータス更新 */
      if (
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_OFFICE == loginUser.department_type ||
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_SUPPLIER_WAREHOUSE == loginUser.department_type ||
        // (DEPARTMENT$DEPARTMENT_TYPE.TYPE_OTHER == loginUser.department_type && USER$ADMIT.ADMIT_MANAGEMENT == loginUser.admit)
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_OTHER == loginUser.department_type
      ) {
        // 3:事務局ユーザー or (9:その他 and ユーザ権限が1:管理)

        const item: object = {
          label: '出荷ステータス更新',
          icon: 'pi pi-upload',
          routerLink: 'pages/secretariat/registerShippingResult',
          routerLinkActiveOptions: 'active',
        };
        secretariat['items'].push(item);
      }

      /* 情報出力 */
      if (
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_OFFICE == loginUser.department_type ||
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_SUPPLIER_WAREHOUSE == loginUser.department_type ||
        // (DEPARTMENT$DEPARTMENT_TYPE.TYPE_OTHER == loginUser.department_type && USER$ADMIT.ADMIT_MANAGEMENT == loginUser.admit)
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_OTHER == loginUser.department_type
      ) {
        // 3:事務局ユーザー or (9:その他 and ユーザ権限が1:管理)

        const item: object = {
          label: '情報出力',
          icon: 'pi pi-file',
          routerLink: 'pages/secretariat/output',
          routerLinkActiveOptions: 'active',
        };
        secretariat['items'].push(item);
      }

      /* ファイル一括登録状況(JOB) */
      if (DEPARTMENT$DEPARTMENT_TYPE.TYPE_OTHER == loginUser.department_type) {
        // 9:その他
        const item: object = {
          label: 'ファイル一括登録状況(JOB)',
          icon: 'pi pi-file',
          routerLink: 'pages/secretariat/fileUploadHistory',
          routerLinkActiveOptions: 'active',
        };
        secretariat['items'].push(item);
      }

      // 事務局カテゴリのメニューが存在するか否か
      if (secretariat['items'].length) {
        // 事務局カテゴリのメニューが存在する場合

        // 事務局カテゴリをメニューに追加
        this.menuitems.push(secretariat);
      }
    }

    /**
     * 帳票カテゴリ
     */
    // {
    //   /* 帳票カテゴリを生成 */
    //   let report: object = new Object();
    //   report['label'] = '帳票';
    //   report['items'] = new Array();

    //   /* 棚卸帳票出力 */
    //   if (DEPARTMENT$DEPARTMENT_TYPE.TYPE_OTHER == loginUser.department_type) {
    //     // 9:その他

    //     const item: object = {
    //       label: '棚卸帳票出力',
    //       icon: 'pi pi-file',
    //       routerLink: 'pages/report/inventoryReport',
    //       routerLinkActiveOptions: 'active',
    //     };
    //     report['items'].push(item);
    //   }

    //   /* 費用明細帳票出力 */
    //   if (
    //     (DEPARTMENT$DEPARTMENT_TYPE.TYPE_FORMER == loginUser.department_type &&
    //       USER$ADMIT.ADMIT_MANAGEMENT == loginUser.admit) ||
    //     DEPARTMENT$DEPARTMENT_TYPE.TYPE_OTHER == loginUser.department_type
    //   ) {
    //     // (1:※プロジェクト毎に変動 and ユーザ情報.権限レベルが1:管理者) or
    //     // 9:その他

    //     const item: object = {
    //       label: 'IMD費用明細帳票出力',
    //       icon: 'pi pi-file',
    //       routerLink: 'pages/report/costDetailsReport',
    //       routerLinkActiveOptions: 'active',
    //     };
    //     report['items'].push(item);
    //   }

    //   // 帳票カテゴリのメニューが存在するか否か
    //   if (report['items'].length) {
    //     // 帳票カテゴリのメニューが存在する場合

    //     // 帳票カテゴリをメニューに追加
    //     this.menuitems.push(report);
    //   }
    // }

    /* ログアウト */
    {
      const logout: object = {
        label: 'ログアウト',
        icon: 'pi pi-times-circle',
        command: (event) => {
          this.loginService.logout();
        },
      };

      const separator: Object = { separator: true };
      this.menuitems.push(separator);
      this.menuitems.push(logout);
    }
  }

  /**
   * TOP画面遷移
   */
  onHome() {
    this.router.navigate(['pages/top']);
  }

  /**
   * カート画面遷移
   */
  onCart() {
    this.router.navigate(['pages/order/cart']);
  }
}
