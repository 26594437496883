import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';

/** モジュール */
// ルーティング
import { AppRoutingModule } from './app-routing.module';

// 共通モジュール
import { PagesModule } from './pages/pages.module';
import { SharedModule } from './shared/shared.module';

/** 定数 */
import { auth0_dev, auth0_prod } from 'manager/environment';

/** コンポーネント */
import { AppComponent } from './app.component';

/** サービス */
import {
  AuthInterceptor,
  ErrorInterceptor,
} from 'src/app/shared/service/interceptor.service';

/** Auth0 */
import { AuthModule, AuthHttpInterceptor } from '@auth0/auth0-angular';

/** ngx-translate */
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

/** ngx-page-scroll */
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { NgxPageScrollModule } from 'ngx-page-scroll';

import { PROD_DOMAIN } from 'manager/environment';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    AppRoutingModule,
    PagesModule,
    SharedModule,
    NgxPageScrollCoreModule,
    NgxPageScrollModule,
    AuthModule.forRoot({
      domain:
        PROD_DOMAIN == window.location.host
          ? auth0_prod.AUTH0_DOMAIN
          : auth0_dev.AUTH0_DOMAIN,
      clientId:
        PROD_DOMAIN == window.location.host
          ? auth0_prod.AUTH0_CLIENT_ID
          : auth0_dev.AUTH0_CLIENT_ID,
      audience:
        PROD_DOMAIN == window.location.host
          ? auth0_prod.AUDIENCE
          : auth0_dev.AUDIENCE,
      redirectUri: `${window.location.origin}`,
      httpInterceptor: {
        allowedList: [
          {
            uri:
              PROD_DOMAIN == window.location.host
                ? auth0_prod.API_URL
                : auth0_dev.API_URL,
            tokenOptions: {
              audience:
                PROD_DOMAIN == window.location.host
                  ? auth0_prod.AUDIENCE
                  : auth0_dev.AUDIENCE,
            },
          },
        ],
      },
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
