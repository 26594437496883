import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import {
  BREADCRUMB_ORDER,
  OPERATION_MODE_SUMMARY_MESSAGE,
} from './breadcrumb-constants';
import { API_URL_WORK_ORDER } from 'manager/http-constants_key';
import { LoadingState } from 'src/app/shared/html-parts/loading/loading-state';
import { CommonService } from 'src/app/shared/service/common.service';
import {
  MessageData,
  ToastMessageData,
} from 'src/app/shared/html-parts/message-common/message-data';
import { TOAST } from 'src/app/shared/constant/primeng-constants';
import { ORDER$CATEGORY1 } from 'src/app/shared/constant/db-constant';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { CONSTANT } from 'src/app/shared/constant/constant';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})

/**
 * 発注管理 パンくずリスト
 */
export class BreadcrumbComponent implements OnInit {
  // 選択行
  @Input() selectionLine: number;

  // カテゴリ1(クエリパラメータ)
  // TODO 森永で同一ユーザで3画面切り分けるために使用
  category1: string;

  // パンくずリスト
  items: MenuItem[] = new Array();

  // 数量ボタン表示フラグ
  quantityButtonFlag: boolean = false;

  constructor(
    private dbOperationService: DbOperationService,
    private route: ActivatedRoute,
    private router: Router,
    private commonService: CommonService,
    private loadingState: LoadingState,
    private messageData: MessageData,
    private confirmationService: ConfirmationService
  ) {}

  /**
   * 初期化処理
   */
  ngOnInit(): void {
    // パスパラメータの変更を検知
    // TODO 森永で同一ユーザで3画面切り分けるために使用
    this.route.paramMap.subscribe((paramsMap) => {
      // パスパラメータを格納
      this.category1 = paramsMap.get('category1');

      // パンくずリストを設定
      this.items = [
        {
          // 販促資材リストへ遷移
          // TODO 森永で同一ユーザで3画面切り分けるために使用
          label: BREADCRUMB_ORDER.SELECT_ITEM,
        },
        {
          // 届先リストへ遷移
          // TODO 森永で同一ユーザで3画面切り分けるために使用
          label: BREADCRUMB_ORDER.SELECT_DELIVERY,
        },
        /*
          // TODO 森永では不要
          {
            // 選択リストへ遷移
            label: BREADCRUMB_ORDER.SELECTED,
            routerLink: '/pages/order/cart',
          },
          */
        {
          // 数量へ遷移
          label: BREADCRUMB_ORDER.QUANTITY_INPUT,
        },
      ];

      // TODO 森永では不要
      /*
        // 選択ボタンが押下されたか否か
        if (location.pathname == '/pages/order/cart') {
          // 数量ボタン表示フラグをONにする
          this.quantityButtonFlag = true;
        }
      */
    });
  }

  // パンくずリストのクリックイベント
  public itemClicked(event) {
    /* 販促資材(パンくずリスト) */
    // 販促資材が押下されたか否か
    if (BREADCRUMB_ORDER.SELECT_ITEM == event.item.label) {
      // 選択行が存在するか否か
      if (!this.selectionLine) {
        // 選択行が存在しない場合

        // カテゴリ1(クエリパラメータ)が送込か否か
        if ('3' == this.category1) {
          // カテゴリ1(クエリパラメータ)が送込の場合

          // 発注管理_販促資材へ遷移
          // TODO 森永で同一ユーザで3画面切り分けるために使用
          this.router.navigate([
            '/pages/order/item-search-list/' + this.category1,
          ]);
        } else {
          // 発注管理_販促資材へ遷移
          // TODO 森永で同一ユーザで3画面切り分けるために使用
          this.router.navigate(['/pages/order/item-search/' + this.category1]);
        }

        return;
      }

      // 選択確認ダイアログを表示
      this.confirmationService.confirm({
        message: this.commonService.msg(MESSAGE_CODE.T00010),
        header: CONSTANT.EMPTY_ONE_STRING,
        icon: 'pi pi-info-circle',
        accept: () => {
          // カテゴリ1(クエリパラメータ)が送込か否か
          if ('3' == this.category1) {
            // カテゴリ1(クエリパラメータ)が送込の場合

            // 発注管理_販促資材へ遷移
            // TODO 森永で同一ユーザで3画面切り分けるために使用
            this.router.navigate([
              '/pages/order/item-search-list/' + this.category1,
            ]);
          } else {
            // 発注管理_販促資材へ遷移
            // TODO 森永で同一ユーザで3画面切り分けるために使用
            this.router.navigate([
              '/pages/order/item-search/' + this.category1,
            ]);
          }

          return;
        },
      });
    }

    /* 届先(パンくずリスト) */
    // 販促資材が押下されたか否か
    if (BREADCRUMB_ORDER.SELECT_DELIVERY == event.item.label) {
      // 選択行が存在するか否か
      if (!this.selectionLine) {
        // 選択行が存在しない場合

        // 発注管理_届先へ遷移
        // TODO 森永で同一ユーザで3画面切り分けるために使用
        this.router.navigate([
          '/pages/order/delivery-search/' + this.category1,
        ]);

        return;
      }

      // 選択確認ダイアログを表示
      this.confirmationService.confirm({
        message: this.commonService.msg(MESSAGE_CODE.T00010),
        header: CONSTANT.EMPTY_ONE_STRING,
        icon: 'pi pi-info-circle',
        accept: () => {
          // 発注管理_届先へ遷移
          // TODO 森永で同一ユーザで3画面切り分けるために使用
          this.router.navigate([
            '/pages/order/delivery-search/' + this.category1,
          ]);

          return;
        },
      });
    }

    /* 数量(パンくずリスト) */
    // 数量が押下されたか否か
    if (
      BREADCRUMB_ORDER.QUANTITY_INPUT == event.item.label &&
      // TODO 森永で同一ユーザで3画面切り分けるために使用
      location.pathname != '/pages/order/quantity/' + this.category1
    ) {
      // 数量ボタンが押下 かつ
      // 数量ページ以外の場合

      // 注文情報(WORK)登録
      this.insertWorkOrder();
    }
  }

  /**
   * 選択リスト情報で注文情報(WORK)登録
   */
  protected insertWorkOrder() {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart();

    // 注文情報(WORK)登録
    this.dbOperationService
      .insertData(API_URL_WORK_ORDER, {
        category1: this.category1,
      })
      .subscribe((response) => {
        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadEnd();

        // レスポンスを判定
        if (this.commonService.checkRunningNormallyResponse(response)) {
          // レスポンスが正常終了の場合

          // 発注管理_数量へ遷移
          // TODO 森永で同一ユーザで3画面切り分けるために使用
          this.router.navigate(['pages/order/quantity/' + this.category1]);
        } else {
          // 警告メッセージ
          this.messageData.toastMessage(
            new ToastMessageData({
              severity: TOAST.WARN,
              summary: this.commonService.msg(MESSAGE_CODE.T00006),
              detail: this.commonService.msg(
                MESSAGE_CODE.S00001,
                '資材又は届先'
              ),
            })
          );
        }
      });
  }
}
