/** CSVファイル登録情報オブジェクト */
export class CSV_UPLOAD_DATA {
  // 登録フラグ(0:データの検証,1:データの登録)
  // 初期値は検証フラグ
  private registFlag: number = 0;

  // テーブルID
  private tableId: string;

  // 実行タイプ(U:更新対象項目を更新,I:登録,D:削除(削除日付を設定),UF:データ全てを更新)
  private execType: string;

  // csvデータオブジェクトリスト
  private list: object[];

  set setRegistFlag(_registFlag: number) {
    this.registFlag = _registFlag;
  }

  get getRegistFlag(): number {
    return this.registFlag;
  }

  set setTableId(_tableId: string) {
    this.tableId = _tableId;
  }

  get getTableId(): string {
    return this.tableId;
  }

  set setExecType(_execType: string) {
    this.execType = _execType;
  }

  get getExecType(): string {
    return this.execType;
  }

  set setList(_list: object[]) {
    this.list = _list;
  }

  get getList(): object[] {
    return this.list;
  }
}
