import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  API_URL_EVENT,
  API_URL_EVENT_PLAN_INFO_DELIVERY_GROUPS,
  API_URL_QUANTITY_PLAN_EVENTS,
  API_URL_SEARCH_EVENT_PLAN_INFO_DELIVERY_GROUPS,
} from 'manager/http-constants_key';
import { PROJECT_NUMBER_CONFIRMATION_TEMPLATE } from 'manager/template-constant';
import {
  DICVALUES_API_CONSTANT,
  INPUT_INFORMATIONS_API_CONSTANT,
} from 'src/app/shared/constant/api-constant';
import { CONSTANT, DATE_FORMAT } from 'src/app/shared/constant/constant';
import {
  DEPARTMENT$DEPARTMENT_TYPE,
  USER$ADMIT,
} from 'src/app/shared/constant/db-constant';
import { MESSAGE_CODE } from 'src/app/shared/constant/message-constant';
import { TOAST } from 'src/app/shared/constant/primeng-constants';
import { SESSION_KEY } from 'src/app/shared/constant/session-constants';
import { InputGenerateDisplay } from 'src/app/shared/generate/generate-display/generate-display-information/generate-display-information';
import { GenerateDisplayComponent } from 'src/app/shared/generate/generate-display/generate-display.component';
import { ExportFileService } from 'src/app/shared/html-parts/export-file/export-file.service';
import {
  LoadData,
  LoadingState,
} from 'src/app/shared/html-parts/loading/loading-state';
import {
  MessageData,
  ToastMessageData,
} from 'src/app/shared/html-parts/message-common/message-data';
import { CommonService } from 'src/app/shared/service/common.service';
import { DbOperationService } from 'src/app/shared/service/db-operation.service';
import { PROJECT_NUMBER_CONFIRMATION_CONSTANT } from './constant';

@Component({
  selector: 'app-project-number-confirmation',
  templateUrl: './project-number-confirmation.component.html',
  styleUrls: ['../project.component.scss'],
})

/**
 * 計画数確認画面
 */
export class ProjectNumberConfirmationComponent implements OnInit {
  /** 詳細画面 */
  @ViewChild(GenerateDisplayComponent)
  generateDisplayItemsComponent: GenerateDisplayComponent;

  // ログインユーザ情報
  loginUser = JSON.parse(
    window.sessionStorage.getItem(SESSION_KEY.loginUserInformation)
  );

  // カテゴリ1(パスパラメータ) ※初期値はパスパラメータ
  category1: string = this.route.snapshot.params.category1;

  // 計画No(クエリパラメータ) ※初期値はクエリパラメータ
  no: string = this.route.snapshot.queryParams.no;

  // 検索項目生成エンドポイント
  public searchEndPoint: string =
    API_URL_SEARCH_EVENT_PLAN_INFO_DELIVERY_GROUPS;

  // 検索項目生成テンプレートID
  searchTemplateId: number =
    // ログインユーザを判定
    DEPARTMENT$DEPARTMENT_TYPE.TYPE_FORMER == this.loginUser.department_type &&
    USER$ADMIT.ADMIT_GENERAL == this.loginUser.admit
      ? // ログインユーザが一般の場合
        PROJECT_NUMBER_CONFIRMATION_TEMPLATE.GENERAL_SEARCH_TEMPLATE_ID
      : // ログインユーザが管理・承認ユーザの場合
        PROJECT_NUMBER_CONFIRMATION_TEMPLATE.MANAGEMENT_SEARCH_TEMPLATE_ID;

  /* 画面用プロパティ */
  // 計画変更プルダウン
  projectChangeInput: Object = new Object();

  // イベントヘッダー情報格納先
  eventColumnOrder: any[] = new Array();

  // イベント検索結果一覧格納先
  eventSearchResults: any[] = new Array();

  // 画面ヘッダー情報格納先
  columnOrder: any[] = new Array();

  // 画面検索結果一覧格納先
  searchResultsList: any[] = new Array();

  // 辞書値格納先
  dicValuesList: any[] = new Array();

  // 検索条件保持
  generateSearchItems: Object;

  constructor(
    private loadingState: LoadingState,
    private dbOperationService: DbOperationService,
    private commonService: CommonService,
    private messageData: MessageData,
    public datePipe: DatePipe,
    private router: Router,
    private exportFileService: ExportFileService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart('projectChangeInput');

    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart('event');

    // 画面ロードフラグをON(ロード中状態)
    this.loadingState.loadStart('projectNumber');

    /* 計画変更プルダウン取得処理 */
    {
      // 計画変更プルダウン取得
      this.dbOperationService
        .getData(
          API_URL_QUANTITY_PLAN_EVENTS,
          this.category1 != '3'
            ? PROJECT_NUMBER_CONFIRMATION_TEMPLATE.PROJECT_CHANGE_INPUT_TEMPLATE_ID
            : PROJECT_NUMBER_CONFIRMATION_TEMPLATE.PROJECT_CHANGE_INPUT_TEMPLATE_ID2
        )
        .subscribe((response) => {
          // 取得データの1行目の入力種類がシングルセレクトか否か
          if (
            (INPUT_INFORMATIONS_API_CONSTANT.SINGLE_SELECT_TYPE =
              response.body[0].input_type)
          ) {
            // 入力種類がシングルセレクトの場合

            // 計画変更プルダウンを格納
            this.projectChangeInput = response.body[0];
          } else {
            // 入力種類がシングルセレクト以外の場合

            // 警告メッセージ
            this.messageData.toastMessage(
              new ToastMessageData({
                severity: TOAST.WARN,
                summary: this.commonService.msg(MESSAGE_CODE.E00003),
                detail: this.commonService.msg(MESSAGE_CODE.E80002),
                position: TOAST.BOTTOM_RIGHT,
                life: 60000,
              })
            );
          }

          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadSleepEnd(0.3, 'projectChangeInput');
        });
    }

    /* イベントヘッダー情報取得処理(画面用) */
    {
      this.dbOperationService
        .getHeaderList(
          PROJECT_NUMBER_CONFIRMATION_TEMPLATE.EVENT_SEARCH_RESULTS_TEMPLATE_ID
        )
        .subscribe((response) => {
          // イベントヘッダー情報のJSONをオブジェクトに格納する
          this.eventColumnOrder = response.body;

          // イベントヘッダー情報分ループ
          for (const headerInformation of response.body) {
            // イベントヘッダー情報に使用辞書番号が存在するか否か
            if (!headerInformation.column_dictionary_id) {
              // 使用辞書番号が存在しない場合
              continue;
            }

            /* 辞書情報を取得 */
            {
              // 使用辞書番号で辞書情報を取得する
              this.dbOperationService
                .getDicValues(headerInformation.column_dictionary_id)
                .subscribe((dicvalues) => {
                  // 辞書情報を格納
                  this.dicValuesList = this.dicValuesList.concat(
                    dicvalues.body[0].dic_value
                  );
                });
            }
          }

          // イベント情報取得処理を実施
          this.eventSearchResult();
        });
    }

    /* ヘッダー情報取得処理(画面用) */
    {
      this.dbOperationService
        .getHeaderList(
          // ログインユーザを判定
          DEPARTMENT$DEPARTMENT_TYPE.TYPE_FORMER ==
            this.loginUser.department_type
            ? // ログインユーザが一般・承認ユーザの場合
              PROJECT_NUMBER_CONFIRMATION_TEMPLATE.GENERAL_SEARCH_RESULTS_TEMPLATE_ID
            : // ログインユーザが管理ユーザの場合
              PROJECT_NUMBER_CONFIRMATION_TEMPLATE.MANAGEMENT_SEARCH_RESULTS_TEMPLATE_ID
        )
        .subscribe((response) => {
          this.columnOrder = response.body;
        });
    }

    // 検索処理を実施
    this.searchResult();
  }

  /**
   * イベント情報取得処理
   */
  private eventSearchResult() {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00003),
      }),
      'event'
    );

    /* イベント情報取得処理(画面用) */
    {
      // イベント情報取得
      this.dbOperationService
        .getSingleData(
          API_URL_EVENT,
          PROJECT_NUMBER_CONFIRMATION_TEMPLATE.EVENT_SEARCH_RESULTS_TEMPLATE_ID,
          this.commonService.getPkeyColumn(this.eventColumnOrder),
          this.no
        )
        .subscribe((response) => {
          // イベント情報が存在するか否か
          if (!this.commonService.checkNoneResponse(response)) {
            // イベント情報の先頭行のJSONをオブジェクトに格納する
            this.eventSearchResults = response.body.slice(0, 1);
          } else {
            this.eventSearchResults = new Array();
          }

          // 画面ロードフラグをOFF(ロード終了)
          this.loadingState.loadSleepEnd(0.3, 'event');
        });
    }
  }

  /**
   * 検索処理
   * @param table テーブル状態
   * @param generateSearchItems 検索条件
   */
  public searchResult(table?: any, generateSearchItems?: Object) {
    // 画面カスタムロードフラグをON(ロード中状態)
    this.loadingState.customLoadStart(
      new LoadData({
        loadingText: this.commonService.msg(MESSAGE_CODE.S00003),
      }),
      'projectNumber'
    );

    // 検索条件に無条件で計画Noを追加
    {
      // 初期表示か否か
      if (!generateSearchItems) {
        // 初期表示の場合

        // 検索条件オブジェクトを生成
        generateSearchItems = new Object();
      }

      // 検索条件に計画Noを追加
      generateSearchItems['event_no'] = this.no;
    }

    // CSV出力用に検索条件を保持
    this.generateSearchItems = generateSearchItems;

    /* 計画数一覧取得処理(画面用) */
    this.dbOperationService
      .getData(
        API_URL_EVENT_PLAN_INFO_DELIVERY_GROUPS,
        // ログインユーザを判定
        DEPARTMENT$DEPARTMENT_TYPE.TYPE_FORMER == this.loginUser.department_type
          ? // ログインユーザが一般・承認ユーザの場合
            PROJECT_NUMBER_CONFIRMATION_TEMPLATE.GENERAL_SEARCH_RESULTS_TEMPLATE_ID
          : // ログインユーザが管理ユーザの場合
            PROJECT_NUMBER_CONFIRMATION_TEMPLATE.MANAGEMENT_SEARCH_RESULTS_TEMPLATE_ID,
        generateSearchItems
      )
      .subscribe((response) => {
        // 計画数一覧情報が存在するか否か
        if (!this.commonService.checkNoneResponse(response)) {
          // 計画数一覧情報のJSONをオブジェクトに格納する
          this.searchResultsList = response.body;
        } else {
          this.searchResultsList = new Array();
        }

        // 画面ロードフラグをOFF(ロード終了)
        this.loadingState.loadSleepEnd(0.3, 'projectNumber');
      });

    // テーブル状態が存在するか否か
    if (table) {
      // テーブル状態が存在する場合

      // テーブル状態をリセット
      table.reset();
    }
  }

  /**
   * 計画変更プルダウン
   */
  protected projectChange(table: any) {
    // 計画確認画面のクエリ文字列を変更(同URLの為、遷移しない)
    this.router.navigate(
      ['pages/project/project-number-confirmation/' + this.category1],
      {
        queryParams: { no: this.no },
      }
    );

    // イベント情報取得処理を実施
    this.eventSearchResult();

    // 検索処理を実施
    this.searchResult();

    // テーブル状態をリセット
    table.reset();
  }

  /**
   * 計画一覧画面遷移
   */
  protected returnProjectList() {
    if ('3' != this.category1) {
      // 計画一覧画面へ遷移
      this.router.navigate(['pages/project/project-list']);
    } else {
      // 発注販促資材リスト画面へ遷移
      this.router.navigate(['pages/order/item-search-list/' + this.category1]);
    }
  }

  /**
   * イベント詳細情報画面表示
   * @param pkeyId IDコード(クリックされたID)
   */
  protected eventDetail(pkeyId: string) {
    // 詳細画面表示
    let inputGenerateDisplay: InputGenerateDisplay = new InputGenerateDisplay();
    inputGenerateDisplay.title = 'イベント情報';
    inputGenerateDisplay.endPoint = API_URL_EVENT;
    inputGenerateDisplay.templateId =
      PROJECT_NUMBER_CONFIRMATION_TEMPLATE.EVENT_OUTPUT_TEMPLATE_ID;
    this.generateDisplayItemsComponent.initial(inputGenerateDisplay, pkeyId);
  }

  /**
   * 詳細情報画面表示
   * @param pkeyId IDコード(クリックされたID)
   */
  protected detail(pkeyId: string) {
    // 詳細画面表示
    let inputGenerateDisplay: InputGenerateDisplay = new InputGenerateDisplay();
    inputGenerateDisplay.title = '届先グループ情報';
    inputGenerateDisplay.endPoint = API_URL_EVENT_PLAN_INFO_DELIVERY_GROUPS;
    inputGenerateDisplay.templateId =
      PROJECT_NUMBER_CONFIRMATION_TEMPLATE.OUTPUT_TEMPLATE_ID;
    this.generateDisplayItemsComponent.initial(inputGenerateDisplay, pkeyId);
  }

  /**
   * コード値変換
   * @param spName コード名称
   * @returns コード値
   */
  protected changeCode(spName: string): number {
    // コード値を取得
    const code = this.commonService.getArrayObjectValue(
      this.dicValuesList,
      DICVALUES_API_CONSTANT.NAME,
      DICVALUES_API_CONSTANT.CODE,
      spName
    );

    return code;
  }

  /**
   * CSV出力
   */
  protected exportCSV() {
    // csvファイル名の設定
    const fileName =
      PROJECT_NUMBER_CONFIRMATION_CONSTANT.CSV_FILENAME +
      CONSTANT.UNDERBAR +
      this.datePipe.transform(new Date(), DATE_FORMAT.DATETIME_HYPHEN);

    // CSVファイル出力
    this.exportFileService.exportTemplateCsv(
      fileName,
      null,
      API_URL_EVENT_PLAN_INFO_DELIVERY_GROUPS,
      // ログインユーザを判定
      DEPARTMENT$DEPARTMENT_TYPE.TYPE_FORMER == this.loginUser.department_type
        ? // ログインユーザが一般・承認ユーザの場合
          PROJECT_NUMBER_CONFIRMATION_TEMPLATE.GENERAL_CSV_TEMPLATE_ID
        : // ログインユーザが管理ユーザの場合
          PROJECT_NUMBER_CONFIRMATION_TEMPLATE.MANAGEMENT_CSV_TEMPLATE_ID,
      this.generateSearchItems
    );
  }

  /**
   * プライマリーカラムを取得
   */
  protected getPkeyColumn(): string {
    return this.commonService.getPkeyColumn(this.columnOrder);
  }
}
