import { Component, OnInit } from '@angular/core';
import { GenerateInputErrorMessage } from './generate-input-error-message';

@Component({
  selector: 'app-generate-input-error-message',
  templateUrl: './generate-input-error-message.component.html',
})

/**
 * 入力エラーメッセージ
 */
export class GenerateInputErrorMessageComponent implements OnInit {
  // 入力エラーメッセージ出力オブジェクトリスト(画面表示情報)
  generateInputErrorMessageList: GenerateInputErrorMessage[] = new Array();

  constructor() {}

  ngOnInit(): void {}

  /**
   * 入力エラーメッセージ出力
   * @param generateInputErrorVerificationList エラーメッセージリスト
   */
  public initial(
    generateInputErrorVerificationList: GenerateInputErrorMessage[]
  ) {
    // 入力エラーメッセージのページアンカーにスクロール
    let top = document.getElementById('generateInputErrorTop');
    top.scrollIntoView();
    top = null;

    // エラーメッセージをプロパティに格納
    this.generateInputErrorMessageList = generateInputErrorVerificationList;
  }

  /**
   * 入力エラーメッセージクリア
   */
  public clear() {
    // エラーメッセージをクリア
    this.generateInputErrorMessageList = new Array();
  }
}
