/**
 * ユーザ権限設定
 * ※ログイン判定及びルーティング遷移判定で使用
 * -----------------------------------------------------
 * 権限設定は以下を設定可能
 * ユーザマスタ.権限レベル(admit)
 * 組織マスタ.組織コード(department_code)
 * 組織マスタ.組織レベル(department_level)
 * 組織マスタ.組織名(department_name)
 * 組織マスタ.組織カナ(department_name_kana)
 * 組織マスタ.組織種別(department_type)
 * ユーザマスタ.メールアドレス(email)
 * ユーザマスタ.ユーザID(user_id)
 * ユーザマスタ.ユーザ名(user_name)
 * ユーザマスタ.ユーザ名カナ(user_name_kana)
 * -----------------------------------------------------
 * 注:権限に値を1つも入れない場合、そのユーザは使われない
 * 注:ユーザ権限が足りない場合、USER_(数字連番)で定数を追加、その後下記の権限判定オブジェクトにUSER_(数字連番)を追加
 */
/**
 * 発注ユーザ
 */
export const USER_1 = {
  // ユーザマスタ.権限レベル
  admit: '0',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '2',
};

/**
 * 承認ユーザ
 */
export const USER_2 = {
  // ユーザマスタ.権限レベル
  admit: '1',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '2',
};

/**
 * 事務局ユーザ
 */
export const USER_3 = {
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '3',
};

/**
 * 管理ユーザ
 */
export const USER_4 = {
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '1',
};

/**
 * システム管理者ユーザ
 */
export const USER_5 = {
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '9',
};

/**
 * サプライヤー／倉庫ユーザ
 */
export const USER_6 = {
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '4',
};

/**
 * ユーザ
 */
export const USER_7 = {
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '',
};

/**
 * ユーザ
 */
export const USER_8 = {
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '',
};

/**
 * ユーザ
 */
export const USER_9 = {
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '',
};

/**
 * ユーザ
 */
export const USER_10 = {
  // ユーザマスタ.権限レベル
  admit: '',
  // 組織マスタ.組織レベル
  department_level: '',
  // 組織マスタ.組織種別
  department_type: '',
};

/** 権限判定オブジェクト */
export class AuthorityDecision {
  private USER_1: any;

  private USER_2: any;

  private USER_3: any;

  private USER_4: any;

  private USER_5: any;

  private USER_6: any;

  private USER_7: any;

  private USER_8: any;

  private USER_9: any;

  private USER_10: any;

  constructor(init?: Partial<AuthorityDecision>) {
    Object.assign(this, init);
  }

  set user_1(user_1: any) {
    this.USER_1 = user_1;
  }

  get user_1(): any {
    return this.USER_1;
  }

  set user_2(user_2: any) {
    this.USER_2 = user_2;
  }

  get user_2(): any {
    return this.USER_2;
  }

  set user_3(user_3: any) {
    this.USER_3 = user_3;
  }

  get user_3(): any {
    return this.USER_3;
  }

  set user_4(user_4: any) {
    this.USER_4 = user_4;
  }

  get user_4(): any {
    return this.USER_4;
  }

  set user_5(user_5: any) {
    this.USER_5 = user_5;
  }

  get user_5(): any {
    return this.USER_5;
  }

  set user_6(user_6: any) {
    this.USER_6 = user_6;
  }

  get user_6(): any {
    return this.USER_6;
  }

  set user_7(user_7: any) {
    this.USER_7 = user_7;
  }

  get user_7(): any {
    return this.USER_7;
  }

  set user_8(user_8: any) {
    this.USER_8 = user_8;
  }

  get user_8(): any {
    return this.USER_8;
  }

  set user_9(user_9: any) {
    this.USER_9 = user_9;
  }

  get user_9(): any {
    return this.USER_9;
  }

  set user_10(user_10: any) {
    this.USER_10 = user_10;
  }

  get user_10(): any {
    return this.USER_10;
  }
}
